import { signOut, useSession } from "next-auth/react";
import { useRef } from "react";

import Modal from ".";
import Link from "next/link";
import Location from "../icons/Location";
import CallIcon from "../icons/CallIcon";
import EmailIcon from "../icons/EmailIcon";
import ArrowIcon from "../icons/ArrowIcon";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import ProfileCircle from "../icons/ProfileCircle";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import LocaleSwitcher from "../global/buttons/LocaleSwitcher";
import CategoryListItems from "@/layout/categories/CategoryListItems";
import { resetSession } from "@/helpers/common/resetSession";
import LogoutIcon from "../icons/LogoutIcon";
import { useRouter } from "next/router";

const SideMenu = ({ t, locale, sideModal, setSideModal, setAuth }) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setSideModal(false));
  const { data: session } = useSession();

  const { push } = useRouter();
  return (
    <Modal show={sideModal}>
      <div
        ref={ref}
        className="max-h-screen px-3 py-5 overflow-y-auto bg-white w-11/12 min-[420px]:w-96 max-sm:text-sm red-scroll"
      >
        <div
          onClick={() => {
            if (!session?.user) {
              setSideModal(false);
              setAuth("login");
            } else {
              setSideModal(false);
              push("/user");
            }
          }}
          className="flex items-center justify-between pb-4 border-b"
        >
          <div className="flex items-center gap-2">
            <ProfileCircle className="text-red-600 size-6" />
            {!session?.user ? (
              <span className="font-bold text-red-600">
                {t("Login")} / {t("Register")}
              </span>
            ) : (
              <span className="font-bold text-red-600">
                {t("welcome")} {session?.user?.firstname}
              </span>
            )}
          </div>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div>

        <div className="pt-3">
          <CategoryListItems isMobile onHide={() => setSideModal(false)} />
        </div>
        <div
          onClick={() => {
            push("/branches");
            setSideModal(false);
          }}
          className="flex items-center justify-between py-3 border-t"
        >
          <div className="flex items-center gap-2">
            <Location className="size-4" />
            <span className="font-semibold">{t("Our Show Rooms")}</span>
          </div>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div>
        <LocaleSwitcher t={t} fromSide />
        <div className="py-3 border-t">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex items-center gap-2">
              <CallIcon className="rotate-90 size-4" />
              <span className="font-semibold">{t("Contact Us")}</span>
            </div>
            <p className="ms-6 text-[13px] sm:text-sm text-justify text-zinc-400">
              {locale === "en"
                ? "For More Information you can contact us on our phone number, email address or whatsapp number"
                : "لمزيد من المعلومات يمكنك التواصل معنا عن طريق البريد الإلكترونى أو رقم الواتس اب أو الهاتف الجوال الخاص بنا"}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-1 mt-2">
            <Link
              href="mailto:info@backcomfort.com.sa"
              className="flex items-center justify-center gap-1 p-1 border sm:p-2"
            >
              <EmailIcon className="size-2.5 sm:size-4" />
              <span className="text-[10.5px] sm:text-xs text-zinc-800 whitespace-nowrap">
                {t("Email Address")}
              </span>
            </Link>
            <Link
              href={"https://api.whatsapp.com/send?phone=966112228227"}
              target="_blank"
              className="flex items-center justify-center gap-1 p-1 border sm:p-2"
            >
              <WhatsAppIcon className="size-2.5 sm:size-4" />
              <span className="text-[10.5px] sm:text-xs text-zinc-800 whitespace-nowrap">
                {t("Whats App")}
              </span>
            </Link>
            <Link
              href={"tel:920000440"}
              className="flex items-center justify-center gap-1 p-1 border sm:p-2"
            >
              <CallIcon className="rotate-90 size-2.5 sm:size-4" />
              <span className="text-[10.5px] sm:text-xs text-zinc-800 whitespace-nowrap">
                {t("Contact Us")}
              </span>
            </Link>
          </div>
        </div>
        {/* <div className="flex items-center justify-between py-3 border-t">
          <span className="font-semibold">{t("Shipping Policy")}</span>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div>
        <div className="flex items-center justify-between py-3 border-t">
          <span className="font-semibold">{t("Returns & Refunds")}</span>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div> */}
        <div
          onClick={() => {
            push("/blogs");
            setSideModal(false);
          }}
          className="flex items-center justify-between py-3 border-t"
        >
          <span className="font-semibold">
            {locale === "en" ? "Blogs" : "المقالات"}
          </span>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div>
        <div
          onClick={() => {
            push("/static/faq");
            setSideModal(false);
          }}
          className="flex items-center justify-between py-3 border-t"
        >
          <span className="font-semibold">{t("FAQ")}</span>
          <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
        </div>

        {session?.user && (
          <button
            className="flex items-center gap-2 pt-3 text-red-600 cursor-pointer  border-t w-full"
            onClick={() => {
              setTimeout(() => signOut(), 10);
              resetSession();
              push("/");
            }}
          >
            <LogoutIcon className="size-[18px]" />
            <span className="text-[15px] font-semibold">
              {locale === "en" ? "Logout" : "تسجيل الخروج"}
            </span>
          </button>
        )}
      </div>
    </Modal>
  );
};

export default SideMenu;
