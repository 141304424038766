import CategoryLists from "./categories/CategoryLists";
import { useGlobalData } from "@/context/global";

// import MegaMenu from "./MegaMenu";
import TopThisWeek from "./TopThisWeek";
import Link from "next/link";

const NavBar = ({ t, locale }) => {
  const {
    appData: { megaMenuTreeData },
  } = useGlobalData();

  return (
    <nav className="items-center hidden lg:flex min-w-full">
      <CategoryLists {...{ t, locale }} />
      <div className="relative flex-1 text-white bg-red-600 border-4 border-red-600">
        <div className="me-[5%] flex items-center justify-between ">
          <div className="flex items-center gap-12">
            {megaMenuTreeData?.map((cat, i) => (
              <Link
                href={`/${cat?.url_key || "/"}`}
                key={i}
                className="py-2.5 px-4 font-bold cursor-pointer group"
              >
                {cat?.name}
                {/* <MegaMenu childrenData = {cat?.children_data} /> */}
              </Link>
            ))}
          </div>
          <TopThisWeek t={t} />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
