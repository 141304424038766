const FilterIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33334 4.8335H5.33334C5.60668 4.8335 5.83334 4.60683 5.83334 4.3335C5.83334 4.06016 5.60668 3.8335 5.33334 3.8335H1.33334C1.06001 3.8335 0.833344 4.06016 0.833344 4.3335C0.833344 4.60683 1.06001 4.8335 1.33334 4.8335Z"
      fill="currentColor"
    />
    <path
      d="M12 4.8335H14.6667C14.94 4.8335 15.1667 4.60683 15.1667 4.3335C15.1667 4.06016 14.94 3.8335 14.6667 3.8335H12C11.7267 3.8335 11.5 4.06016 11.5 4.3335C11.5 4.60683 11.7267 4.8335 12 4.8335Z"
      fill="currentColor"
    />
    <path
      d="M9.33332 7.16667C10.8933 7.16667 12.1667 5.89333 12.1667 4.33333C12.1667 2.77333 10.8933 1.5 9.33332 1.5C7.77332 1.5 6.49999 2.77333 6.49999 4.33333C6.49999 5.89333 7.77332 7.16667 9.33332 7.16667ZM9.33332 2.5C10.3467 2.5 11.1667 3.32 11.1667 4.33333C11.1667 5.34667 10.3467 6.16667 9.33332 6.16667C8.31999 6.16667 7.49999 5.34667 7.49999 4.33333C7.49999 3.32 8.31999 2.5 9.33332 2.5Z"
      fill="currentColor"
    />
    <path
      d="M1.33333 12.1665H4C4.27333 12.1665 4.5 11.9398 4.5 11.6665C4.5 11.3932 4.27333 11.1665 4 11.1665H1.33333C1.06 11.1665 0.833333 11.3932 0.833333 11.6665C0.833333 11.9398 1.06 12.1665 1.33333 12.1665Z"
      fill="currentColor"
    />
    <path
      d="M10.6667 12.1665H14.6667C14.94 12.1665 15.1667 11.9398 15.1667 11.6665C15.1667 11.3932 14.94 11.1665 14.6667 11.1665H10.6667C10.3933 11.1665 10.1667 11.3932 10.1667 11.6665C10.1667 11.9398 10.3933 12.1665 10.6667 12.1665Z"
      fill="currentColor"
    />
    <path
      d="M6.66667 14.5002C8.22667 14.5002 9.5 13.2268 9.5 11.6668C9.5 10.1068 8.22667 8.8335 6.66667 8.8335C5.10667 8.8335 3.83333 10.1068 3.83333 11.6668C3.83333 13.2268 5.10667 14.5002 6.66667 14.5002ZM6.66667 9.8335C7.68 9.8335 8.5 10.6535 8.5 11.6668C8.5 12.6802 7.68 13.5002 6.66667 13.5002C5.65333 13.5002 4.83333 12.6802 4.83333 11.6668C4.83333 10.6535 5.65333 9.8335 6.66667 9.8335Z"
      fill="currentColor"
    />
  </svg>
);
export default FilterIcon;
