import ArrowIcon from "../../icons/ArrowIcon";

const SwiperButtons = ({ swiper, locale }) => {
  return (
    <div
      className={`hidden sm:flex ${
        locale === "en" ? "flex-row" : "flex-row-reverse"
      } items-center gap-4`}
    >
      <button
        onClick={() => swiper?.slidePrev()}
        className="grid border rounded-full size-9 place-items-center"
      >
        <ArrowIcon className="rotate-90" />
      </button>
      <button
        onClick={() => swiper?.slideNext()}
        className="grid border rounded-full size-9 place-items-center"
      >
        <ArrowIcon className="-rotate-90" />
      </button>
    </div>
  );
};

export default SwiperButtons;
