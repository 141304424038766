import { EmailValidation } from "@/utils/valitation/subscribe";
import { subscribeToNewsletter } from "@/services/newsletter";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getCookies } from "cookies-next";

import toast from "react-hot-toast";

const EmailSubscribe = ({ t }) => {
  const { handshake, mage } = getCookies();

  return (
    <div className="my-3 sm:my-5">
      <span className="block mb-3 text-sm font-bold sm:mb-5 max-sm:text-xs">
        {t("Subscribe in news letter")}
      </span>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={EmailValidation(t)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const res = await subscribeToNewsletter(
            handshake,
            mage,
            values.email
          );
          if (res.status == 200) {
            !res.data.data.at(0).error
              ? toast.success(res.data.data.at(0).message)
              : toast.error(res.data.data.at(0).message);
          } else {
            toast.error(res.error.error.at(0).message);
          }
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="flex items-center border border-[#393939] rounded-md border-opacity-60">
                <Field
                  autoComplete="email"
                  type="email"
                  placeholder={t("Email Address")}
                  className="placeholder:text-sm max-sm:placeholder:text-xs text-sm max-sm:text-xs flex-1 p-1.5 sm:p-2 bg-transparent border-e border-[#393939] outline-none"
                  name="email"
                />

                <button
                  type="submit"
                  className="py-1.5 max-sm:text-xs text-sm sm:py-2 text-red-600 px-3"
                >
                  {isSubmitting ? t("Loading...") : t("Subscribe")}
                </button>
              </div>
              <ErrorMessage
                name="email"
                component="p"
                className="text-red-600 text-xs max-sm:text-[10px]"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmailSubscribe;
