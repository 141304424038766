import { getProductsByCatId } from "@/services/category";
import { productsSearch } from "@/services/search";
import { getCookie } from "cookies-next";
import { useCallback } from "react";
import useSWRMutation from "swr/mutation";

const useSortProducts = ({
  setAllSortingKeys,
  setAllProducts,
  categoryID,
  allSortingKeys,
  allFilterKeys,
  fromCategory = true,
  setPageIndex,
  word = "",
}) => {
  const handshake = getCookie("handshake");
  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}&${allSortingKeys}`
      : `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${allFilterKeys}`,
    async (_, { arg }) => {
      const { pageNo, pageSize, categoryID, filterKeys, sortKeys } = arg;
      const { data: productsData, error: productsError } = fromCategory
        ? await getProductsByCatId(
            handshake,
            categoryID,
            sortKeys,
            filterKeys,
            pageSize,
            pageNo
          )
        : await productsSearch(
            handshake,
            word,
            pageSize,
            pageNo,
            sortKeys,
            filterKeys
          );

      let handleProducts = {
        products: productsData?.data?.products,
        aggregate: productsData?.data?.aggregate,
        pages: productsData?.data?.pages,
        pageParam: +pageNo,
        error: productsError,
      };
      return handleProducts;
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const handleSorting = useCallback(
    async ({ keys }) => {
      setAllSortingKeys(keys);
      setPageIndex(1);
      let result = await res?.trigger(
        fromCategory
          ? {
              pageNo: 0,
              pageSize: 30,
              categoryID,
              filterKeys: allFilterKeys,
              sortKeys: keys,
            }
          : {
              pageNo: 0,
              pageSize: 30,
              filterKeys: allFilterKeys,
              sortKeys: keys,
              word,
            }
      );

      setAllProducts(
        result?.products?.length && result?.products != undefined
          ? result?.products
          : []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, categoryID, res, setAllProducts, setAllSortingKeys]
  );

  return {
    handleSorting,
  };
};

export default useSortProducts;
