import { otpValidation } from "@/utils/valitation/auth";
import { getCookie, setCookie } from "cookies-next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useRouter } from "next/router";

const Otp = ({ t, setAuth }) => {
  const { locale } = useRouter();

  const handleOTP = async ({ otp }, { setSubmitting }) => {
    setSubmitting(true);
    const resetPassword = JSON.parse(getCookie("resetPassword"));
    resetPassword &&
      setCookie("resetPassword", JSON.stringify({ ...resetPassword, otp }));
    setAuth("changePassword");
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleOTP}
      initialValues={{ otp: "" }}
      validationSchema={otpValidation(t)}
    >
      <Form className="mt-2">
        <Field
          dir={locale === "en" ? "ltr" : "rtl"}
          name="otp"
          className="w-full max-sm:placeholder:text-sm max-sm:py-1.5 p-2 border rounded-md outline-none"
          placeholder={t("Otp")}
        />
        <ErrorMessage
          name="otp"
          component="p"
          className="max-sm:text-xs text-sm text-red-600 mt-0.5"
        />
        <button
          type="submit"
          className="flex items-center justify-center w-full h-8 sm:h-10 max-sm:py-1.5 max-sm:text-sm p-2 mt-2 font-bold text-white bg-red-600 rounded-md"
        >
          {t("Send")}
        </button>
      </Form>
    </Formik>
  );
};

export default Otp;
