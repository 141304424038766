import { useTranslation } from "next-i18next";
import CategoriesSwiper from "../global/swiper/CategoriesSwiper";
import SwiperButtons from "../global/buttons/SwiperButtons";
import { useState } from "react";
import { useRouter } from "next/router";

// used on home and cateogry page
const Categories = ({ data, title, showSwiperButtons=true }) => {
  const { t } = useTranslation("common");
  const [swiper, set_swiper] = useState({});
  const { locale } = useRouter();
  const activeData = data?.value?.filter((item) => item?.active);
  return (
    data?.active &&
    activeData?.length > 0 && (
      <section className="mx-auto">
        <div className={`flex items-center justify-between mb-3 sm:mb-4`}>
          <div className="flex flex-wrap items-center gap-4">
            <span className="text-lg font-extrabold sm:text-xl">
              {title ? title : t("Categories") }
            </span>
          </div>
          {showSwiperButtons && <SwiperButtons {...{ swiper, locale }} />}
        </div>
        <CategoriesSwiper
          set_swiper={set_swiper}
          categories={activeData || []}
        />
      </section>
    )
  );
};

export default Categories;
