import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import { resetNewPassword } from "@/services/auth";
import { changePasswordValidation } from "@/utils/valitation/auth";
import { deleteCookie, getCookie } from "cookies-next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";

import toast from "react-hot-toast";

const ChangePassword = ({ t, setAuth, locale }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handshake = getCookie("handshake");

  const handleChangePassword = async (values, { resetForm }) => {
    setLoading(true);
    const resetPassword = JSON.parse(getCookie("resetPassword"));

    const changedPassword = await resetNewPassword({
      handshake,
      email: resetPassword?.email,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
      resetToken: resetPassword?.otp,
    });

    if (changedPassword?.status == 200) {
      toast.success(t("PasswordUpdatedSuccessfully"));
      setLoading(false);
      resetForm();
      setAuth("");
      deleteCookie("resetPassword");
    } else {
      setAuth("otp");
      toast.error(changedPassword?.error?.error?.message);
      setLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={handleChangePassword}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={changePasswordValidation(t)}
    >
      <Form>
        <div className="relative mt-2">
          <Field
            name="password"
            type={show ? "text" : "password"}
            className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
            placeholder={t("Password")}
          />
          <button
            type="button"
            onClick={() => setShow(!show)}
            className={`absolute z-20 -translate-y-1/2 cursor-pointer ${
              locale === "ar" ? "left-2" : "right-2"
            } top-1/2 text-zinc-400`}
          >
            {show ? <Eyelock className="size-5" /> : <Eye />}
          </button>
        </div>
        <ErrorMessage
          name="password"
          component="p"
          className="max-sm:text-xs text-sm text-red-600 mt-0.5"
        />
        <div className="relative mt-2">
          <Field
            name="confirmPassword"
            type={show ? "text" : "password"}
            className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
            placeholder={t("Confirm Password")}
          />
          <button
            type="button"
            onClick={() => setShow(!show)}
            className={`absolute z-20 -translate-y-1/2 cursor-pointer ${
              locale === "ar" ? "left-2" : "right-2"
            } top-1/2 text-zinc-400`}
          >
            {show ? <Eyelock className="size-5" /> : <Eye />}
          </button>
        </div>
        <ErrorMessage
          name="confirmPassword"
          component="p"
          className="max-sm:text-xs text-sm text-red-600 mt-0.5"
        />
        <button
          type="submit"
          className="flex items-center justify-center w-full h-8 max-sm:text-sm sm:h-10 max-sm:py-1.5 p-2 mt-2 font-bold text-white bg-red-600 rounded-md"
          disabled={loading}
        >
          {loading && <div className="loader" />} {t("Change Password")}
        </button>
      </Form>
    </Formik>
  );
};

export default ChangePassword;
