import axios from "axios";

export const getFetcher = async (url) => {
  const req = await axios.get(url);
  return req.data;
};

export const postFetcher = async (url, { arg }) => {
  const updatedArgs = {};

  for (let updatedArg of Object.keys(arg || {})) {
    updatedArgs[updatedArg] = arg[updatedArg];
  }
  
  try {
    const res = await axios.post(url, updatedArgs);
    return res?.data || null;
  } catch (err) {
    return err
  }
};