// import { getProductValueFromProductAttributes } from "@/helpers/product";
import { useRouter } from "next/router";

const ExtraAttributes = ({ sku, fromModal }) => {
  const { locale } = useRouter();
  return (
    <div
      className={`flex items-center gap-4 flex-wrap ${
        fromModal && "max-sm:hidden"
      }`}
    >
      <div className="flex items-center gap-2 max-sm:text-sm">
        {/* <span className="text-zinc-400">
          {locale === "en" ? "Type" : "النوع"} :
        </span>
        <span>كرسي هزاز</span> */}
        {/* <span>
          {getProductValueFromProductAttributes(
            product_attributes,
            "Model Number",
            locale
          )}
        </span> */}
      </div>
      {/* <span className="hidden text-zinc-400 sm:block"> | </span> */}
      {/* {model && (
        <>
          <div className="flex items-center gap-2 max-sm:text-sm">
            <span className="text-zinc-400">
              {locale === "en" ? "Model" : "الموديل"} :
            </span>
            <span> {model?.join(",")} </span>
          </div>
          <span className="hidden text-zinc-400 sm:block"> | </span>
        </>
      )} */}
      <div className="flex items-center gap-2 max-sm:text-sm">
        <span className="text-zinc-400">
          {locale === "en" ? "SKU" : "الكود"} :
        </span>
        <span>{sku}</span>
      </div>
    </div>
  );
};

export default ExtraAttributes;
