const Location = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99992 9.44679C6.57992 9.44679 5.41992 8.29346 5.41992 6.86679C5.41992 5.44012 6.57992 4.29346 7.99992 4.29346C9.41992 4.29346 10.5799 5.44679 10.5799 6.87346C10.5799 8.30012 9.41992 9.44679 7.99992 9.44679ZM7.99992 5.29346C7.13326 5.29346 6.41992 6.00012 6.41992 6.87346C6.41992 7.74679 7.12659 8.45346 7.99992 8.45346C8.87326 8.45346 9.57992 7.74679 9.57992 6.87346C9.57992 6.00012 8.86659 5.29346 7.99992 5.29346Z"
      fill="currentColor"
    />
    <path
      d="M8.00012 15.1735C7.01345 15.1735 6.02012 14.8002 5.24678 14.0602C3.28012 12.1668 1.10678 9.14683 1.92678 5.5535C2.66678 2.2935 5.51345 0.833496 8.00012 0.833496C8.00012 0.833496 8.00012 0.833496 8.00678 0.833496C10.4935 0.833496 13.3401 2.2935 14.0801 5.56016C14.8934 9.1535 12.7201 12.1668 10.7534 14.0602C9.98012 14.8002 8.98678 15.1735 8.00012 15.1735ZM8.00012 1.8335C6.06012 1.8335 3.56678 2.86683 2.90678 5.7735C2.18678 8.9135 4.16012 11.6202 5.94678 13.3335C7.10012 14.4468 8.90678 14.4468 10.0601 13.3335C11.8401 11.6202 13.8134 8.9135 13.1068 5.7735C12.4401 2.86683 9.94012 1.8335 8.00012 1.8335Z"
      fill="currentColor"
    />
  </svg>
);
export default Location;
