// import { Swiper, SwiperSlide } from "swiper/react";
// import { ProductsData } from "@/constants/Home";
import { useContext, useEffect, useRef } from "react";
import { useRouter } from "next/router";

import Modal from "..";
import Link from "next/link";
import CloseIcon from "../../icons/CloseIcon";
// import ProductCard from "../cards/ProductCard";
// import useHandleOutSide from "@/hooks/useHandleOutSide";
import { ModalContext } from "@/pages/_app";
import { useCart } from "@/context/cart";
import Image from "@/components/global/Image";
import CartModalSwiper from "../CartModalSwiper";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import CartModalItem from "./CartModalItem";
import useSWR from "swr";
import { getCookie } from "cookies-next";
import { getProductsBySkus } from "@/services/product";

const CartModal = () => {
  const ref = useRef();
  const { locale } = useRouter();
  const { cart } = useCart();
  const { state, dispatch } = useContext(ModalContext);
  useHandleOutSide(ref, () => dispatch({ type: "cart_info" }));
  const handShake = getCookie("handshake");

  // const cartItem = cart?.cartItems?.at(0)

  useEffect(() => {
    !cart?.cartItems ||
      (cart?.cartItems?.length == 0 && dispatch({ type: "cart_info" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.cartItems]);

  const relatedProducts = cart?.cartItems
    ?.flatMap((item) => item?.custom_related_products)
    ?.filter((item) => item != undefined);

  const { data: productsData } = useSWR(
    ["cart-modal-related-products", locale, relatedProducts],
    () => getProductsBySkus(handShake, relatedProducts),
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: true,
    }
  );

  return (
    <Modal show={state?.cartInfo}>
      <div
        ref={ref}
        className="min-h-screen max-h-screen overflow-y-auto p-3 pb-0 bg-white w-11/12 sm:w-[500px] relative sm:ps-7 red-scroll"
      >
        <div className="flex items-center justify-between pb-2 border-b sm:pb-3">
          <h2 className="font-bold sm:text-lg md:text-xl">
            {locale === "en" ? "My Cart" : "سلة التسوق"}
          </h2>
          <button onClick={() => dispatch({ type: "cart_info" })}>
            <CloseIcon className="size-5" />
          </button>
        </div>
        {cart?.cartItems?.map((cartItem, key) => {
          return <CartModalItem key={key} cartItem={cartItem} />;
        })}
        <Image
          loading="lazy"
          src="/imgs/cart/divide-tamara-tabby.png"
          className={`w-full h-auto ${
            cart?.cartItems
              ?.map((item) => item?.custom_related_products)
              ?.flat()
              ?.filter((item) => item != undefined) &&
            Array.isArray(cart?.cartItems) &&
            "mb-16"
          }`}
          alt="Divide Tamara Tabby Img!"
        />
        {productsData?.data?.data?.products?.length > 0 && (
          <div className="my-3 mb-16">
            <p className="mb-3 text-lg font-bold">
              {locale === "en"
                ? "People who bought this also bought"
                : "منتجات ذات صلة"}
            </p>
            {<CartModalSwiper products={productsData?.data?.data?.products} />}
          </div>
        )}
        <div
          className={`fixed bottom-0 start-0 z-50 grid w-11/12 ${
            locale === "en" ? "sm:w-[500px]" : "sm:w-[480px]"
          } grid-cols-2 gap-2 p-2 bg-white max-sm:text-sm`}
        >
          <Link
            href="/cart"
            onClick={() => dispatch({ type: "cart_info" })}
            className={`${
              locale === "ar" && "max-sm:px-1"
            } p-2 font-semibold text-center text-white bg-red-600 rounded-sm`}
          >
            {locale === "en" ? "Go To Cart" : "الذهاب لسلة التسوق"}{" "}
          </Link>
          <Link
            href="/checkout"
            onClick={() => dispatch({ type: "cart_info" })}
            className={`${
              locale === "ar" && "max-sm:px-1"
            } p-2 font-semibold text-center text-white bg-black rounded-sm`}
          >
            {locale === "en" ? "Checkout" : "الدفع"}
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default CartModal;
