const Image = ({ src, alt, loading = "lazy", ...rest }) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      // src={src || "/imgs/blackbox.jpg"}
      src={src || "/imgs/placeholder.png"}
      alt={alt}
      loading={loading}
      className={
        rest?.fill ? "w-full object-fill " + rest?.className : rest?.className
      }
      {...rest}
    />
  );
};

export default Image;
