// use selected attributes to retrive only needed data from backend
const requiredAtteributes = [
  "sku",
  "name",
  "option_text_brand",
  "prices_with_tax",
  "image",
  "_media_",
  "stock",
  "label",
  "product_fees",
  "entity_id",
  "rewrite_url",
  "category",
  "rewiews_count",
  "attributes",
  "product_attributes",
  "eligible_for_tabby",
  "eligible_for_tamara",
  "product_fees",
  "option_text_eligible_for_tabby",
  "option_text_eligible_for_tamara",
  "back_orders",
  "show_outofstock",
  "_configurables",
  "type_id",
];

const selectedAtteributes = requiredAtteributes
  ?.map((attr) => `select=${attr}`)
  ?.join("&");
export default selectedAtteributes;
