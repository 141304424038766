const CategoryIcon = (props) => (
  <svg
    width={19}
    height={20}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.81474 8.93537H5.51845C7.57993 8.93537 8.71289 7.8024 8.71289 5.74092V4.03722C8.71289 1.97574 7.57993 0.842773 5.51845 0.842773H3.81474C1.75326 0.842773 0.620298 1.97574 0.620298 4.03722V5.74092C0.620298 7.8024 1.75326 8.93537 3.81474 8.93537ZM5.51845 2.12055C6.86437 2.12055 7.43511 2.69129 7.43511 4.03722V5.74092C7.43511 7.08685 6.86437 7.65759 5.51845 7.65759H3.81474C2.46882 7.65759 1.89808 7.08685 1.89808 5.74092V4.03722C1.89808 2.69129 2.46882 2.12055 3.81474 2.12055H5.51845Z"
      fill="currentColor"
    />
    <path
      d="M14.0369 19.1575H15.7406C17.8021 19.1575 18.9351 18.0246 18.9351 15.9631V14.2594C18.9351 12.1979 17.8021 11.0649 15.7406 11.0649H14.0369C11.9754 11.0649 10.8425 12.1979 10.8425 14.2594V15.9631C10.8425 18.0246 11.9754 19.1575 14.0369 19.1575ZM15.7406 12.3427C17.0865 12.3427 17.6573 12.9135 17.6573 14.2594V15.9631C17.6573 17.309 17.0865 17.8798 15.7406 17.8798H14.0369C12.691 17.8798 12.1202 17.309 12.1202 15.9631V14.2594C12.1202 12.9135 12.691 12.3427 14.0369 12.3427H15.7406Z"
      fill="currentColor"
    />
    <path
      d="M14.8888 8.93537C17.1206 8.93537 18.9351 7.12092 18.9351 4.88907C18.9351 2.65722 17.1206 0.842773 14.8888 0.842773C12.6569 0.842773 10.8425 2.65722 10.8425 4.88907C10.8425 7.12092 12.6569 8.93537 14.8888 8.93537ZM14.8888 2.12055C16.4136 2.12055 17.6573 3.36425 17.6573 4.88907C17.6573 6.41388 16.4136 7.65759 14.8888 7.65759C13.3639 7.65759 12.1202 6.41388 12.1202 4.88907C12.1202 3.36425 13.3639 2.12055 14.8888 2.12055Z"
      fill="currentColor"
    />
    <path
      d="M4.66659 19.1575C6.89845 19.1575 8.71289 17.3431 8.71289 15.1112C8.71289 12.8794 6.89845 11.0649 4.66659 11.0649C2.43474 11.0649 0.620298 12.8794 0.620298 15.1112C0.620298 17.3431 2.43474 19.1575 4.66659 19.1575ZM4.66659 12.3427C6.19141 12.3427 7.43511 13.5864 7.43511 15.1112C7.43511 16.6361 6.19141 17.8798 4.66659 17.8798C3.14178 17.8798 1.89808 16.6361 1.89808 15.1112C1.89808 13.5864 3.14178 12.3427 4.66659 12.3427Z"
      fill="currentColor"
    />
  </svg>
);
export default CategoryIcon;
