import { forgetSendOtp } from "@/services/auth";
import { forgotValidation } from "@/utils/valitation/auth";
import { getCookie, setCookie } from "cookies-next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import toast from "react-hot-toast";

const Forgot = ({ t, setAuth }) => {
  const handshake = getCookie("handshake");
  const { locale } = useRouter();
  const handleForgotApi = async (
    { email },
    { setSubmitting, setFieldError }
  ) => {
    setSubmitting(true);
    const res = await forgetSendOtp(handshake, email);
    if (res?.data?.data?.status) {
      setCookie("resetPassword", JSON.stringify({ email }));
      // toast.success(res?.data?.message);
      toast.success(
        locale === "en"
          ? "Please check your email to get an otp"
          : "برجاء الذهاب للبريد الإلكترونى للحصول على رمز التحقق"
      );
      setAuth("otp");
    } else {
      // toast.error(res?.data?.data?.message);
      toast.error(
        locale === "en"
          ? "Error Occured, please try again"
          : "حدث خطأ ما, برجاء المحاولة مره اخرى"
      );
      setFieldError(
        "email",
        locale === "en"
          ? "Email is not valid, please try again"
          : "البريد غير صحيح, برجاء المحاولة مره اخرى"
      );
    }
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleForgotApi}
      initialValues={{ email: "" }}
      validationSchema={forgotValidation(t)}
    >
      {({ isSubmitting }) => (
        <Form className="mt-2">
          <Field
            name="email"
            type="email"
            className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
            placeholder={t("Email Address")}
          />
          <ErrorMessage
            name="email"
            component="p"
            className="max-sm:text-xs text-sm text-red-600 mt-0.5"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className="flex items-center justify-center w-full h-[34px] sm:h-10 max-sm:text-sm p-2 mt-2 font-bold text-white bg-red-600 rounded-md disabled:cursor-not-allowed disabled:opacity-75 gap-1"
          >
            {isSubmitting && <div className="loader" />} {t("Send")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Forgot;
