import Image from "@/components/global/Image";
import { Payments } from "@/constants/Home";
import { useRouter } from "next/router";

const CopyRights = () => {
  const { locale } = useRouter();
  const currentYear = new Date().getFullYear();

  return (
    <div className="pb-4 sm:py-3 max-lg:pb-20 bg-[#121212] max-sm:bg-black max-sm:border-t max-sm:pt-3 ">
      <div className="flex flex-col-reverse items-center justify-center gap-2 mx-auto sm:flex-row sm:justify-between w-11/12">
        <div className="flex items-center text-[#A9A5A5] max-sm:text-white gap-2 max-sm:flex-col text-xs sm:text-base">
          <p className="text-center uppercase">
            {locale === "ar"
              ? `جميع الحقوق محفوظة لشركة باك كمفورت   ${currentYear}`
              : `CopyRight © ${new Date().getFullYear()} By BackComfort . All Right Reserved`}
          </p>
          <span className="max-sm:hidden">-</span>
          <p className="text-center">
            <span>
              {locale === "ar" ? "تم التطوير بواسطة: " : "Powered by : "}
            </span>

            <a href="https://v4tech.com/" target="_blank" className="font-bold">
              V4tech
            </a>
          </p>
        </div>
        <div className="flex items-center gap-3">
          {Payments.map((payment) => (
            <Image
              key={payment}
              loading="lazy"
              src={`/imgs/footer/${payment}.png`}
              className="object-contain w-8 h-8"
              alt=""
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CopyRights;
