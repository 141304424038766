import CallIcon from "@/components/icons/CallIcon";
import EmailIcon from "@/components/icons/EmailIcon";
import LocaleSwitcher from "@/components/global/buttons/LocaleSwitcher";
import ArrowIcon from "@/components/icons/ArrowIcon";
import Link from "next/link";

const TopHeader = ({ t, locale }) => {
  return (
    <header className="py-2.5 sm:py-3 text-white bg-red-600">
      <div className="items-center justify-between hidden mx-auto lg:flex w-11/12">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1.5 cursor-pointer">
            <ArrowIcon className="mt-0.5" />
            <span className="font-semibold">
              <LocaleSwitcher />
            </span>
          </div>
          <Link
            href={"/static/about"}
            className={`font-semibold cursor-pointer ${
              locale === "ar" ? "pr-3 border-r" : "pl-3 border-l"
            }`}
          >
            {t("About Us")}
          </Link>
        </div>
        <div className="flex items-center gap-4">
          <Link
            href={"tel:920000440"}
            className={`flex items-center flex-row-reverse gap-1 cursor-pointer ${
              locale === "en" ? "pr-3 border-r" : "pl-3 border-l"
            }`}
          >
            <span className="font-semibold">920000440</span>
            <CallIcon />
          </Link>
          <Link
            href={"mailto:info@backcomfort.com.sa"}
            className={`cursor-pointer flex items-center flex-row-reverse gap-1 `}
          >
            <span className="font-semibold">info@backcomfort.com.sa</span>
            <EmailIcon />
          </Link>
        </div>
      </div>
      <p className="block text-center lg:hidden max-sm:text-sm">{t("DiscountHeader")}</p>
    </header>
  );
};

export default TopHeader;
