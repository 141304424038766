export const Payments = [
  "mastercard",
  "visa",
  "mada",
  "stcpay",
  "applepay",
  "tamara",
  "tabby",
];

export const ProductsData = [
  {
    img: "/imgs/products/1.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/2.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/3.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/4.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/5.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/6.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/7.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/8.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/9.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/10.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/11.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
  {
    img: "/imgs/products/12.png",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, odio iste deserunt repudiandae alias consequuntur nostrum aliquam quibusdam dolorem laboriosam?",
    category: "Category",
    originalPrice: "2500",
    discountPrice: "2300",
  },
];
