import ArrowIcon from "@/components/icons/ArrowIcon";
import Link from "next/link";

import { useState } from "react";
import { useRouter } from "next/router";
import { useGlobalData } from "@/context/global";
import { modifyMenuTreeData } from "@/helpers/category";

const CategoryListItems = ({ onHide, isMobile, setOpen }) => {
  const {
    appData: { menuTreeData },
  } = useGlobalData();
  
  const { locale } = useRouter();
  const [isShowChild, setIsShowChild] = useState(
    Array(menuTreeData?.length).fill(false)
  );

  const toggleSubCategory = (index) => {
    setIsShowChild((prev) => {
      const newVisibilityState = [...prev];
      newVisibilityState[index] = !newVisibilityState[index];
      return newVisibilityState;
    });
  };
  return (
    <div
      className={`${
        !isMobile ? (locale === "en" ? "ml-[12%] px-6" : "mr-[12%] px-6") : ""
      }`}
    >
      {menuTreeData?.map((cat, i) => {
        const parentUrl = `/${cat.url_key}`;
        const hasChildren = modifyMenuTreeData(cat?.children_data)?.length > 0;
        return (
          <div key={i}>
            <div
              onClick={() => hasChildren && toggleSubCategory(i)}
              className={`flex items-center justify-between ${isShowChild[i] ? "pb-2" : "pb-5"} cursor-pointer`}
            >
              <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    onHide && onHide();
                    setOpen && setOpen(false)
                  }}
                  href={parentUrl}
                  className={`uppercase ${
                    isShowChild[i] ? "font-bold" : !isMobile ? "font-medium" : "font-bold"
                  }`}
                >
                  {cat.name}
              </Link>
              {hasChildren && (
                <ArrowIcon
                  className={`transition-all ${
                    isShowChild[i]
                      ? "rotate-270"
                      : locale === "en"
                      ? "-rotate-90"
                      : "rotate-90"
                  }`}
                />
              )}
            </div>

            {isShowChild[i] && (
              <SubMenu
                data={cat?.children_data}
                parentUrl={parentUrl}
                onHide={onHide}
                setOpen={setOpen}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CategoryListItems;

const SubMenu = ({ data, parentUrl, onHide, setOpen }) => {
  const { locale } = useRouter();
  const modifiedData = modifyMenuTreeData(data);
  const [isShowChild, setIsShowChild] = useState(
    Array(modifiedData?.length).fill(false)
  );

  const toggleSubCategory = (index) => {
    setIsShowChild((prev) => {
      const newVisibilityState = [...prev];
      newVisibilityState[index] = !newVisibilityState[index];
      return newVisibilityState;
    });
  };

  return (
    <div className={`${locale === "en" ? "pl-2 pr-2" : "pr-2 pl-2"} pb-2 sm:pb-4`}>
      {modifiedData?.map((sub, i) => {
        const childUrl = `${parentUrl}/${sub.url_key}`;
        const hasChildren = modifyMenuTreeData(sub?.children_data)?.length > 0;

        return (
          <div key={i}>
            <div
              onClick={() => hasChildren && toggleSubCategory(i)}
              className={`cursor-pointer flex items-center justify-between pb-2 sm:pb-4 ${i === modifiedData?.length -1}`}
            >
              <Link
                href={childUrl}
                onClick={(e) => {
                  e.stopPropagation();
                  onHide && onHide();
                  setOpen && setOpen(false)
                }}
                className={`text-zinc-500 text-sm  ${
                  isShowChild[i] ? "font-bold" : "font-medium"
                }`}
              >
                {sub.name}
              </Link>
              {hasChildren && (
                <ArrowIcon
                  className={`size-2 transition-all ${
                    locale === "ar" && "rotate-180"
                  } ${isShowChild[i] ? "rotate-90" : ""}`}
                />
              )}
            </div>
            {isShowChild[i] && (
              <SubMenu data={sub?.children_data} parentUrl={childUrl} />
            )}
          </div>
        );
      })}
    </div>
  );
};
