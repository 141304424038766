import MinusIcon from "@/components/icons/MinusIcon";
import AddIcon from "@/components/icons/AddIcon";

import { useState } from "react";

const FooterContactUs = ({ t }) => {
  const [active, setActive] = useState(false);
  return (
    <div className="col-span-full md:col-span-3 lg:col-span-1">
      <button
        onClick={() => setActive(!active)}
        className="flex items-center justify-between w-full mb-4"
      >
        <span className="font-bold max-sm:text-xs">{t("Contact Us")}</span>
        {!active ? (
          <AddIcon className="size-3 sm:size-5 md:hidden" />
        ) : (
          <MinusIcon className="size-3 sm:size-5 md:hidden" />
        )}
      </button>
      <ul className={`${active ? "flex" : "hidden"} md:flex flex-col gap-2.5`}>
        <li className="text-[#817B7B] max-sm:text-xs">{t("Email Address")}</li>
        <li className="text-sm max-sm:text-xs">info@backcomfort.com.sa</li>
        <li className="text-[#817B7B] max-sm:text-xs">{t("Customer Service")}</li>
        <li className="text-sm max-sm:text-xs">920000440</li>
      </ul>
    </div>
  );
};

export default FooterContactUs;
