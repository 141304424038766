const GridEditIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66671 15.1668H6.00004C2.38004 15.1668 0.833374 13.6202 0.833374 10.0002V6.00016C0.833374 2.38016 2.38004 0.833496 6.00004 0.833496H10C13.62 0.833496 15.1667 2.38016 15.1667 6.00016V7.3335C15.1667 7.60683 14.94 7.8335 14.6667 7.8335C14.3934 7.8335 14.1667 7.60683 14.1667 7.3335V6.00016C14.1667 2.92683 13.0734 1.8335 10 1.8335H6.00004C2.92671 1.8335 1.83337 2.92683 1.83337 6.00016V10.0002C1.83337 13.0735 2.92671 14.1668 6.00004 14.1668H6.66671C6.94004 14.1668 7.16671 14.3935 7.16671 14.6668C7.16671 14.9402 6.94004 15.1668 6.66671 15.1668Z"
      fill="currentColor"
    />
    <path
      d="M14.6668 6.1665H1.35352C1.08018 6.1665 0.853516 5.93984 0.853516 5.6665C0.853516 5.39317 1.08018 5.1665 1.35352 5.1665H14.6668C14.9402 5.1665 15.1668 5.39317 15.1668 5.6665C15.1668 5.93984 14.9402 6.1665 14.6668 6.1665Z"
      fill="currentColor"
    />
    <path
      d="M8.00016 10.8335H1.35352C1.08018 10.8335 0.853516 10.6068 0.853516 10.3335C0.853516 10.0602 1.08018 9.8335 1.35352 9.8335H8.00016C8.2735 9.8335 8.50016 10.0602 8.50016 10.3335C8.50016 10.6068 8.2735 10.8335 8.00016 10.8335Z"
      fill="currentColor"
    />
    <path
      d="M5.67322 15.16C5.39988 15.16 5.17322 14.9333 5.17322 14.66V1.34668C5.17322 1.07335 5.39988 0.84668 5.67322 0.84668C5.94655 0.84668 6.17322 1.07335 6.17322 1.34668V14.66C6.17322 14.9333 5.95322 15.16 5.67322 15.16Z"
      fill="currentColor"
    />
    <path
      d="M10.3398 8.49333C10.0665 8.49333 9.83984 8.26666 9.83984 7.99333V1.34668C9.83984 1.07335 10.0665 0.84668 10.3398 0.84668C10.6132 0.84668 10.8398 1.07335 10.8398 1.34668V7.99333C10.8398 8.26666 10.6198 8.49333 10.3398 8.49333Z"
      fill="currentColor"
    />
    <path
      d="M9.84003 15.1666C9.56003 15.1666 9.30668 15.0666 9.12002 14.8799C8.89335 14.6599 8.79337 14.3333 8.8467 13.9933L9.00002 12.9333C9.03335 12.68 9.18669 12.3733 9.37335 12.1866L12.14 9.41996C12.5 9.05996 12.8534 8.87329 13.2267 8.83329C13.6867 8.78663 14.1467 8.98663 14.58 9.41996C15.0134 9.8533 15.2134 10.3066 15.1667 10.7733C15.1334 11.1466 14.94 11.5 14.58 11.86L11.8133 14.6266C11.6333 14.8066 11.3267 14.96 11.0667 15L10.0134 15.1533C9.95337 15.1599 9.89336 15.1666 9.84003 15.1666ZM13.3467 9.83329C13.34 9.83329 13.3267 9.83329 13.32 9.83329C13.1867 9.84663 13.0267 9.94659 12.84 10.1333L10.0733 12.8999C10.04 12.9333 9.99334 13.0333 9.98667 13.08L9.83335 14.1399L10.92 14.02C10.9667 14.0133 11.0667 13.96 11.1 13.9333L13.8667 11.1666C14.0533 10.98 14.1534 10.82 14.1667 10.6866C14.18 10.5333 14.08 10.3533 13.8667 10.1399C13.6667 9.93327 13.4934 9.83329 13.3467 9.83329Z"
      fill="currentColor"
    />
    <path
      d="M13.8267 12.4064C13.7801 12.4064 13.7401 12.3997 13.6934 12.3864C12.6934 12.1064 11.8934 11.3064 11.6134 10.3064C11.5401 10.0397 11.6934 9.76644 11.9601 9.69311C12.2334 9.61978 12.5001 9.77312 12.5734 10.0398C12.7601 10.7065 13.2934 11.2398 13.9601 11.4264C14.2267 11.4998 14.3801 11.7798 14.3068 12.0398C14.2468 12.2665 14.0401 12.4064 13.8267 12.4064Z"
      fill="currentColor"
    />
  </svg>
);
export default GridEditIcon;
