import { register } from "@/services/auth";
import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import { registerValidation } from "@/utils/valitation/auth";
import { getCookie } from "cookies-next";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { preventLettersInInput } from "@/utils/global";

const Register = ({ t, setAuth, locale }) => {
  const handshake = getCookie("handshake");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const intialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: "",
    mobile: "",
  };

  const handleRegister = async (values, { resetForm }) => {
    setLoading(true);

    const { error } = await register(handshake, values);
    if (error) {
      // handling the error coming from backend and show to client
      // There is already an account registered with this email: gamal@gmail.com
      // 00966566828456 = This mobile number is already registered.
      if (
        error?.error?.message?.includes(
          "There is already an account registered with this email"
        )
      ) {
        toast.error(
          t("errorRegEmailMessage", {
            email: error?.error?.message?.split(" ").pop(),
          })
        );
      } else if (
        error?.error?.message?.includes(
          "This mobile number is already registered"
        )
      ) {
        toast.error(
          t("errorRegPhoneMessage", {
            mobile: error?.error?.message?.split(" ")[0],
          })
        );
      } else
        toast.error(
          locale === "en"
            ? "Error Occured, please try again"
            : "حدث خطأ ما, برجاء المحاولة مره أخرى"
        );
      return setLoading(false);
    } else {
      toast.success(locale === "en" ? "Register success" : "تم التسجيل بنجاح");
      resetForm();
      setAuth("login");
      return setLoading(false);
    }
  };
  return (
    <div>
      <p className="my-4 text-center sm:my-7 max-sm:text-sm">
        <span className="font-bold">{t("You can create a new account")} </span>
        <span className="font-semibold text-zinc-500">
          {t(
            "To enjoy the site’s benefits, such as tracking orders and registering"
          )}{" "}
          {t("products in your favorites")}
        </span>
      </p>
      <Formik
        onSubmit={handleRegister}
        initialValues={intialValues}
        validationSchema={registerValidation(t)}
      >
        <Form className="grid grid-cols-2 gap-2">
          <div className="col-span-full sm:col-span-1">
            <Field
              name="firstname"
              className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
              placeholder={t("First Name")}
            />
            <ErrorMessage
              name="firstname"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="col-span-full sm:col-span-1">
            <Field
              name="lastname"
              className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
              placeholder={t("Last Name")}
            />
            <ErrorMessage
              name="lastname"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="mt-2 col-span-full">
            <Field
              name="email"
              className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
              placeholder={t("Email Address")}
            />
            <ErrorMessage
              name="email"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="mt-2 col-span-full">
            <Field
              className={`w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none ${
                locale === "ar" && "text-end"
              }`}
              name="mobile"
              placeholder={t("5xxxxxxxx")}
              type="tel"
              id="mobile"
              onKeyDown={(event) => {
                preventLettersInInput(event);
              }}
              maxLength={9}
            />
            <ErrorMessage
              name="mobile"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="col-span-full">
            <div className="relative mt-2">
              <Field
                name="password"
                type={show ? "text" : "password"}
                className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
                placeholder={t("Password")}
              />
              <button
                type="button"
                onClick={() => setShow(!show)}
                className={`absolute z-20 -translate-y-1/2 cursor-pointer ${
                  locale === "ar" ? "left-2" : "right-2"
                } top-1/2 text-zinc-400`}
              >
                {show ? <Eyelock className="size-5" /> : <Eye />}
              </button>
            </div>
            <ErrorMessage
              name="password"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="col-span-full">
            <div className="relative mt-2">
              <Field
                name="confirmPassword"
                type={show ? "text" : "password"}
                className="w-full max-sm:py-1.5 max-sm:placeholder:text-sm max-sm:text-sm p-2 border rounded-md outline-none"
                placeholder={t("Confirm Password")}
              />
              <button
                type="button"
                onClick={() => setShow(!show)}
                className={`absolute z-20 -translate-y-1/2 cursor-pointer ${
                  locale === "ar" ? "left-2" : "right-2"
                } top-1/2 text-zinc-400`}
              >
                {show ? <Eyelock className="size-5" /> : <Eye />}
              </button>
            </div>
            <ErrorMessage
              name="confirmPassword"
              component="p"
              className="text-sm text-red-600 mt-0.5 max-sm:text-xs"
            />
          </div>
          <div className="flex items-center flex-wrap gap-2 *:cursor-pointer col-span-full my-1">
            <Field
              name="terms"
              id="terms-privacy"
              type="checkbox"
              className="border rounded-sm appearance-none size-4 checked:bg-red-600 checked:border-red-600"
            />
            <label
              htmlFor="terms-privacy"
              className="font-semibold text-zinc-500 max-sm:text-sm"
            >
              {t("I Accept")}{" "}
              <span className="font-bold underline">
                {t("Terms & Conditions")}
              </span>
            </label>
            <ErrorMessage
              name="terms"
              component="p"
              className="w-full text-sm text-red-600 max-sm:text-xs"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="flex items-center justify-center w-full h-8 max-sm:text-sm sm:h-10 max-sm:py-1.5 p-2 font-bold text-white bg-red-600 rounded-md col-span-full disabled:opacity-75 disabled:cursor-not-allowed"
          >
            {loading ? (
              <span className="border-t border-r border-white rounded-full animate-spin size-4" />
            ) : (
              t("Register")
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default Register;
