import { createContext, useReducer, useEffect } from "react";
import wishlistReducer from "./Reducers";
import store from "store";

const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
  const [wishlist, dispatch] = useReducer(
    wishlistReducer,
    store.get("wishlist") ? JSON.parse(store.get("wishlist")) : {}
  );

  useEffect(() => {
    store.set("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  return (
    <WishlistContext.Provider value={{ wishlist, dispatch }}>
      {children}
    </WishlistContext.Provider>
  );
};

export { WishlistContext, WishlistProvider };
