import { useRouter } from "next/router";
import Link from "next/link";
import Image from "../global/Image";

const CategoryCard = ({ category }) => {
  const { locale } = useRouter();
  const imgSrc =
    !category?.["img-ar"]?.startsWith("http") && category?.["img-ar"] != null
      ? `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${
          locale === "ar"
            ? category?.["img-ar"].slice(6)
            : category?.["img-en"].slice(6)
        }`
      : category?.["img-ar"];

  return (
    <>
      <div className="relative rounded-md cursor-pointer group">
        <div className="absolute z-50 flex flex-col gap-1 top-2 sm:top-4 start-2 sm:start-4">
          <span className="text-sm font-bold sm:text-xl">
            {category[`title-${locale}`] || ""}
          </span>
          {/* <span className="max-sm:text-[10px] text-zinc-500">
            {data?.product_count
              ? data?.product_count + (locale === "ar" ? " منتج" : " product")
              : ""}
          </span> */}
        </div>
        <Link
          href={`/category/${category?.["category-url"]}`}
          className="relative flex items-center justify-center h-auto"
        >
          <Image
            loading="eager"
            src={imgSrc}
            className="object-contain w-full h-auto cursor-pointer"
            alt=""
          />
        </Link>
      </div>
    </>
  );
};

export default CategoryCard;
