import CartIcon from "@/components/icons/CartIcon";

import { useRouter } from "next/router";
import { useCart } from "@/context/cart";
import { ModalContext } from "@/pages/_app";
import { useContext, useMemo, useState } from "react";
import { handleAddItemToCart } from "@/utils/product";
import { useTranslation } from "next-i18next";
import { event_view_item } from "@/utils/gtm";

const AddToCart = ({
  product,
  qty = 1,
  fromPDP,
  configurations,
  sku = null,
  isFast = false,
  onHide,
  configurPrice,
  chosenConfigurableProduct,
  configIndex,
}) => {
  const { dispatch } = useCart();
  const context = useContext(ModalContext);
  const { push, locale } = useRouter();
  const [loading, setLoading] = useState(false);
  const [loadingFast, setLoadingFast] = useState(false);
  const { t } = useTranslation("common");
  const isInStock = useMemo(
    () =>
      chosenConfigurableProduct
        ? chosenConfigurableProduct?.quantity > 0
        : product?.stock?.qty >= 0 && product?.stock?.is_in_stock == true,
    [chosenConfigurableProduct, product]
  );

  const handleAddToCart = async (type) => {
    type == "fast" ? setLoadingFast(true) : setLoading(true);
    const relatedProducts = [];
    // const region_id = "1101"
    if (product?.stock?.qty > 0 && product?.stock?.is_in_stock) {
      await handleAddItemToCart(
        t,
        sku
          ? {
              ...product,
              sku,
              ...(chosenConfigurableProduct
                ? {
                    _media_: chosenConfigurableProduct?._media_,
                    name: chosenConfigurableProduct?.name,
                  }
                : {}),
              ...(configurPrice ? { prices_with_tax: configurPrice } : {}),
            }
          : product,
        qty,
        type == "fast" ? setLoadingFast : setLoading,
        dispatch,
        configurations,
        context,
        relatedProducts,
        type,
        push
      );
      if (onHide) {
        onHide();
      }
    }
    if (type == "fast") {
      event_view_item(product, configIndex, "add_to_cart", qty);
      setLoadingFast(false);
    } else {
      setLoading(false);
      event_view_item(product, configIndex, "add_to_cart", qty);
    }
  };
  return !fromPDP ? (
    isInStock && (
      <button
        onClick={handleAddToCart}
        disabled={loading || !isInStock}
        className="flex items-center justify-center text-white bg-red-600 rounded-full disabled:cursor-not-allowed sm:opacity-0 sm:group-hover:opacity-100 size-[30px] sm:size-9"
      >
        {loading ? (
          <span className="border-t border-r border-white rounded-full animate-spin size-4" />
        ) : (
          <CartIcon className="size-4 sm:size-5" />
        )}
      </button>
    )
  ) : (
    <div className="flex items-center w-full gap-2">
      <button
        onClick={handleAddToCart}
        disabled={loading || !isInStock}
        className={`w-full h-10 p-2 text-sm font-bold text-white bg-red-500 rounded-md max-sm:h-8 max-sm:p-1 disabled:cursor-not-allowed max-sm:text-xs disabled:bg-red-400 col-span-full lg:col-span-6  ${
          isInStock ? "sm:w-1/2" : ""
        }`}
      >
        {loading ? (
          <span className="size-4">
            {locale === "en" ? "Buying..." : "جاري الشراء..."}.
          </span>
        ) : isInStock ? (
          locale === "en" ? (
            "Add To Cart"
          ) : (
            "أضف للعربة"
          )
        ) : locale === "en" ? (
          "Out Of Stock"
        ) : (
          "نفذ المخزون"
        )}
      </button>
      {isFast && isInStock ? (
        <div
          className="w-full col-span-full max-sm:hidden lg:col-span-4 sm:w-1/2"
          onClick={() => handleAddToCart("fast")}
        >
          <button
            disabled={loadingFast}
            className="w-full h-10 p-2 text-sm font-bold text-white bg-black rounded-md max-sm:text-xs max-sm:h-8 max-sm:p-1"
          >
            {loadingFast ? (
              <span className="size-4">
                {locale === "en" ? "Buying..." : "جاري الشراء..."}.
              </span>
            ) : isInStock ? (
              locale === "en" ? (
                "Buy Fast"
              ) : (
                "شراء سريع"
              )
            ) : locale === "en" ? (
              "Out Of Stock"
            ) : (
              "نفذ المخزون"
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default AddToCart;
