const UserDashboardIcon = (props) => (
  <svg
    width={23}
    height={21}
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.0677 19.5404C20.4443 16.8041 17.9424 14.842 15.0227 13.9119C16.4669 13.0737 17.589 11.7965 18.2166 10.2765C18.8442 8.75641 18.9426 7.07751 18.4968 5.49762C18.0509 3.91774 17.0855 2.52421 15.7486 1.53105C14.4118 0.537883 12.7775 0 11.0968 0C9.41603 0 7.78175 0.537883 6.44491 1.53105C5.10808 2.52421 4.1426 3.91774 3.69675 5.49762C3.25091 7.07751 3.34934 8.75641 3.97694 10.2765C4.60454 11.7965 5.7266 13.0737 7.17081 13.9119C4.25112 14.841 1.74929 16.8031 0.125823 19.5404C0.0662875 19.6351 0.026798 19.7404 0.00968452 19.8501C-0.007429 19.9599 -0.0018199 20.0719 0.0261809 20.1795C0.0541817 20.2871 0.104007 20.3882 0.172717 20.4767C0.241427 20.5652 0.32763 20.6395 0.42624 20.695C0.524849 20.7505 0.633869 20.7862 0.746865 20.8C0.859861 20.8138 0.974546 20.8054 1.08415 20.7753C1.19376 20.7451 1.29607 20.6939 1.38504 20.6246C1.47401 20.5553 1.54785 20.4694 1.60219 20.3718C3.61048 16.9881 7.16015 14.9678 11.0968 14.9678C15.0334 14.9678 18.5831 16.9881 20.5914 20.3718C20.6457 20.4694 20.7195 20.5553 20.8085 20.6246C20.8975 20.6939 20.9998 20.7451 21.1094 20.7753C21.219 20.8054 21.3337 20.8138 21.4467 20.8C21.5597 20.7862 21.6687 20.7505 21.7673 20.695C21.8659 20.6395 21.9521 20.5652 22.0208 20.4767C22.0895 20.3882 22.1394 20.2871 22.1674 20.1795C22.1954 20.0719 22.201 19.9599 22.1839 19.8501C22.1667 19.7404 22.1273 19.6351 22.0677 19.5404ZM5.12735 7.48531C5.12735 6.33428 5.47745 5.2091 6.13338 4.25205C6.78931 3.29501 7.7216 2.54908 8.81237 2.1086C9.90314 1.66812 11.1034 1.55287 12.2614 1.77742C13.4193 2.00198 14.483 2.55625 15.3178 3.37015C16.1526 4.18405 16.7212 5.22103 16.9515 6.34994C17.1818 7.47885 17.0636 8.649 16.6118 9.71241C16.16 10.7758 15.3949 11.6847 14.4132 12.3242C13.4315 12.9637 12.2774 13.305 11.0968 13.305C9.5141 13.3034 7.99674 12.6897 6.87762 11.5986C5.75851 10.5076 5.12904 9.02828 5.12735 7.48531Z"
      fill="currentColor"
    />
  </svg>
);
export default UserDashboardIcon;
