const BurgerMenu = (props) => (
  <svg
    width={24}
    height={17}
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.913462 2.5H22.8365C23.3359 2.5 23.75 1.93333 23.75 1.25C23.75 0.566667 23.3359 0 22.8365 0H0.913462C0.414103 0 0 0.566667 0 1.25C0 1.93333 0.414103 2.5 0.913462 2.5Z"
      fill="currentColor"
    />
    <path
      d="M1.62375 9.5H22.1263C23.0139 9.5 23.75 8.93333 23.75 8.25C23.75 7.56667 23.0139 7 22.1263 7H1.62375C0.736097 7 0 7.56667 0 8.25C0 8.93333 0.714449 9.5 1.62375 9.5Z"
      fill="currentColor"
    />
    <path
      d="M0.913462 16.5H22.8365C23.3359 16.5 23.75 15.9333 23.75 15.25C23.75 14.5667 23.3359 14 22.8365 14H0.913462C0.414103 14 0 14.5667 0 15.25C0 15.9333 0.414103 16.5 0.913462 16.5Z"
      fill="currentColor"
    />
  </svg>
);
export default BurgerMenu;
