import Layout from "@/layout";
import dynamic from "next/dynamic";
import NextNProgress from "nextjs-progressbar";
import ErrorBoundary from "@/components/ErrorBoundary";
import SuspenseFallback from "@/components/SuspenseFallback";

import { useRouter } from "next/router";
import { Cairo } from "next/font/google";
import { Suspense, createContext, useEffect, useReducer } from "react";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { CartProvider } from "@/context/cart/provider";
import { AppProvider } from "@/context/global/provider";
import { initialState, modalReducer } from "@/context/Modal.reducer";

import "swiper/css";
import "swiper/css/pagination";
import "@/styles/globals.css";

import { WishlistProvider } from "@/context/wishlist/Provider";
import { CheckoutProvider } from "@/context/checkout/provider";
import { CompareProvider } from "@/context/compare/compare-context";

const cairoFont = Cairo({
  weight: ["200", "300", "400", "500", "600", "700", "800", "900"],
  style: ["normal"],
  display: "swap",
  subsets: ["arabic"],
  variable: "--font-cairo",
});

const Toaster = dynamic(
  () => import("react-hot-toast").then((c) => c.Toaster),
  { ssr: false }
);

export const ModalContext = createContext(null);

const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const [state, dispatch] = useReducer(modalReducer, initialState);

  useEffect(() => {
    window.onresize = () => {
      const windowSize = window.devicePixelRatio;
      if (windowSize < 1) {
        document.body.style.width = windowSize * 100 + "%";
        document.body.style.margin = "auto";
      } else {
        document.body.style.width = "100%";
        document.body.style.margin = "auto";
      }
    };
  });

  // Direction
  useEffect(() => {
    document.body.style.direction = locale === "en" ? "ltr" : "rtl";
  }, [locale]);

  return (
    <SessionProvider session={pageProps?.session}>
      <main className={`${cairoFont.variable} font-sans`}>
        <NextNProgress
          color="#ffffff"
          startPosition={0.3}
          stopDelayMs={200}
          height={4}
          showOnShallow={false}
        />
        <ModalContext.Provider value={{ state, dispatch }}>
          <AppProvider>
            <CompareProvider>
              <CartProvider>
                <WishlistProvider>
                  <CheckoutProvider>
                    <Layout>
                      <Suspense fallback={<SuspenseFallback />}>
                        <ErrorBoundary>
                          <Component {...pageProps} />
                        </ErrorBoundary>
                      </Suspense>
                    </Layout>
                  </CheckoutProvider>
                </WishlistProvider>
              </CartProvider>
            </CompareProvider>
          </AppProvider>
        </ModalContext.Provider>
        <Toaster position="bottom-center" reverseOrder={false} />
      </main>
    </SessionProvider>
  );
};

export default appWithTranslation(App);
