import React, { useRef } from "react";
import CloseIcon from "../icons/CloseIcon";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import CopyIcon from "../icons/CopyIcon";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  ViberShareButton,
  ViberIcon,
  LineShareButton,
  LineIcon,
} from "react-share";
import toast from "react-hot-toast";
const ShareModal = ({ open, setOpen, url, title, t }) => {
  const iconSize = 36;
  const iconBorderRadius = 6;
  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setOpen(false));

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`
        ${title}
        ــــــــــــــــــ
        ${url}
      `);
      toast.success(t("copied"));
      setOpen(false);
    }
  };
  return (
    open && (
      <div
        ref={modalRef}
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full md:max-w-[500px] md:min-w-80 p-4 bg-slate-200 z-[1000] flex flex-col  rounded-md shadow-md ring-1 ring-slate-300/70"
      >
        <div className="flex justify-between text-slate-600">
          <h2 className="font-bold text-lg">{t("share")}</h2>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="flex-grow">
          <h1 className="text-slate-600 my-3 divide-x-2">{title || ""}</h1>
          <div className="flex flex-wrap gap-2 my-3">
            <FacebookShareButton url={url}>
              <FacebookIcon size={iconSize} borderRadius={iconBorderRadius} />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title || ""}>
              <XIcon size={iconSize} borderRadius={iconBorderRadius} />
            </TwitterShareButton>
            <WhatsappShareButton url={url} title={title || ""}>
              <WhatsappIcon size={iconSize} borderRadius={iconBorderRadius} />
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={title || ""}>
              <TelegramIcon size={iconSize} borderRadius={iconBorderRadius} />
            </TelegramShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={iconSize} borderRadius={iconBorderRadius} />
            </LinkedinShareButton>
            <PinterestShareButton url={url}>
              <PinterestIcon size={iconSize} borderRadius={iconBorderRadius} />
            </PinterestShareButton>
            <ViberShareButton url={url} title={title || ""}>
              <ViberIcon size={iconSize} borderRadius={iconBorderRadius} />
            </ViberShareButton>
            <LineShareButton url={url} title={title || ""}>
              <LineIcon size={iconSize} borderRadius={iconBorderRadius} />
            </LineShareButton>

            <EmailShareButton url={url}>
              <EmailIcon size={iconSize} borderRadius={iconBorderRadius} />
            </EmailShareButton>
          </div>
        </div>
        <div className="flex">
          <input
            dir="ltr"
            ref={modalRef}
            type="text"
            value={url}
            readOnly
            className="flex-grow text-slate-500 font-bold px-4 focus:outline-0 text-start text-ellipsis"
          />
          <button
            onClick={handleCopy}
            className="flex gap-2 items-center  justify-center bg-primary text-white py-2 px-4 hover:bg-primary-to-r"
          >
            <span>{t("copyLink")}</span>
            <CopyIcon />
          </button>
        </div>
      </div>
    )
  );
};

export default ShareModal;
