import React from "react";
import Image from "@/components/global/Image";
import { useTranslation } from "next-i18next";
import { priceFormatedObject, validatePrice } from "@/helpers/product";
import { handlePriceByComma } from "@/helpers/common/handlePrice";

import ItemCartCounter from "@/components/cart/ItemCartCounter";
import Link from "next/link";

const CartDropDownItem = ({ product }) => {
  console.log("product", product);
  const { t } = useTranslation("common");
  const price = validatePrice(priceFormatedObject(product));
  return (
    <li className="flex gap-2 border-b py-3.5 first:pt-0 last:pb-1 last:border-b-0">
      <Image
        src={
          product?._media_?.image?.[0]?.image ||
          `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product${
            product?.image
              ? product?.image?.[0]
              : product?.extension_attributes?.product_image
          }`
        }
        className="object-contain w-24 h-full border rounded-md"
        alt={product?.name}
        loading="lazy"
      />

      <div className="flex flex-col text-[15px] flex-1 justify-between ">
        <Link
          href={`
            /product/${
              product?.extension_attributes?.parent_rewrite_url ||
              product?.extension_attributes?.rewrite_url ||
              product?.rewrite_url ||
              ""
            }`}
          className="font-semibold"
        >
          {product?.name}
        </Link>

        <p className="font-bold">
          {handlePriceByComma(
            price?.specialPrice || price?.originalPrice || product?.price
          )}{" "}
          {t("SAR")}
        </p>
        <ItemCartCounter product={product} />
      </div>
    </li>
  );
};

export default CartDropDownItem;
