const Remove = (props) => (
  <svg
    width={18}
    height={17}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5887 4.98507C15.5746 4.98507 15.5534 4.98507 15.5323 4.98507C11.8005 4.64594 8.07587 4.51796 4.38648 4.8571L2.9474 4.98507C2.65112 5.01067 2.39012 4.8187 2.3619 4.54995C2.33368 4.2812 2.54531 4.05084 2.83454 4.02525L4.27361 3.89727C8.02649 3.55174 11.8288 3.68611 15.6381 4.02525C15.9273 4.05084 16.1389 4.2876 16.1107 4.54995C16.0895 4.79951 15.8568 4.98507 15.5887 4.98507Z"
      fill="currentColor"
    />
    <path
      d="M6.76788 4.33879C6.73966 4.33879 6.71144 4.33879 6.67617 4.33239C6.394 4.2876 6.19648 4.03805 6.24586 3.7821L6.40105 2.94385C6.51392 2.32956 6.66912 1.47852 8.31277 1.47852H10.161C11.8117 1.47852 11.9669 2.36155 12.0727 2.95025L12.2279 3.7821C12.2773 4.04445 12.0798 4.294 11.7976 4.33239C11.5084 4.37719 11.2332 4.19802 11.1909 3.94207L11.0357 3.11022C10.937 2.55352 10.9158 2.44474 10.168 2.44474H8.31982C7.57207 2.44474 7.55796 2.53432 7.45214 3.10382L7.28989 3.93567C7.24757 4.17242 7.02183 4.33879 6.76788 4.33879Z"
      fill="currentColor"
    />
    <path
      d="M11.5021 15.2361H6.97321C4.51126 15.2361 4.4125 14.0011 4.3349 13.0029L3.87638 6.55924C3.85521 6.29689 4.08095 6.06654 4.37018 6.04734C4.66646 6.03454 4.91336 6.2329 4.93452 6.49526L5.39305 12.9389C5.47065 13.9115 5.49886 14.2762 6.97321 14.2762H11.5021C12.9835 14.2762 13.0117 13.9115 13.0822 12.9389L13.5408 6.49526C13.5619 6.2329 13.8159 6.03454 14.1051 6.04734C14.3943 6.06654 14.6201 6.29049 14.5989 6.55924L14.1404 13.0029C14.0628 14.0011 13.964 15.2361 11.5021 15.2361Z"
      fill="currentColor"
    />
    <path
      d="M10.4094 11.7167H8.06032C7.7711 11.7167 7.53125 11.4991 7.53125 11.2367C7.53125 10.9744 7.7711 10.7568 8.06032 10.7568H10.4094C10.6986 10.7568 10.9385 10.9744 10.9385 11.2367C10.9385 11.4991 10.6986 11.7167 10.4094 11.7167Z"
      fill="currentColor"
    />
    <path
      d="M11.0015 9.15721H7.47438C7.18516 9.15721 6.94531 8.93965 6.94531 8.6773C6.94531 8.41495 7.18516 8.19739 7.47438 8.19739H11.0015C11.2908 8.19739 11.5306 8.41495 11.5306 8.6773C11.5306 8.93965 11.2908 9.15721 11.0015 9.15721Z"
      fill="currentColor"
    />
  </svg>
);
export default Remove;
