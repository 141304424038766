const Heart = (props) => (
  <svg
    width={32}
    height={29}
    viewBox="0 0 32 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9987 28.5731C15.5595 28.5731 15.1345 28.5147 14.7804 28.3835C9.3687 26.4731 0.769531 19.6918 0.769531 9.67308C0.769531 4.56891 4.7787 0.427246 9.7087 0.427246C12.1029 0.427246 14.3412 1.38975 15.9987 3.11058C17.6562 1.38975 19.8945 0.427246 22.2887 0.427246C27.2187 0.427246 31.2279 4.5835 31.2279 9.67308C31.2279 19.7064 22.6287 26.4731 17.217 28.3835C16.8629 28.5147 16.4379 28.5731 15.9987 28.5731ZM9.7087 2.61475C5.95453 2.61475 2.89453 5.77933 2.89453 9.67308C2.89453 19.6335 12.202 25.1752 15.4745 26.3272C15.7295 26.4147 16.282 26.4147 16.537 26.3272C19.7954 25.1752 29.117 19.6481 29.117 9.67308C29.117 5.77933 26.057 2.61475 22.3029 2.61475C20.1495 2.61475 18.152 3.65016 16.8629 5.44391C16.4662 5.99808 15.5595 5.99808 15.1629 5.44391C13.8454 3.63558 11.862 2.61475 9.7087 2.61475Z"
      fill="currentcolor"
    />
  </svg>
);
export default Heart;
