const ArrowIcon = (props) => (
  <svg
    width={11}
    height={7}
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.235568 0.244272C0.541149 -0.090672 1.02707 -0.0798111 1.3209 0.268531L5.11727 4.86257L8.91365 0.268531C9.20747 -0.079811 9.69339 -0.090672 9.99897 0.244272C10.3046 0.579216 10.3141 1.13313 10.0203 1.48147L5.67058 6.73147C5.52586 6.90304 5.32607 7 5.11727 7C4.90847 7 4.70869 6.90304 4.56397 6.73147L0.214287 1.48147C-0.0795417 1.13313 -0.0700139 0.579216 0.235568 0.244272Z"
      fill="currentColor"
    />
  </svg>
);
export default ArrowIcon;
