import { useRouter } from "next/router";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import CategoryCard from "@/components/cards/CategoryCard";
import { useGlobalData } from "@/context/global";
import { findObjectByIdV2 } from "@/utils/global";

const CategoriesSwiper = ({ categories, set_swiper }) => {
  const { locale } = useRouter();
  const {
    appData: { categoryData },
  } = useGlobalData();
  return (
    <Swiper
      modules={[Autoplay]}
      onInit={(e) => set_swiper(e)}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      breakpoints={{
        320: {
          slidesPerView: 2.5,
          spaceBetween: 5,
        },
        769: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1023: {
          slidesPerView: 4.5,
          spaceBetween: 20,
        },
        1581: {
          slidesPerView: 6,
          spaceBetween: 20,
        },
      }}
      // loop
      key={locale}
      dir={locale === "en" ? "ltr" : "rtl"}
    >
      {categories.map((cat, i) => {
        const category = findObjectByIdV2(
          categoryData?.children_data,
          "id",
          cat?.id
        );
        return (
          <SwiperSlide key={i}>
            <CategoryCard category={cat} data={category} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CategoriesSwiper;
