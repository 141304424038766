import React, { forwardRef } from "react";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import CartIcon from "@/components/icons/CartIcon";
import Link from "next/link";
import CartDropDownItem from "./CartDropDownItem";

const CartDropDown = ({ items, cartTotal, onHide }, ref) => {
  const { t } = useTranslation("common");
  const { pathname, push } = useRouter();

  return (
    <div
      ref={ref}
      className="absolute max-h-[450px] overflow-y-auto red-scroll top-full -end-4 bg-white shadow p-4 pb-0 rounded-md z-[200] w-[350px]"
    >
      {(items || [])?.length ? (
        <>
          <ul className="max-h-[280px] overflow-y-auto red-scroll">
            {(items || []).map((product, i) => (
              <CartDropDownItem key={i} product={product} />
            ))}
          </ul>
          <div className="flex items-center justify-center gap-5 my-2">
            <span className="font-semibold">{t("Sub Total")} </span>
            <span className="text-[#305446] font-bold">
              {cartTotal} {t("SAR")}
            </span>
          </div>
          <button
            onClick={() => {
              push("/cart");
              onHide();
            }}
            className="p-1.5 border border-[#305446] text-[#305446] font-semibold rounded-md w-full mb-3"
          >
            {t("View and Edit My Cart")}
          </button>
          <button
            onClick={() => {
              push("/checkout");
              onHide();
            }}
            className="p-1.5 border border-red-600 text-white bg-red-600 font-semibold rounded-md w-full mb-3"
          >
            {t("Checkout")}
          </button>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center gap-4 p-4">
          <CartIcon size={48} className="text-slate-300" />
          <span className="text-red-600">{t("No Items in Cart")}</span>
          {pathname !== "/" ? (
            <Link
              href="/"
              className="mb-2 block w-full text-center bg-red-600 text-white p-2 rounded-md hover:bg-red-400 duration-300 transition-colors"
            >
              {t("Continue shopping")}
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default forwardRef(CartDropDown);
