import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper from "swiper";
import ProductCard from "../cards/ProductCard";
import ArrowIcon from "../icons/ArrowIcon";
import { useState } from "react";

const CartModalSwiper = ({ products }) => {
  const [swiper, set_swiper] = useState({});

  return (
    <div className="relative">
      {products?.length ? (
        <Swiper
          onInit={(e) => set_swiper(e)}
          spaceBetween={5}
          loop
          breakpoints={{
            320: { slidesPerView: 2 },
            421: { slidesPerView: 2.5 },
            641: { slidesPerView: 2 },
          }}
        >
          {products?.map((product, key) => (
            <SwiperSlide key={key}>
              <ProductCard product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
      <button
        onClick={() => swiper?.slidePrev()}
        className="absolute z-50 flex items-center justify-center bg-white border rounded-full -left-3 top-1/2 size-8"
      >
        <ArrowIcon className="rotate-90" />
      </button>
      <button
        onClick={() => swiper?.slideNext()}
        className="absolute z-50 flex items-center justify-center bg-white border rounded-full -right-3 top-1/2 size-8"
      >
        <ArrowIcon className="-rotate-90" />
      </button>
    </div>
  );
};

export default CartModalSwiper;
