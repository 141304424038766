import { addItemToCartV2, createCart } from "@/services/cart";
import { getCookies, setCookie } from "cookies-next";
import toast from "react-hot-toast";

const addICartItem = async (
  t,
  handshake,
  mage,
  quote,
  product,
  qty,
  dispatch,
  configurable_item_options = null,
  context,
  relatedProducts,
  type,
  push,
  region_id 
) => {
  const sku = Array.isArray(product?.sku) ? product?.sku?.at(0) : product?.sku;

  const { data, error } = await addItemToCartV2(
    handshake,
    mage,
    quote,
    sku,
    qty,
    region_id,
    Object.values(configurable_item_options || {})
  );
  if (error)
    return toast.error(
      t(error?.error?.message) ||
        "The product that was requested doesn't exist. Verify the product and try again."
    );

  if (data?.quoteId && quote != data?.quoteId) {
    dispatch({ type: "clear" });
    setCookie("quote", data?.quoteId);
  }

  setTimeout(() => {
    if (type !== "fast") {
      context?.dispatch({
        type: "cartInfo",
        productInfo: product,
        payloadRelatedProducts: relatedProducts,
      });
    }
  }, 0);

  dispatch({ type: "add_item", payload: { ...product, ...data?.data } });
  if (data?.message) {
    toast.success(t(data?.message));
  }

  push && type == "fast" && push("/checkout");
};

export const handleAddItemToCart = async (
  t,
  product,
  qty,
  setLoading,
  dispatchCart,
  configurations,
  context,
  relatedProducts,
  type,
  push,
  region_id
) => {
  setLoading(true);
  const { handshake, quote, mage } = getCookies();

  // 1 check if user has a cart. If not generate one and save it to cookies
  if (!quote) {
    const { data: newCart } = await createCart(handshake, mage);
    // 2 .5 Set the cookie with the id of the new cart in users browser and add item to cart
    if (newCart?.cart_id) {
      setCookie("quote", newCart?.cart_id);
      await addICartItem(
        t,
        handshake,
        mage,
        newCart?.cart_id,
        product,
        qty,
        dispatchCart,
        configurations,
        context,
        relatedProducts,
        type,
        push,
        region_id
      );
      return setLoading(false);
    }
    return setLoading(false);
  }

  //If user already has a cart just add the item to their existing cart
  await addICartItem(
    t,
    handshake,
    mage,
    quote,
    product,
    qty,
    dispatchCart,
    configurations,
    context,
    relatedProducts,
    type,
    push,
    region_id
  );

  setLoading(false);
  return null;
};

export const handleConfigurablesGrouping = (configurables) => {
  const groupBy = configurables?.reduce((acc, item) => {
    const optionDetails = Object.keys(item.option_details || {});

    optionDetails.forEach((key) => {
      // Initialize the group for the key if not present
      if (!acc[key]) acc[key] = {};

      const optionValue = item?.option_details[key]?.option_value;

      // Initialize the array for the specific option value if not present
      if (!acc[key][optionValue]) acc[key][optionValue] = [];

      acc[key][optionValue].push({
        ...item?.option_details[key],
        _media_: item?._media_,
        prices_with_tax: item?.prices_with_tax,
        sku: item?.sku,
        quantity: item?.quantity,
      });
    });
    return acc;
  }, {});

  return groupBy;
};
