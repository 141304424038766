import { useRef, useState } from "react";

import CategoryListItems from "./CategoryListItems";
import ArrowIcon from "@/components/icons/ArrowIcon";
import BurgerMenu from "@/components/icons/BurgerMenu";
import useOnClickOutsideV2 from "@/hooks/global/useOnClickOutsideV2";
import { useGlobalData } from "@/context/global";
import { modifyMenuTreeData } from "@/helpers/category";

const CategoryLists = ({ t, locale, categoryTreeData }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const categoryNameRef = useRef();
  useOnClickOutsideV2([ref, categoryNameRef], () => setOpen(false));

  const {
    appData: { menuTreeData },
  } = useGlobalData();

  return (
    <div className="group relative w-1/5 bg-[#F2EFEF]">
      <div
        ref={categoryNameRef}
        className={`${
          locale === "en" ? "ml-[12%]" : "mr-[12%]"
        } py-4 px-6 flex items-center justify-between text-zinc-600 cursor-pointer`}
        onClick={() => setOpen(!open)}
      >
        <div className="flex items-center gap-5 cursor-pointer">
          <BurgerMenu className="size-5" />
          <span className="font-bold">{t("Categories")}</span>
        </div>
        <ArrowIcon />
      </div>
      {open && (
        <div
          ref={ref}
          className="py-3 text-zinc-600 cursor-pointer absolute top-full w-full max-h-96 overflow-y-auto red-scroll z-50 bg-[#F2EFEF] border-t-4 border-t-white"
        >
          {modifyMenuTreeData(menuTreeData)?.length > 0? (
            <CategoryListItems
              categoryTreeData={categoryTreeData}
              setOpen={setOpen}
            />
          ) : (
            <p className="text-center font-bold">There is no menu</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryLists;
