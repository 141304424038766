import ProductsList from "@/components/category/ProductsList";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import Side from "@/components/category/Side";
import Image from "@/components/global/Image";
import FilterIcon from "../icons/FilterIcon";
import Modal from "@/components/modals";
import Sort from "./Sort";

import { useRef, useState } from "react";
import { sortKeys } from "@/constants/Category";
import BreadCrumb from "../global/BreadCrumb";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import { getSubCategoriesData } from "@/helpers/category";
import Categories from "../home/Categories";
import useScreenSize from "@/hooks/display/useDisplay";

export default function Body({
  t,
  allProducts,
  selectedCategory,
  handleSorting,
  totalProducts,
  selectedFilterKeys,
  handleFilter,
  allFilters,
  fromCategory = true,
  imageBanner = null,
  productQuickViewRef = null,
  language,
}) {
  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 576;
  const [filterModal, setFilterModal] = useState(false);
  const [sortName, setSortName] = useState("Most Views");
  return (
    <>
      <BreadCrumb
        current={
          !fromCategory
            ? language === "en"
              ? "Search results"
              : "نتائج البحث"
            : selectedCategory?.name
        }
      />
      <section className="my-4 sm:my-8">
        <section className="mx-auto sm:subMainSize">
          {imageBanner ? (
            <Image
              src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/${imageBanner}`}
              className="object-cover w-full h-auto"
              loading="lazy"
              alt="Category Banner Img!"
            />
          ) : null}
          <div className="my-4">
            {selectedCategory?.children_data?.length > 0 && (
              <Categories
                key={
                  selectedCategory?.id +
                  selectedCategory?.name +
                  Math.random().toString(16).slice(2)
                }
                title={t("Shop by categories")}
                data={getSubCategoriesData(selectedCategory, isMobile)}
                showSwiperButtons={false}
              />
            )}
          </div>
          <div className="sm:hidden bg-[#EDEDED]">
            <div className="flex items-center justify-between py-2 mx-auto subMainSize">
              <div
                onClick={() => setFilterModal(true)}
                className="flex items-center gap-2"
              >
                <FilterIcon />
                <span className="text-sm">
                  {language === "en" ? "Filter" : "تصفية"}
                </span>
              </div>
              <div className="relative flex items-center justify-between w-48 gap-1 px-2 py-1 bg-white border rounded-md cursor-pointer group">
                <Sort
                  {...{
                    handleSorting,
                    sortKeys,
                    sortName,
                    setSortName,
                    t,
                    isResponsive: true,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="grid grid-cols-12 gap-4 mx-auto my-2 subMainSize sm:my-8">
          <div className="hidden lg:block col-span-3 *:mb-4">
            <Side
              {...{
                filterList: allFilters,
                t,
                selectedFilterKeys,
                handleFilter,
              }}
            />
          </div>
          <div className="col-span-full lg:col-span-9">
            <ProductsList
              {...{
                allProducts,
                fromCategory,
                selectedCategory,
                totalProducts,
                sortKeys,
                handleSorting,
                t,
                language,
                sortName,
                setSortName,
                productQuickViewRef,
              }}
            />
          </div>
        </div>
      </section>
      {filterModal && (
        <SideModal
          {...{
            filterModal,
            t,
            allFilters,
            selectedFilterKeys,
            setFilterModal,
            handleFilter,
          }}
        />
      )}
    </>
  );
}

const SideModal = ({
  filterModal,
  t,
  allFilters,
  selectedFilterKeys,
  setFilterModal,
  handleFilter,
}) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setFilterModal(false));
  useBodyScrollLock();
  return (
    <Modal show={filterModal}>
      <div
        ref={ref}
        className="w-3/4 max-h-screen min-h-screen p-3 overflow-y-auto bg-white sm:w-80 red-scroll"
      >
        <Side
          t={t}
          filterList={allFilters}
          handleFilter={handleFilter}
          selectedFilterKeys={selectedFilterKeys}
          isModal
          setFilterModal={setFilterModal}
        />
      </div>
    </Modal>
  );
};
