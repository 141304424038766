const Eye = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99896 13.6083C8.00729 13.6083 6.39062 11.9916 6.39062 9.99993C6.39062 8.00827 8.00729 6.3916 9.99896 6.3916C11.9906 6.3916 13.6073 8.00827 13.6073 9.99993C13.6073 11.9916 11.9906 13.6083 9.99896 13.6083ZM9.99896 7.6416C8.69896 7.6416 7.64062 8.69993 7.64062 9.99993C7.64062 11.2999 8.69896 12.3583 9.99896 12.3583C11.299 12.3583 12.3573 11.2999 12.3573 9.99993C12.3573 8.69993 11.299 7.6416 9.99896 7.6416Z"
      fill="currentColor"
    />
    <path
      d="M9.99844 17.5167C6.8651 17.5167 3.90677 15.6834 1.87344 12.5001C0.990104 11.1251 0.990104 8.8834 1.87344 7.50006C3.9151 4.31673 6.87344 2.4834 9.99844 2.4834C13.1234 2.4834 16.0818 4.31673 18.1151 7.50006C18.9984 8.87506 18.9984 11.1167 18.1151 12.5001C16.0818 15.6834 13.1234 17.5167 9.99844 17.5167ZM9.99844 3.7334C7.30677 3.7334 4.73177 5.35007 2.93177 8.17507C2.30677 9.15007 2.30677 10.8501 2.93177 11.8251C4.73177 14.6501 7.30677 16.2667 9.99844 16.2667C12.6901 16.2667 15.2651 14.6501 17.0651 11.8251C17.6901 10.8501 17.6901 9.15007 17.0651 8.17507C15.2651 5.35007 12.6901 3.7334 9.99844 3.7334Z"
      fill="currentColor"
    />
  </svg>
);
export default Eye;
