const FaceBook = (props) => (
  <svg
    width={8}
    height={14}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.44186 7.73913L7.86047 5.30435H5.39535V3.6087C5.39535 2.91304 5.67442 2.3913 6.7907 2.3913H8V0.173913C7.34884 0.0869564 6.60465 0 5.95349 0C3.81395 0 2.32558 1.21739 2.32558 3.3913V5.30435H0V7.73913H2.32558V13.8696C2.83721 13.9565 3.34884 14 3.86047 14C4.37209 14 4.88372 13.9565 5.39535 13.8696V7.73913H7.44186Z"
      fill="currentColor"
    />
  </svg>
);
export default FaceBook;
