import { event_view_item } from "@/utils/gtm";
import { handleConfigurablesGrouping } from "@/utils/product";
import { useEffect, useState } from "react";

const useHandleAlbumDetails = (product) => {
  const [configIndex, setConfigIndex] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const [configurables, setConfigurables] = useState({});
  const [selectedIdx, setSelectedIdx] = useState({});

  const [chosenConfigurableProduct, setChosenConfigurableProduct] =
    useState(null);

  const handleOnClickConfigurable = (info, index, type) => {
    setSelectedIdx((prev) => ({ ...prev, [type]: index }));
    setConfiguration((prev) => ({
      ...prev,
      [type]: {
        option_id: info?.option_id,
        option_value: info?.option_value,
      },
    }));
  };

  // initial load
  useEffect(() => {
    if (product?._configurables?.length > 0) {
      setConfiguration((prev) => ({
        ...prev,
        ...(product?._configurables?.at(0)?.option_details?.size?.option_id &&
        product?._configurables?.at(0)?.option_details?.size?.option_value
          ? {
              size: {
                option_id:
                  product?._configurables?.at(0)?.option_details?.size
                    ?.option_id,
                option_value:
                  product?._configurables?.at(0)?.option_details?.size
                    ?.option_value,
              },
            }
          : {}),
        ...(product?._configurables?.at(0)?.option_details?.color?.option_id &&
        product?._configurables?.at(0)?.option_details?.color?.option_value
          ? {
              color: {
                option_id:
                  product?._configurables?.at(0)?.option_details?.color
                    ?.option_id,
                option_value:
                  product?._configurables?.at(0)?.option_details?.color
                    ?.option_value,
              },
            }
          : {}),
      }));

      const configurablesGrouping = handleConfigurablesGrouping(
        product?._configurables
      );
      setConfigurables(configurablesGrouping);

      let defaultItem;
      let defaultItemIndex;
      if (Object.keys(selectedIdx || {})?.length == 0) {
        Object.keys(configurablesGrouping || {})?.forEach((item) => {
          defaultItem =
            product?._configurables?.at(0)?.option_details?.[item]
              ?.option_value;

          defaultItemIndex = Object.values(configurablesGrouping[item])
            ?.flat()
            ?.findIndex((item) => item?.option_value === defaultItem);

          setSelectedIdx((prev) => ({ ...prev, [item]: defaultItemIndex }));
        });
      }
    }
  }, []);

  useEffect(() => {
    if (product?._configurables?.length > 0) {
      const modifiedChosenConfig = Object.entries(configuration).reduce(
        (acc, [key, value]) => {
          acc[key] = value?.option_value;
          return acc;
        },
        {}
      );

      const findMatchingConfigurable = product?._configurables?.find(
        (config) => {
          return Object.entries(modifiedChosenConfig).every(
            ([key, value]) => config?.options[key] === value
          );
        }
      );

      setChosenConfigurableProduct(findMatchingConfigurable);

      const configIndex = product?._configurables?.findIndex(
        (content) => content?.sku === findMatchingConfigurable?.sku
      );
      setConfigIndex(configIndex);
      event_view_item(product, configIndex);
    }
  }, [product, configuration]);

  return {
    chosenConfigurableProduct,
    handleOnClickConfigurable,
    selectedIdx,
    setSelectedIdx,
    configuration,
    configurables,
    configIndex,
  };
};

export default useHandleAlbumDetails;
