import StarsRateIcon from "../icons/StarsRateIcon";
// import ProductCardRate from "../icons/ProductCardRate";

const ProductRates = ({ reviews_count = null, fromModal }) => {
  return (
    <div className="flex items-center gap-4">
      {/* <StarsRateIcon className="w-auto h-4 text-[#F1C40F]" count={reviews_count || 0} /> */}
      <StarsRateIcon rate={reviews_count || 4.7} />
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <span
              className={`text-[#B4AFAF] ${
                fromModal ? "max-sm:text-[13px]" : ""
              }`}
            >
              ({reviews_count || 4.7})
            </span>
          </div>
        </div>
        <span
          className={`font-extrabold ${fromModal ? "max-sm:text-[11px]" : ""}`}
        >
          {reviews_count || ""}
        </span>
      </div>
    </div>
  );
};

export default ProductRates;
