import { useRouter } from "next/router";
import AddtoWishlist from "../global/buttons/AddtoWishlist";
import ShareIcon from "../icons/ShareIcon";
// import AddToCompare from "./AddToCompare";
import Share from "../share";

const UserActions = ({ product, fromPDP = false }) => {
  const { locale } = useRouter();
  return (
    <>
      <div className="flex items-end gap-3">
        <div className="flex items-center gap-1.5">
          <ShareIcon />
          <Share
            url={
              !fromPDP
                ? process.env.NEXT_PUBLIC_WEBSITE_URL +
                  "/product/" +
                  product?.rewrite_url
                : null
            }
            title={product?.name || ""}
            label={locale === "en" ? "Share" : "مشاركة"}
          />
        </div>
        {/* <AddToCompare product={product}/> */}
        <AddtoWishlist fromPDP product={product} />
      </div>
    </>
  );
};

export default UserActions;
