const Instagram = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80003 1.4H4.20001C2.65361 1.4 1.4 2.65361 1.4 4.20001V9.80003C1.4 11.3464 2.65361 12.6 4.20001 12.6H9.80003C11.3464 12.6 12.6 11.3464 12.6 9.80003V4.20001C12.6 2.65361 11.3464 1.4 9.80003 1.4ZM4.20001 0C1.88041 0 0 1.88041 0 4.20001V9.80003C0 12.1196 1.88041 14 4.20001 14H9.80003C12.1196 14 14 12.1196 14 9.80003V4.20001C14 1.88041 12.1196 0 9.80003 0H4.20001ZM7.00004 9.10004C8.15984 9.10004 9.10004 8.15984 9.10004 7.00004C9.10004 5.84024 8.15984 4.90003 7.00004 4.90003C5.84024 4.90003 4.90003 5.84024 4.90003 7.00004C4.90003 8.15984 5.84024 9.10004 7.00004 9.10004ZM7.00004 10.5C8.93304 10.5 10.5 8.93304 10.5 7.00004C10.5 5.06704 8.93304 3.50003 7.00004 3.50003C5.06704 3.50003 3.50003 5.06704 3.50003 7.00004C3.50003 8.93304 5.06704 10.5 7.00004 10.5ZM10.4998 4.48006C11.041 4.48006 11.4798 4.0413 11.4798 3.50006C11.4798 2.95882 11.041 2.52006 10.4998 2.52006C9.95852 2.52006 9.51975 2.95882 9.51975 3.50006C9.51975 4.0413 9.95852 4.48006 10.4998 4.48006Z"
      fill="currentColor"
    />
  </svg>
);
export default Instagram;
