// import { isShowBannerEachNumOfRows } from "@/helpers/category";
import ProductCard from "../cards/ProductCard";
import BannerBtwProducts from "./BannerBtwProducts";
import Sort from "./Sort";
import useScreenSize from "@/hooks/display/useDisplay";
import React from "react";

const ProductsList = ({
  allProducts,
  selectedCategory,
  totalProducts,
  sortKeys,
  handleSorting,
  fromCategory,
  t,
  language,
  sortName,
  setSortName,
  productQuickViewRef,
}) => {
  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 576;
  // const plp_banner_rate = selectedCategory?.plp_banner_rate;
  return (
    <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4">
      <div className="flex flex-wrap items-center justify-between gap-5 col-span-full">
        <div className="flex flex-col">
          <h2 className="text-lg font-bold uppercase sm:text-xl">
            {!fromCategory
              ? language === "en"
                ? "Search results"
                : "نتائج البحث"
              : selectedCategory?.name}
          </h2>
          <span className="max-sm:text-sm text-[15px]">
            {language === "en" ? "Showing" : "عرض"} {allProducts?.length}{" "}
            {language === "en" ? "of" : "من"} {totalProducts}
          </span>
        </div>
        <div className="relative items-center justify-between hidden w-full gap-1 p-2 border rounded-md cursor-pointer group sm:w-48 text-slate-600 lg:flex">
          <Sort {...{ handleSorting, setSortName, sortKeys, sortName, t }} />
        </div>
      </div>

      {allProducts.map((product, idx) => {
        const numOfProductsOnRow = isMobile ? 2 : 4;
        const rowNum = idx + 1;
        // const numOfRows = plp_banner_rate;
        return (
          <React.Fragment key={product?.sku || idx}>
            <ProductCard
              product={product}
              productQuickViewRef={productQuickViewRef}
            />
            {/* {isShowBannerEachNumOfRows(numOfProductsOnRow, numOfRows, rowNum) &&
              numOfRows > 0 && (
                <BannerBtwProducts
                  banner={
                    selectedCategory?.category_banners?.[
                      rowNum / numOfProductsOnRow - 1
                    ]
                  }
                />
              )} */}
            <BannerBtwProducts
              banner={
                selectedCategory?.category_banners?.[
                  rowNum / numOfProductsOnRow - 1
                ]
              }
            />
          </React.Fragment>
        );
      })}
      <div id="afterProductsSection" className="w-full"></div>
    </div>
  );
};

export default ProductsList;
