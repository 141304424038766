import Link from "next/link";
import ArrowIcon from "../icons/ArrowIcon";

import { useRouter } from "next/router";

const BreadCrumb = ({ current, categoryName = null }) => {
  const { locale } = useRouter();
  return (
    <div className="bg-[#F2EFEF] p-4 text-sm max-sm:text-xs">
      <div className="flex items-center w-full gap-2 mx-auto">
        <Link href="/">{locale === "en" ? "Home" : "الرئيسية"}</Link>
        {categoryName ? (
          <>
            <ArrowIcon
              className={`${
                locale === "en" ? "-rotate-90" : "rotate-90"
              } max-sm:size-2.5`}
            />
            <span className="font-semibold line-clamp-1">{categoryName}</span>
          </>
        ) : null}
        <ArrowIcon
          className={`${
            locale === "en" ? "-rotate-90" : "rotate-90"
          } max-sm:size-2.5`}
        />
        <span className="font-semibold line-clamp-1">{current}</span>
      </div>
    </div>
  );
};

export default BreadCrumb;
