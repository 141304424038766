import ArrowIcon from "@/components/icons/ArrowIcon";
import GridEditIcon from "@/components/icons/GridEditIcon";
import Link from "next/link";

import { useRouter } from "next/router";

const LocaleSwitcher = ({ fromSide, t }) => {
  const { asPath, locale, query, pathname } = useRouter();
  return !fromSide ? (
    <Link
      href={{ pathname, query }}
      as={asPath}
      locale={locale === "ar" ? "en" : "ar"}
    >
      {locale === "en" ? "عربى" : "English"}
    </Link>
  ) : (
    <Link
      href={{ pathname, query }}
      as={asPath}
      locale={locale === "ar" ? "en" : "ar"}
      className="flex items-center justify-between py-3 border-t"
    >
      <span className="flex items-center gap-2">
        <GridEditIcon className="size-4" />
        <span className="flex flex-col">
          <span className="font-semibold">{t("Change Language")}</span>
          <span className="text-xs sm:text-sm text-zinc-500">
            {locale === "en" ? "عربى" : "English"}
          </span>
        </span>
      </span>
      <ArrowIcon className={locale === "en" ? "-rotate-90" : "rotate-90"} />
    </Link>
  );
};

export default LocaleSwitcher;
