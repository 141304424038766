import AddIcon from "../icons/AddIcon";
import MinusIcon from "../icons/MinusIcon";
import AddToCart from "../global/buttons/AddToCart";

const SpecialQtyCart = ({
  product,
  configurations,
  fromModal,
  sku = null,
  onHide,
  qty,
  setQty,
  configurPrice,
  chosenConfigurableProduct,
  configIndex,
}) => {
  return (
    <div className="grid grid-cols-12 gap-4 mt-7">
      <div
        className={`z-[500] col-span-full lg:col-span-12 flex items-center gap-2 sm:gap-4 p-3
      ${
        !fromModal &&
        "sm:flex-nowrap fixed lg:static bottom-[68px] left-1/2 lg:left-0 lg:-translate-x-0 border lg:border-0 lg:rounded-none lg:p-0 -translate-x-1/2 bg-white lg:bg-transparent lg:w-full w-full lg:shadow-none shadow-xl"
      }`}
      >
        <div className="flex items-center w-fit">
          <button
            onClick={() => setQty((qty) => qty + 1)}
            disabled={qty === product?.stock?.qty}
            className="flex items-center justify-center border max-sm:p-2 sm:p-3 bg-zinc-100 border-zinc-100 disabled:cursor-not-allowed"
          >
            <AddIcon className="size-3.5" />
          </button>
          <span className="grid flex-1 w-full max-sm:h-8 sm:h-10 sm:w-20 border-y place-items-center max-sm:w-12">
            {qty}
          </span>
          <button
            onClick={() => setQty((qty) => qty - 1)}
            disabled={qty === 1}
            className="flex items-center justify-center border max-sm:p-2 sm:p-3 bg-zinc-100 border-zinc-100 disabled:cursor-not-allowed"
          >
            <MinusIcon className="size-3.5" />
          </button>
        </div>
        <AddToCart
          fromPDP
          {...{
            product,
            qty,
            configurations,
            sku,
            isFast: true,
            onHide,
            configurPrice,
            chosenConfigurableProduct,
            configIndex,
          }}
        />
      </div>
    </div>
  );
};

export default SpecialQtyCart;
