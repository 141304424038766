import UserDashboardIcon from "@/components/icons/UserDashboardIcon";
import AddressDasboardIcon from "@/components/icons/AddressDashboardIcon";
import OrderIcon from "@/components/icons/OrderIcon";
import Heart from "@/components/icons/Heart";
import LogoutIcon from "@/components/icons/LogoutIcon";
import { signOut } from "next-auth/react";
import { resetSession } from "@/helpers/common/resetSession";

const UserDropDown = ({ push, setUserDropDown, refUser, locale }) => {
  return (
    <div
      ref={refUser}
      className="absolute top-full start-0 bg-white p-2 w-40 rounded-md z-[200] shadow"
    >
      <div
        onClick={() => {
          push("/user");
          setUserDropDown(false);
        }}
        className="flex items-center cursor-pointer gap-2 pb-2.5 border-b"
      >
        <UserDashboardIcon className="size-[18px]" />
        <span className="text-[15px] font-semibold">
          {locale === "en" ? "Dashboard" : "الحساب"}
        </span>
      </div>
      <div
        onClick={() => {
          push("/user/addresses");
          setUserDropDown(false);
        }}
        className="flex items-center cursor-pointer gap-2 py-2.5 border-b"
      >
        <AddressDasboardIcon className="size-[18px]" />
        <span className="text-[15px] font-semibold">
          {locale === "en" ? "Addresses" : "العناوين"}
        </span>
      </div>
      <div
        onClick={() => {
          push("/user/orders");
          setUserDropDown(false);
        }}
        className="flex items-center cursor-pointer gap-2 py-2.5 border-b"
      >
        <OrderIcon className="size-[18px]" />
        <span className="text-[15px] font-semibold">
          {locale === "en" ? "Orders" : "الطلبات"}
        </span>
      </div>
      <div
        onClick={() => {
          push("/user/favourites");
          setUserDropDown(false);
        }}
        className="flex items-center cursor-pointer gap-2 py-2.5 border-b"
      >
        <Heart className="size-[18px]" />
        <span className="text-[15px] font-semibold">
          {locale === "en" ? "Favourites" : "المفضلة"}
        </span>
      </div>
      <button
        className="flex items-center gap-2 pt-2 text-red-600 cursor-pointer"
        onClick={() => {
          setTimeout(() => signOut(), 10)
          resetSession();
          push("/");
        }}
      >
        <LogoutIcon className="size-[18px]" />
        <span className="text-[15px] font-semibold">
          {locale === "en" ? "Logout" : "تسجيل الخروج"}
        </span>
      </button>
    </div>
  );
};

export default UserDropDown;
