import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import Image from "@/components/global/Image";

const BannerBtwProducts = ({ banner }) => {
  const { locale } = useRouter();
  const imgSrc = banner?.["img_" + locale]?.startsWith("/media")
    ? `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${banner?.["banner_image"]?.slice(
        6
      )}`
    : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/${banner?.["banner_image"]}`;
  return (
    <>
      {banner?.banner_image && (
        <div className="col-span-full ">
          <div className="flex items-center justify-center gap-1 sm:gap-4">
            <Link
              href={handleLinkByType(banner?.url, banner?.type)}
              className="relative w-auto h-full overflow-hidden rounded-md"
            >
              <Image
                src={imgSrc}
                loading="lazy"
                className="object-cover w-full h-auto transition-all duration-300 rounded-md cursor-pointer hover:scale-105"
                alt={"cart page"}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerBtwProducts;
