import { useGlobalData } from "@/context/global";
import { useEffect, useState } from "react";
import useSWR from "swr";
import store from "store";
import { useRouter } from "next/router";
import { getCategoryTree } from "@/services/category";
import { decodeJwt } from "@/utils/global";
import { getCookie } from "cookies-next";

export function useCategoryTree() {
  // isChangeLang, setIsChangeLang
  const { locale } = useRouter();
  const {
    dispatch,
    appData: { menuTreeData },
  } = useGlobalData();
  const handshake = getCookie("handshake");

  const decodedToken = decodeJwt(handshake);
  const [categoryTreeData, setCategoryTreeData] = useState([]);
  const { data: categoryTree, mutate } = useSWR(
    `/mstore/category/tree?locale=${locale}/${handshake}`,
    () => getCategoryTree(handshake),
    {
      revalidateOnFocus: false,
      // onSuccess: (data) => {
      //   console.log('data?.data?.data//////////', data?.data?.data)
      //   setCategoryTreeData(data?.data?.data?.children_data || [])
      //   dispatch({
      //     type: "GET_CAT_TREE",
      //     payload: {
      //       ...(data?.data?.data || {}),
      //       cacheTime: Date.now() + 30 * 60 * 1000,
      //       locale
      //     },
      //   });
      // },
    }
  );
  console.log("categoryTree//////////", categoryTree);
  useEffect(() => {
    setTimeout(() => {
      if (
        (menuTreeData?.length == 0 || menuTreeData?.length) &&
        (store.get("app_data")?.cacheTime
          ? store.get("app_data")?.cacheTime
          : false) < Date.now()
      ) {
        mutate();
      }
    }, 2000);
    // if (locale !== decodedToken?.storeName) {
    //   mutate();
    //   setIsChangeLang(false)
    // }
    // else if (store.get("app_data")?.cacheTime > Date.now() && locale === decodedToken?.storeName && !isChangeLang && locale === store.get("app_data")?.categoryData?.locale) {
    //   dispatch({
    //     type: "GET_CAT_TREE",
    //     payload: {
    //       ...store.get("app_data")?.categoryData,
    //       cacheTime: store.get("app_data")?.cacheTime,
    //     },
    //   });
    // }
    // else {
    //   mutate();
    //   setIsChangeLang(false)
    // }
    // store.set("lang", locale);
  }, [decodedToken?.storeName, locale, menuTreeData, mutate]);

  useEffect(() => {
    if (categoryTree && Object.keys(categoryTree?.data?.data || {})?.length) {
      setCategoryTreeData(categoryTree?.data?.data?.children_data || []);
      dispatch({
        type: "GET_CAT_TREE",
        payload: {
          ...(categoryTree?.data?.data || {}),
          cacheTime: Date.now() + 30 * 60 * 1000,
          locale,
        },
      });
    }
  }, [categoryTree, dispatch, locale]);

  return categoryTreeData;
}
