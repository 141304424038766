import browserStorage from "store";

export const checkoutReducers = (state, action) => {
  switch (action.type) {
    case "cart":
      return {
        ...state,
        cart: action?.payload,
        items: action?.payload?.items,
        totals: action?.payload?.total_segments,
      };
    case "shipping_address":
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case "shipping_methods":
      return {
        ...state,
        shippingMethods: action.payload,
      };
    case "pickup_storeId":
      return {
        ...state,
        pickupStoreId: action.payload,
      };
    case "VERIFY_MOBILE":
      const { number: mobile, status } = action.payload;
      browserStorage.set("checkout", {
        ...browserStorage.get("checkout"),
        mobiles: { ...state.mobiles, [mobile]: status },
      });
      return {
        ...state,
        mobiles: { ...state.mobiles, [mobile]: status },
      };
    case "GET_TEL":
      browserStorage.set("checkout", {
        ...browserStorage.get("checkout"),
        tel: action?.payload,
      });
      return {
        ...state,
        tel: action?.payload,
      };
    case "payments":
      return {
        ...state,
        payments: action.payload,
      };
    case "regions":
      return {
        ...state,
        regions: action.payload,
      };
    case "user_addresses":
      return {
        ...state,
        userAddresses: action.payload,
      };
    case "clear":
      return {
        cart: {},
        items: [],
        totals: [],
        shippingAddress: null,
        userAddresses: [],
        payments: null,
        regions: [],
      };
    default:
      return state;
  }
};
