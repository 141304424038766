import ArrowIcon from "@/components/icons/ArrowIcon";
import { useRouter } from "next/router";
import { useState } from "react";

const Categories = ({
  options,
  selectedFilterKeys,
  handleFilter,
  code,
  isModal,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { locale } = useRouter();
  return (
    <ul className="flex flex-col gap-1">
      {options?.slice(0, showMore ? options?.length : 11)?.map((option, i) => (
        <li
          className="flex items-center gap-2 cursor-pointer *:cursor-pointer"
          key={option?.key || i}
          onClick={() =>
            handleFilter({
              code,
              item: option,
            })
          }
        >
          <input
            className="border-2 rounded-sm appearance-none size-4 checked:bg-red-600 checked:border-0"
            //checked={selectedFilterKeys?.includes(`${code}=${option?.Key}`)}
            defaultChecked={selectedFilterKeys?.includes(`${code}=${option?.Key}`)}
            type="checkbox"
            id={`brand-${code}-${option?.key || i}`}
          />
          <label htmlFor={`brand-${code}-${option?.key || i}`} className=" text-[#586A84]">
            <span className="max-sm:text-[13px] text-sm">{option?.Label}</span>
          </label>
          <span className="text-[#586A84] max-sm:text-[13px] text-sm -mx-1 opacity-70">
            ({option?.DocCount})
          </span>
        </li>
      ))}
      {options?.length > 10 ? (
        <li
          className="flex max-sm:text-sm items-center gap-2 text-slate-600 cursor-pointer *:cursor-pointer text-[15px]"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? (
            <span>{locale === "en" ? "Show More" : "عرض المزيد"}</span>
          ) : (
            <span>{locale === "en" ? "Show Less" : "عرض أقل"}</span>
          )}
          <ArrowIcon className={!isModal ? "rotate-90 size-3 mt-0.5" : "max-sm:size-2.5"} />
        </li>
      ) : null}
    </ul>
  );
};

export default Categories;
