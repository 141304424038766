import { useRef, useState } from "react";

import Image from "@/components/global/Image";
import SearchZoomIcon from "../icons/SearchZoomIcon";

const Album = ({ album, setSelectedIdx, setZoom }) => {
  const ref = useRef();
  const [currentImg, setCurrentImg] = useState(0);
  return (
    <div className="grid grid-cols-4 max-sm:grid-cols-3 max-sm:flex max-sm:flex-col-reverse gap-4 h-fit md:sticky md:top-0">
      {album.length > 1 && (
        <div className="flex flex-col red-scroll max-sm:flex-row max-sm:w-[97%] max-sm:min-h-32 max-sm:gap-x-4 gap-y-4 max-h-96 overflow-auto min-h-96 ">
          {album?.map((img, index) => (
            <Image
              key={index}
              src={img.image}
              onClick={() => {
                setCurrentImg(index);
                setSelectedIdx((prev) => ({ ...prev, color: null }));
              }}
              className={`object-contain max-sm:size-32 w-full h-auto border rounded-sm cursor-pointer ${
                currentImg === index && "border-red-600"
              }`}
              loading="eager"
              alt="Sub Img"
            />
          ))}
        </div>
      )}
      <div
        className={`min-h-96 max-sm:min-h-96 max-h-96 max-sm:max-h-96 max-sm:max-w-[100%] w-96 border overflow-hidden flex items-center justify-center rounded-sm ${
          album?.length > 1 ? "col-span-3" : "col-span-full" 
        } relative`}
        ref={ref}
      >
        <Image
          src={album[currentImg]?.image}
          className="object-contain w-auto h-full relative overflow-hidden "
          loading="eager"
          alt="Main Img"
        />
        <SearchZoomIcon onClick={() => setZoom(true)} className="absolute z-40 cursor-pointer top-3 end-3 max-sm:size-5"/>
      </div>
    </div>
  );
};

export default Album;
