import CompareContext from "@/context/compare/compare-context";
import Link from "next/link";

import { useContext } from "react";
import { useTranslation } from "next-i18next";
import { usePathname } from "next/navigation";

const CompareProductFooter = () => {
  const compareCtx = useContext(CompareContext);
  const { t } = useTranslation("common");
  const pathname = usePathname();
  return (
    <>
      {compareCtx.compareProducts.length > 0 && (
        <div className="fixed bottom-0 bg-white py-7 px-6 md:px-11 w-full text-sm md:text-2xl font-bold shadow-compareFooter z-50 flex items-center justify-between mb-16 lg:mb-0">
          <div className="text-red-500">
            {t("Compare Products")} ({compareCtx.compareProducts.length})
          </div>
          <div className="text-xs lg:text-sm font-normal flex items-center gap-4">
            <button
              className="py-2 lg:py-3 px-4 lg:px-8 rounded-md border border-primary text-black transition-all hover:text-white hover:bg-primary"
              onClick={compareCtx.clearCompare}
            >
              {t("Delete All")}
            </button>
            {pathname !== "/compare" && (
              <Link
                href={"/compare"}
                className="py-2 lg:py-3 px-4 lg:px-8 rounded-md bg-primary text-white"
              >
                {t("Compare")}
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CompareProductFooter;
