import { Form, Formik, Field, ErrorMessage } from "formik";
import { loginValidation } from "@/utils/valitation/auth";
import { useState } from "react";

import Eye from "@/components/icons/Eye";
import Eyelock from "@/components/icons/Eyelock";
import { signIn, getSession } from "next-auth/react";
import { getCookie, setCookie } from "cookies-next";
import { preventLettersInInput } from "@/utils/global";

const Login = ({ t, locale, setAuth }) => {
  const handshake = getCookie("handshake");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleLogin = async (values, { setSubmitting, setFieldError }) => {
    setLoading(true);
    const submittedValues = {
      username: isMobile ? "00966" + values.mobilenumber : values.username,
      password: values.password,
    };

    const res = await signIn("credentials", {
      ...submittedValues,
      handshake,
      callbackUrl: "/",
      redirect: false,
    });
    if (!res.ok && res.status !== 200) {
      setFieldError("password", res?.error);
    } else {
      const session = await getSession();
      if (session?.mage) {
        setCookie("mage", session?.mage, { maxAge: 9 * 24 * 60 * 60 });
      }
      setAuth(false);
      // reset cart and checkout global state
      // merge guest cart if exist
    }
    setLoading(false);
    setSubmitting(false);
  };
  return (
    <div>
      <div className="grid grid-cols-2 my-2 border-b">
        <button
          onClick={() => setIsMobile((prev) => !prev)}
          className={`w-full sm:w-44 text-sm sm:text-base sm:px-3 mx-auto text-secondary-800 px-1 py-1.5 sm:p-2 ${
            isMobile ? "font-bold border-b border-black" : "font-semibold"
          }`}
        >
          {t("Login with Mobile")}
        </button>
        <button
          onClick={() => setIsMobile((prev) => !prev)}
          className={`w-full sm:w-44 text-sm sm:text-base sm:px-3 mx-auto text-secondary-800 px-1 py-1.5 sm:p-2 ${
            !isMobile ? "font-bold border-b border-black" : "font-semibold"
          }`}
        >
          {t("Login with Email")}
        </button>
      </div>
      <p className="my-4 text-center sm:my-7 max-sm:text-sm">
        <span className="font-bold">{t("If you have an account")}</span>
        <span className="font-semibold text-zinc-500">
          {t(", Log in using your email.")}
        </span>
      </p>
      <Formik
        onSubmit={handleLogin}
        initialValues={{
          username: "",
          mobilenumber: "",
          password: "",
        }}
        validationSchema={loginValidation(t, isMobile)}
      >
        <Form>
          {!isMobile ? (
            <>
              <Field
                name="username"
                type="email"
                className="max-sm:placeholder:text-sm max-sm:text-sm w-full max-sm:py-1.5 p-2 border rounded-md outline-none"
                placeholder={t("Email Address")}
              />
              <ErrorMessage
                name="username"
                component="p"
                className="text-xs sm:text-sm text-red-600 mt-0.5"
              />
            </>
          ) : (
            <>
              <Field
                className={`max-sm:placeholder:text-sm max-sm:text-sm w-full max-sm:py-1.5 p-2 border rounded-md outline-none ${
                  locale === "ar" && "text-end"
                }`}
                name="mobilenumber"
                placeholder={t("5xxxxxxxx")}
                type="tel"
                id="mobilenumber"
                onKeyDown={(event) => {
                  preventLettersInInput(event);
                }}
                maxLength={9}
              />
              <ErrorMessage
                name="mobilenumber"
                component="p"
                className="text-xs sm:text-sm text-red-600 mt-0.5"
              />
            </>
          )}
          <div className="relative mt-3">
            <Field
              name="password"
              type={show ? "text" : "password"}
              className="max-sm:placeholder:text-sm max-sm:text-sm w-full max-sm:py-1.5 p-2 border rounded-md outline-none"
              placeholder={t("Password")}
            />
            <button
              type="button"
              onClick={() => setShow(!show)}
              className={`absolute z-20 -translate-y-1/2 cursor-pointer ${
                locale === "ar" ? "left-2" : "right-2"
              } top-1/2 text-zinc-400`}
            >
              {show ? <Eyelock className="size-5" /> : <Eye />}
            </button>
          </div>
          <ErrorMessage
            name="password"
            component="p"
            className="text-xs sm:text-sm text-red-600 mt-0.5"
          />
          <div className="flex items-center justify-between my-3">
            <div className="flex items-center gap-2 *:cursor-pointer">
              <input
                id="remember-me"
                type="checkbox"
                className="border rounded-sm appearance-none size-4 checked:bg-red-600 checked:border-red-600"
              />
              <label
                htmlFor="remember-me"
                className="font-semibold max-sm:text-sm text-zinc-500"
              >
                {t("Remember Me ?")}
              </label>
            </div>
            <button
              type="button"
              onClick={() => setAuth("forgot")}
              className="font-bold underline max-sm:text-sm"
            >
              {t("Forgot Password")}
            </button>
          </div>
          <button
            type="submit"
            className="flex items-center justify-center w-full h-8 sm:h-10 max-sm:py-1.5 max-sm:text-sm p-2 mt-2 font-bold text-white bg-red-600 rounded-md disabled:opacity-75 disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? (
              <span className="border-t border-r border-white rounded-full animate-spin size-4" />
            ) : (
              t("Login")
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
