import { useEffect, useRef, useState } from "react";
import { useCart } from "@/context/cart";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { mergeGuestCart } from "@/utils/MergeGuestCart";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import FixedBox from "./FixedBox";
import TopHeader from "./TopHeader";
import CopyRights from "./CopyRights";
import CartModal from "@/components/modals/cart-modal/CartModal";
import { useRouter } from "next/router";
import { useCategoryTree } from "@/hooks/category/useCategoryTree";
import useClient from "@/hooks/global/useClient";
import useSearchBox from "@/hooks/search/useSearchBox";
import { SWRConfig } from "swr";
import Body from "@/components/category/Body";
import Loading from "@/components/global/Loading";

const Layout = ({ children }) => {
  const { t } = useTranslation("common");
  const { locale, push } = useRouter();
  const { dispatch: dispatchCart } = useCart();
  const { data: session } = useSession();
  const ourSearchContainer = useRef(null);
  const ourSearchInput = useRef(null);
  const productQuickViewRef = useRef(null);
  const { isClient } = useClient();
  const [isChangeLang, setIsChangeLang] = useState(false);

  // merge guest cart to user cart
  useEffect(() => {
    mergeGuestCart(session, dispatchCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const {
    inputValue,
    setInputValue,
    loadingSearch,
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
  } = useSearchBox(ourSearchContainer, ourSearchInput, productQuickViewRef);
  const [auth, setAuth] = useState(false);
  useCategoryTree(isChangeLang, setIsChangeLang);

  return (
    <>
      <TopHeader {...{ t, locale }} />
      <Header
        {...{
          t,
          locale,
          push,
          setInputValue,
          inputValue,
          ourSearchInput,
          auth,
          setAuth,
        }}
      />
      {isClient && <NavBar {...{ t, locale }} />}
      <div className="mx-auto max-w-[1440px] w-11/12">
        {isClient && (
          <div ref={ourSearchContainer}>
            {inputValue ? (
              loadingSearch ? (
                <Loading />
              ) : allProducts?.length ? (
                <SWRConfig value={{ provider: () => new Map() }}>
                  <Body
                    {...{
                      fromCategory: false,
                      language: locale,
                      t,
                      allProducts,
                      handleSorting,
                      totalProducts,
                      selectedFilterKeys,
                      setSelectedFilterKeys,
                      handleFilter,
                      handleResetFilter,
                      allFilters,
                      productQuickViewRef,
                    }}
                  />
                </SWRConfig>
              ) : (
                <div className="h-[80vh] flex justify-center items-center">
                  <p className="text-2xl text-slate-800">
                    {locale == "en"
                      ? "Empty search result"
                      : "لا يوجد نتائج بحث"}
                  </p>
                </div>
              )
            ) : (
              children
            )}
          </div>
        )}
      </div>
      {isClient && <Footer t={t} />}
      <CopyRights />
      <FixedBox {...{ setAuth }} />
      <CartModal />
    </>
  );
};

export default Layout;
