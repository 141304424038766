const NotFound = (props) => (
  <svg
    width={413}
    height={333}
    viewBox="0 0 413 333"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={39}
      width={411}
      height={232}
    >
      <path d="M410.865 39.7637H0V270.388H410.865V39.7637Z" fill="white" />
    </mask>
    <g mask="url(#mask0_177_23694)">
      <mask
        id="mask1_177_23694"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={39}
        width={411}
        height={232}
      >
        <path
          d="M53.2086 270.387H11.1011C-0.165985 258.683 -3.17986 240.031 3.55045 225.244C9.96588 211.149 23.7397 201.836 32.0853 188.79C49.8962 160.949 41.2873 119.472 65.4907 96.9657C77.5887 85.715 94.9858 82.4607 111.454 81.1455C127.923 79.8295 144.943 79.8663 160.173 73.4656C178.954 65.5715 193.111 48.6169 212.457 42.2285C234.179 35.0549 260.163 44.0065 272.857 63.0369C280.8 74.9444 283.797 89.905 293.19 100.706C308.378 118.171 335.137 119.526 354.08 132.826C367.073 141.947 375.845 156.844 377.518 172.631C379.541 191.691 372.304 213.341 383.734 228.73C389.306 236.234 398.342 240.464 404.348 247.626C410.33 254.764 412.016 261.266 410.133 270.388H53.2086V270.387Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_177_23694)">
        <path
          d="M-18.6953 269.36L-1.36533 7.67847L427.534 36.0825L410.204 297.765L-18.6953 269.36Z"
          fill="url(#paint0_linear_177_23694)"
        />
      </g>
    </g>
    <mask
      id="mask2_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={62}
      width={160}
      height={65}
    >
      <path
        d="M69.8436 65.2146C65.2872 67.6601 61.3941 71.3242 58.6632 75.7162C54.2704 82.7744 52.6019 91.9338 45.8871 96.8337C38.3954 102.305 27.9265 100.13 18.9798 102.575C8.74733 105.372 0.617643 115.922 2.17161 126.31L2.54784 126.261H159.139L160.872 126.204C160.766 124.02 160.202 121.934 158.451 120.765C156.996 119.792 155.123 119.775 153.471 119.194C150.027 117.959 147.991 114.426 146.748 110.983C145.505 107.548 144.703 103.835 142.462 100.939C137.694 94.789 128.501 94.6664 120.837 96.0158C118.498 96.433 116.077 96.9237 113.795 96.2939C111.317 95.6143 109.372 93.7094 107.826 91.6647C100.889 82.5127 98.9019 68.7724 88.532 63.8488C86.1954 62.7422 83.6583 62.2482 81.0894 62.2482C77.2183 62.2482 73.2745 63.3711 69.8436 65.2146Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_177_23694)">
      <path
        d="M160.872 62.2482H0.617188V126.31H160.872V62.2482Z"
        fill="url(#paint1_linear_177_23694)"
      />
    </g>
    <mask
      id="mask3_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={228}
      y={81}
      width={185}
      height={65}
    >
      <path
        d="M266.26 84.0394C263.577 85.2826 261.018 86.8366 258.81 88.7995C253.232 93.7476 250.14 101.051 249.094 108.428C248.202 114.644 248.439 121.555 244.408 126.373C242.306 128.884 239.32 130.438 236.516 132.131C233.709 133.815 230.897 135.844 229.547 138.829C228.508 141.103 228.687 144.063 230.144 145.977H412.751C411.679 139.949 409.659 134.093 406.797 128.687C405.079 125.449 402.928 122.251 399.715 120.492C395.728 118.314 390.909 118.713 386.156 119.113C380.701 119.572 375.331 120.031 371.407 116.591C368.545 114.08 367.449 110.171 366.01 106.637C362.566 98.156 355.549 90.3698 346.47 89.1921C338.75 88.2016 331.143 92.1683 325.451 97.4763C321.444 101.215 316.905 106.057 311.563 104.829C309.699 104.396 308.088 103.243 306.681 101.951C300.719 96.5121 297.365 88.3578 290.528 84.0721C287.143 81.9498 283.238 81.0321 279.25 81.0321C274.818 81.0329 270.287 82.1665 266.26 84.0394Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_177_23694)">
      <path
        d="M224.309 145.704L229.282 69.1184L416.95 81.3048L411.978 157.89L224.309 145.704Z"
        fill="#FAF9F9"
      />
    </g>
    <mask
      id="mask4_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={64}
      y={151}
      width={94}
      height={120}
    >
      <path
        d="M105.863 203.553C108.201 199.909 110.296 195.993 112.146 191.803H113.044C112.934 192.021 112.784 194.345 112.595 198.78C112.403 203.214 112.308 206.735 112.308 209.346V223.218H92.6451L105.863 203.553ZM114.349 151.087L64.7383 223.626V247.288H112.308V270.38H144.131V247.288H157.269V223.218H144.131V151.087H114.349Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_177_23694)">
      <path
        d="M157.269 151.087H64.7383V270.381H157.269V151.087Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask5_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={56}
      y={151}
      width={93}
      height={120}
    >
      <path
        d="M97.1714 203.561C99.5089 199.917 101.604 196 103.454 191.811H104.351C104.242 192.029 104.093 194.354 103.902 198.788C103.711 203.222 103.617 206.743 103.617 209.354V223.226H83.9528L97.1714 203.561ZM105.657 151.095L56.0469 223.634V247.297H103.617V270.388H135.44V247.297H148.577V223.226H135.44V151.095H105.657Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_177_23694)">
      <path
        d="M56.0805 272.202L53.707 151.141L148.54 149.281L150.913 270.341L56.0805 272.202Z"
        fill="url(#paint2_linear_177_23694)"
      />
    </g>
    <mask
      id="mask6_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={269}
      y={156}
      width={96}
      height={115}
    >
      <path
        d="M304.599 218.581L306.152 214.909C307.755 210.886 309.065 206.641 310.087 202.175L310.97 202.011C310.905 202.241 311.199 204.555 311.854 208.946C312.508 213.33 313.081 216.806 313.579 219.374L316.212 232.991L307.682 234.644L296.91 236.722L304.599 218.581ZM304.517 161.788L269.586 242.423L274.076 265.659L320.785 256.62L323.443 270.385H355.856L352.028 250.584L356.395 249.743L364.926 248.09L360.354 224.454L351.823 226.106L347.455 226.949L333.766 156.129L304.517 161.788Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_177_23694)">
      <path
        d="M248.285 160.248L361.487 138.354L386.226 266.265L273.023 288.159L248.285 160.248Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask7_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={261}
      y={157}
      width={96}
      height={114}
    >
      <path
        d="M297.623 216.56C299.226 212.543 300.534 208.299 301.556 203.833L302.439 203.661C302.374 203.899 302.669 206.213 303.323 210.596C303.79 213.721 304.214 216.387 304.6 218.579C304.755 219.471 304.911 220.281 305.05 221.025L307.683 234.642L288.374 238.379L297.623 216.56ZM304.517 161.787L295.987 163.438L261.055 244.082L265.546 267.308L274.076 265.656L312.255 258.279L314.594 270.383H347.006L343.497 252.235L352.029 250.582L356.396 249.74L351.823 226.112L338.926 228.605L325.226 157.786L304.517 161.787Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_177_23694)">
      <path
        d="M261.091 272.251L258.848 157.831L356.358 155.918L358.602 270.341L261.091 272.251Z"
        fill="url(#paint3_linear_177_23694)"
      />
    </g>
    <mask
      id="mask8_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={91}
      y={94}
      width={71}
      height={116}
    >
      <path
        d="M91.6328 96.3814C112.104 100.029 129.936 119.509 129.936 119.509L142.28 209.876L161.708 209.878L155.076 95.4735L111.131 94.2794L91.6328 96.3814Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask8_177_23694)">
      <path
        d="M63.6289 101.517L157.321 77.3038L189.711 202.642L96.0193 226.854L63.6289 101.517Z"
        fill="black"
      />
    </g>
    <path
      d="M85.0258 160.199C85.0258 160.199 73.9861 176.356 78.8876 190.229L99.4253 160.199L96.7582 156.617L85.0258 160.199Z"
      fill="#111023"
    />
    <path
      d="M142.275 209.877L141.613 214.05C141.613 214.05 135.761 214.869 135.434 223.226H150.656L158.926 215.152L159.698 209.878H142.275V209.877Z"
      fill="#111023"
    />
    <mask
      id="mask9_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={71}
      y={96}
      width={59}
      height={66}
    >
      <path
        d="M91.6205 96.3809L84.366 97.166C75.402 98.1385 69.5869 107.111 72.3677 115.691L81.8804 161.737L98.3827 155.824L94.4258 118.536L118.774 119.445L129.932 119.509C129.932 119.509 112.1 100.029 91.6287 96.3809H91.6205Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask9_177_23694)">
      <path
        d="M129.931 96.3809H69.5859V161.737H129.931V96.3809Z"
        fill="black"
      />
    </g>
    <mask
      id="mask10_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={31}
      y={4}
      width={43}
      height={18}
    >
      <path
        d="M34.7665 13.1456C34.7665 17.6439 33.1659 21.3456 31.1016 21.8323L31.1163 21.8911L62.9284 17.8574L66.5189 17.4002C66.4306 16.1685 66.357 13.566 67.3081 13.0123C68.5693 12.2819 69.7937 13.4776 71.5562 14.9964C71.9177 15.313 72.2784 15.8953 72.6178 16.6256L73.9379 16.4563V15.3718V10.1587V9.29583L31.1899 4.47363C33.21 5.0486 34.7665 8.70615 34.7665 13.1456Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_177_23694)">
      <path
        d="M73.9379 4.47388H31.1016V21.8914H73.9379V4.47388Z"
        fill="url(#paint4_linear_177_23694)"
      />
    </g>
    <mask
      id="mask11_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={26}
      y={4}
      width={8}
      height={9}
    >
      <path
        d="M26.5586 12.061C27.0231 11.8476 27.5466 11.7437 28.0553 11.6775C28.8217 11.5744 29.6035 11.5302 30.3192 11.2497C30.9972 10.9839 31.5951 10.5202 32.0081 9.91575C32.5757 9.10441 32.79 8.09352 33.2464 7.20858C33.32 7.06872 33.4009 6.93622 33.4893 6.80291C32.8775 5.56464 32.0817 4.72387 31.1968 4.4736L30.5327 4.39917C28.4757 4.5104 26.8015 7.81381 26.5586 12.061Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask11_177_23694)">
      <path
        d="M33.4893 4.39954H26.5586V12.0614H33.4893V4.39954Z"
        fill="url(#paint5_linear_177_23694)"
      />
    </g>
    <mask
      id="mask12_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={26}
      y={6}
      width={9}
      height={12}
    >
      <path
        d="M33.241 7.21133C32.7847 8.09628 32.5704 9.10717 32.0028 9.9185C31.5897 10.5229 30.9919 10.9867 30.3138 11.2525C29.5982 11.533 28.8163 11.5772 28.05 11.6802C27.5412 11.7465 27.0178 11.8503 26.5533 12.0638C26.523 12.4179 26.5156 12.7794 26.5156 13.1483C26.5156 14.9247 26.7667 16.5768 27.1945 17.9558C27.4521 17.5509 27.7989 17.2115 28.2266 16.9899C29.0666 16.5695 30.0628 16.6807 30.9845 16.4738C31.9807 16.2448 32.8869 15.6256 33.4545 14.7775C33.942 14.0553 34.1922 13.1777 34.7165 12.4768C34.7312 12.4621 34.7377 12.4474 34.7533 12.4327C34.6715 10.2203 34.1996 8.25167 33.4839 6.80566C33.3956 6.93898 33.3146 7.07148 33.241 7.21133Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask12_177_23694)">
      <path
        d="M34.7533 6.80518H26.5156V17.9553H34.7533V6.80518Z"
        fill="url(#paint6_linear_177_23694)"
      />
    </g>
    <mask
      id="mask13_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={27}
      y={12}
      width={8}
      height={10}
    >
      <path
        d="M34.7212 12.4765C34.197 13.1775 33.9467 14.055 33.4593 14.7772C32.8916 15.6254 31.9854 16.2445 30.9893 16.4735C30.0675 16.6804 29.0713 16.5692 28.2314 16.9896C27.8036 17.2112 27.4568 17.5506 27.1992 17.9555C27.9369 20.3298 29.2047 21.8936 30.6425 21.8936C30.7979 21.8936 30.9598 21.8715 31.1079 21.8347C33.1722 21.348 34.7728 17.6463 34.7728 13.148C34.7728 12.9043 34.7646 12.6687 34.758 12.4324C34.7425 12.4471 34.736 12.4618 34.7212 12.4765Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask13_177_23694)">
      <path
        d="M34.7728 12.432H27.1992V21.8932H34.7728V12.432Z"
        fill="url(#paint7_linear_177_23694)"
      />
    </g>
    <path
      d="M99.1289 11.0078H111.989C110.647 11.546 109.505 12.1577 108.988 12.6444C108.583 13.0206 108.759 13.7068 109.261 14.5844H99.1289V11.0078Z"
      fill="#111023"
    />
    <path
      d="M127.631 13.1613V14.5844H120.662C119.991 13.0361 119.49 11.7308 119.32 11.0078H127.631V13.1613Z"
      fill="#111023"
    />
    <mask
      id="mask14_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={73}
      y={10}
      width={27}
      height={6}
    >
      <path
        d="M73.9414 10.1608V15.3739H99.1312V14.5855V11.0089V10.1608H73.9414Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask14_177_23694)">
      <path
        d="M99.1312 10.1604H73.9414V15.3735H99.1312V10.1604Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask15_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={62}
      y={12}
      width={13}
      height={17}
    >
      <path
        d="M67.3097 13.013C66.3585 13.5667 66.4321 16.1692 66.5204 17.4009L62.93 17.8581C62.6568 21.442 69.3896 28.4987 69.3896 28.4987L74.9348 24.2809C74.9348 24.2809 73.9165 19.4063 72.6194 16.6264C72.2799 15.896 71.9193 15.3137 71.5578 14.9971C70.1608 13.7932 69.1017 12.793 68.0949 12.793C67.8315 12.793 67.5714 12.8617 67.3097 13.013Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask15_177_23694)">
      <path
        d="M74.9342 12.7932H62.6562V28.4989H74.9342V12.7932Z"
        fill="url(#paint8_linear_177_23694)"
      />
    </g>
    <path
      d="M37.6218 4.50693C37.2513 3.6956 36.0531 4.38834 36.4204 5.19231C38.7006 10.183 38.4078 15.6726 35.6753 20.4195C35.2312 21.19 36.4285 21.8835 36.8726 21.1106C39.8587 15.9237 40.1057 9.94336 37.6218 4.50693Z"
      fill="#5B2B89"
    />
    <mask
      id="mask16_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={131}
      y={29}
      width={6}
      height={6}
    >
      <path
        d="M136.383 34.125V29.8483L131.266 29.2872L136.383 34.125Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask16_177_23694)">
      <path
        d="M136.517 29.291L136.379 34.2662L131.133 34.1222L131.27 29.1471L136.517 29.291Z"
        fill="url(#paint9_linear_177_23694)"
      />
    </g>
    <mask
      id="mask17_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={118}
      y={25}
      width={51}
      height={37}
    >
      <path
        d="M126.236 26.4044L120.616 30.3351L118.094 32.0968L139.501 61.1364C139.501 61.1364 159.058 56.3951 163.497 55.5617C167.936 54.7365 167.803 49.6329 168.533 45.0242C169.211 40.7467 166.852 36.286 162.995 35.7396C162.708 35.6955 162.413 35.6734 162.103 35.6808C157.731 35.747 146.463 43.6379 146.463 43.6379L141.544 38.9915L136.389 34.1252L131.271 29.2874L129.841 27.9379L127.37 25.6078L126.236 26.4044Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask17_177_23694)">
      <path
        d="M170.334 25.6437L169.161 62.7524L116.973 61.1011L118.146 23.994L170.334 25.6437Z"
        fill="#E6625A"
      />
    </g>
    <mask
      id="mask18_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={141}
      y={30}
      width={22}
      height={14}
    >
      <path
        d="M141.539 38.9929L146.458 43.6393C146.458 43.6393 157.726 35.7484 162.098 35.6822C162.408 35.6748 162.703 35.6969 162.99 35.741L150.204 30.7422L141.539 38.9929Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask18_177_23694)">
      <path
        d="M163.345 30.752L162.973 44.2298L141.184 43.6294L141.555 30.1525L163.345 30.752Z"
        fill="url(#paint10_linear_177_23694)"
      />
    </g>
    <mask
      id="mask19_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={66}
      y={24}
      width={98}
      height={74}
    >
      <path
        d="M69.3885 28.501L66.9766 30.3371L85.0737 53.7128L113.906 50.5272L116.295 69.8176L111.126 94.2787C124.392 99.271 145.608 96.8517 152.687 95.8417C154.199 95.6282 155.069 95.4728 155.069 95.4728L156.064 75.7841L163.491 55.5637C159.051 56.3971 139.494 61.1383 139.494 61.1383L118.087 32.0988L120.61 30.3371C120.61 30.3371 119.769 29.9903 118.795 29.2894L92.0347 40.985L74.9337 24.2823L69.3885 28.501Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask19_177_23694)">
      <path
        d="M163.491 24.2821H66.9766V99.2708H163.491V24.2821Z"
        fill="#E0483E"
      />
    </g>
    <mask
      id="mask20_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={108}
      y={9}
      width={19}
      height={22}
    >
      <path
        d="M111.994 11.0074C110.653 11.5455 109.511 12.1573 108.994 12.6439C108.588 13.021 108.765 13.7064 109.267 14.5839C110.83 17.3345 115.608 21.9432 115.851 24.7747C116.036 26.884 117.533 28.3807 118.801 29.2877C119.775 29.9886 120.616 30.3354 120.616 30.3354L126.235 26.4047C126.235 26.4047 122.702 19.3325 120.667 14.5839C119.996 13.0357 119.494 11.7295 119.325 11.0074C119.31 10.9558 119.296 10.9035 119.287 10.8593C119.134 10.0791 118.33 9.77563 117.226 9.77563C115.737 9.77563 113.702 10.3293 111.994 11.0074Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask20_177_23694)">
      <path
        d="M126.233 9.77588H108.586V30.3357H126.233V9.77588Z"
        fill="url(#paint11_linear_177_23694)"
      />
    </g>
    <mask
      id="mask21_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={128}
      y={8}
      width={24}
      height={31}
    >
      <path
        d="M129.068 9.89626L129.024 10.1613C128.891 10.8466 128.84 11.9017 128.84 13.1629C128.84 18.6271 129.835 27.9403 129.835 27.9403L131.266 29.2898L136.383 29.8508L144.93 29.2898L141.538 38.9939L150.203 30.7431L149.502 19.5112C149.502 19.5112 152.341 16.4736 151.692 13.9447C151.412 12.8382 150.711 12.6975 150.019 12.8897C149.119 13.1334 148.211 13.9447 148.211 13.9447C148.211 13.9447 147.717 20.3005 146.125 20.1091C144.532 19.9095 143.366 8.56885 143.366 8.56885C143.366 8.56885 135.056 10.9055 129.068 9.89626Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask21_177_23694)">
      <path
        d="M152.341 8.56848H128.84V38.9935H152.341V8.56848Z"
        fill="url(#paint12_linear_177_23694)"
      />
    </g>
    <path
      d="M150.085 4.64403L150.019 12.889C149.119 13.132 148.212 13.9433 148.212 13.9433C148.212 13.9433 147.718 20.2998 146.125 20.1076C144.533 19.9089 143.367 8.56738 143.367 8.56738C143.367 8.56738 135.057 10.9049 129.069 9.89479V9.88743C127.188 9.57746 125.544 8.92888 124.453 7.77813C119.874 2.95593 121.466 0.219313 128.029 1.70948C134.592 3.19884 148.198 -4.69858 150.085 4.64403Z"
      fill="#111023"
    />
    <path
      d="M144.934 29.2886L141.543 38.9935L136.387 34.1263V29.8496L144.934 29.2886Z"
      fill="#F79244"
    />
    <path
      d="M128.497 11.3905C129.814 10.7288 131.185 10.6503 132.571 11.15C133.418 11.4543 132.779 12.6999 131.942 12.3981C131.071 12.0849 130.085 12.147 129.258 12.5633C128.462 12.9625 127.693 11.7945 128.497 11.3905Z"
      fill="#111023"
    />
    <path
      d="M142.222 14.6542C142.265 15.477 141.851 16.1681 141.296 16.1967C140.741 16.2253 140.257 15.5817 140.214 14.7589C140.171 13.9361 140.586 13.2458 141.141 13.2164C141.694 13.1877 142.178 13.8314 142.222 14.6542Z"
      fill="#111023"
    />
    <path
      d="M142.974 11.056C141.594 10.5358 140.223 10.6004 138.897 11.2417C138.086 11.6326 138.851 12.8046 139.651 12.4186C140.486 12.0145 141.473 11.9745 142.339 12.3016C143.172 12.6149 143.815 11.3742 142.974 11.056Z"
      fill="#111023"
    />
    <path
      d="M133.269 19.3211C133.715 17.2077 134.808 15.0559 135.547 13.0251C135.7 12.6022 136.323 12.9212 136.171 13.3391C135.705 14.6191 135.24 15.8999 134.774 17.1815C134.613 17.6248 134.221 18.3593 134.176 18.8279C134.024 20.4228 134.753 20.2984 135.579 20.6828C135.988 20.8726 135.672 21.4966 135.265 21.3069C134.25 20.8358 132.962 20.7712 133.269 19.3211Z"
      fill="#F79244"
    />
    <path
      d="M132.498 14.882C131.845 14.3553 131.102 14.0093 130.266 13.9022C129.751 13.8367 129.537 14.6268 130.059 14.6939C130.481 14.7478 130.881 14.8689 131.254 15.0488C130.818 15.1903 130.383 15.3326 129.946 15.4741C129.447 15.6361 129.655 16.4269 130.158 16.2642C130.876 16.0303 131.595 15.7972 132.313 15.5641C132.575 15.479 132.754 15.0881 132.498 14.882Z"
      fill="#111023"
    />
    <path
      d="M137.697 25.558C137.697 27.0089 136.883 28.1858 135.878 28.1858C134.873 28.1858 134.059 27.0089 134.059 25.558C134.059 24.1062 134.873 22.9293 135.878 22.9293C136.883 22.9293 137.697 24.1062 137.697 25.558Z"
      fill="white"
    />
    <g opacity={0.5}>
      <mask
        id="mask22_177_23694"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={52}
        y={270}
        width={335}
        height={63}
      >
        <path d="M386.564 270.39H52.5742V332.32H386.564V270.39Z" fill="white" />
      </mask>
      <g mask="url(#mask22_177_23694)">
        <mask
          id="mask23_177_23694"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x={52}
          y={270}
          width={335}
          height={63}
        >
          <path
            d="M386.565 270.391H58.3841C58.3841 270.391 40.7262 282.113 66.9784 284.099C93.2314 286.084 134.179 287.959 133.169 296.891C132.159 305.824 108.45 304.059 108.45 319.828C108.45 335.598 217.801 331.959 217.801 331.959C217.801 331.959 279.573 332.767 288.371 322.328C297.167 311.889 263.468 302.332 269.584 298.215C275.701 294.098 320.105 306.448 326.72 296.451C333.336 286.453 325.397 280.13 337.602 278.807C349.806 277.483 384.506 282.267 386.565 270.391Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask23_177_23694)">
          <path
            d="M39.7812 335.583L40.7987 265.379L387.509 270.404L386.491 340.608L39.7812 335.583Z"
            fill="#E0483E"
          />
        </g>
      </g>
    </g>
    <mask
      id="mask24_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={336}
      y={284}
      width={51}
      height={15}
    >
      <path
        d="M336.941 291.153C336.941 295.051 348.05 298.21 361.753 298.21C375.457 298.21 386.565 295.051 386.565 291.153C386.565 287.255 375.457 284.095 361.753 284.095C348.05 284.095 336.941 287.255 336.941 291.153Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask24_177_23694)">
      <path
        d="M386.565 284.096H336.941V298.211H386.565V284.096Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask25_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={75}
      y={291}
      width={45}
      height={12}
    >
      <path
        d="M75.8867 297.256C75.8867 300.22 85.5965 302.623 97.5735 302.623C109.551 302.623 119.261 300.22 119.261 297.256C119.261 294.291 109.551 291.888 97.5735 291.888C85.5965 291.888 75.8867 294.291 75.8867 297.256Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask25_177_23694)">
      <path
        d="M119.261 291.888H75.8867V302.622H119.261V291.888Z"
        fill="#99271F"
      />
    </g>
    <path
      d="M246.514 318.17C246.514 320.789 235.487 322.912 221.882 322.912C208.277 322.912 197.25 320.789 197.25 318.17C197.25 315.552 208.277 313.429 221.882 313.429C235.487 313.429 246.514 315.552 246.514 318.17Z"
      fill="#E9FDFF"
    />
    <mask
      id="mask26_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={168}
      y={184}
      width={91}
      height={87}
    >
      <path
        d="M191.231 193.732C182.316 202.008 175.739 216.542 171.486 237.334C168.886 250.067 168.159 261.084 169.312 270.383H199.704C199.318 264.952 200.391 256.07 202.91 243.753C205.634 230.43 208.333 221.432 211.005 216.755C212.56 214.04 214.32 212.248 216.282 211.382C217.697 210.752 219.228 210.605 220.854 210.94C224.748 211.741 226.987 214.907 227.577 220.435C228.166 225.973 227.136 235.214 224.493 248.161C222.506 257.869 220.509 265.271 218.514 270.382H251.835C253.38 265.597 254.738 260.331 255.907 254.582C256.684 250.779 257.297 247.13 257.739 243.639C259.645 228.753 258.548 216.631 254.458 207.277C249.404 195.719 239.925 188.521 226.021 185.684C224.508 185.373 223.027 185.128 221.581 184.948C219.453 184.679 217.393 184.544 215.395 184.544C205.872 184.543 197.816 187.606 191.231 193.732Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask26_177_23694)">
      <path
        d="M171.829 166.595L276.488 187.985L255.98 288.332L151.32 266.94L171.829 166.595Z"
        fill="#99271F"
      />
    </g>
    <mask
      id="mask27_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={160}
      y={182}
      width={91}
      height={89}
    >
      <path
        d="M182.707 191.998C173.801 200.284 167.217 214.816 162.972 235.599C162.105 239.828 161.451 243.876 161.001 247.729C160.011 256.194 160.019 263.751 161.033 270.383H191.385C191.344 270.187 191.319 269.991 191.295 269.786C190.657 264.495 191.688 255.237 194.387 242.02C197.11 228.697 199.809 219.7 202.492 215.029C205.166 210.352 208.444 208.413 212.338 209.215C213.933 209.542 215.251 210.262 216.28 211.382C217.794 213.001 218.71 215.439 219.061 218.701C219.65 224.239 218.619 233.48 215.977 246.427C213.752 257.289 211.519 265.271 209.279 270.383H242.755C244.522 265.148 246.067 259.309 247.392 252.855C251.473 232.867 250.991 217.098 245.945 205.542C241.405 195.162 233.284 188.293 221.581 184.947C220.263 184.57 218.906 184.236 217.499 183.949C213.778 183.187 210.239 182.807 206.882 182.807C197.357 182.809 189.299 185.872 182.707 191.998Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask27_177_23694)">
      <path
        d="M160.049 272.176L158.297 182.842L251.442 181.016L253.193 270.349L160.049 272.176Z"
        fill="url(#paint13_linear_177_23694)"
      />
    </g>
    <path
      d="M269.148 127.6L268.944 127.756L247.573 144.391L234.233 154.77L223.126 185.506L220.043 184.393L231.485 152.766L267.144 125.016L269.148 127.6Z"
      fill="#2A2A2A"
    />
    <mask
      id="mask28_177_23694"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={247}
      y={127}
      width={36}
      height={77}
    >
      <path
        d="M247.57 144.39C249.918 145.364 251.734 147.645 251.971 150.197C252.316 153.852 249.787 157.484 250.735 161.034C251.391 163.48 253.598 165.239 255.945 166.187C258.571 167.234 261.883 167.831 262.981 170.431C263.471 171.585 263.379 172.893 263.12 174.12C262.464 177.179 260.786 180.115 261.148 183.223C261.426 185.586 262.872 187.673 264.583 189.332C266.293 190.993 268.287 192.326 270.079 193.905C271.134 194.844 272.165 195.958 272.435 197.357C272.819 199.41 271.461 201.34 270.177 202.984L270.398 203.368C273.647 201.447 276.958 199.468 279.387 196.58C281.816 193.693 283.238 189.677 282.093 186.078C281.259 183.478 279.176 181.318 278.766 178.611C278.128 174.374 281.782 170.553 281.834 166.269C281.882 162.45 279.109 159.236 276.353 156.594C275.02 155.31 273.572 153.936 273.237 152.12C273.023 150.934 273.319 149.724 273.662 148.562C274.529 145.683 275.748 142.821 275.731 139.811C275.691 135.06 272.566 130.896 268.944 127.755L247.57 144.39Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask28_177_23694)">
      <path
        d="M283.237 127.755H247.57V203.368H283.237V127.755Z"
        fill="#99271F"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_177_23694"
        x1={204.419}
        y1={7.67847}
        x2={204.419}
        y2={297.765}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E0483E" />
        <stop offset={1} stopColor="#F88E87" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_177_23694"
        x1={80.7539}
        y1={126.861}
        x2={80.7539}
        y2={92.0143}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_177_23694"
        x1={103.681}
        y1={278.259}
        x2={102.798}
        y2={233.267}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_177_23694"
        x1={309.942}
        y1={274.192}
        x2={309.059}
        y2={229.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_177_23694"
        x1={31.1242}
        y1={13.1673}
        x2={73.9613}
        y2={13.1673}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#51ADC6" />
        <stop offset={0.006} stopColor="#55AFC4" />
        <stop offset={0.0664} stopColor="#74BFB5" />
        <stop offset={0.1334} stopColor="#8FCDA7" />
        <stop offset={0.2104} stopColor="#A5D89D" />
        <stop offset={0.3005} stopColor="#B5E094" />
        <stop offset={0.4117} stopColor="#C1E78E" />
        <stop offset={0.5678} stopColor="#C8EA8B" />
        <stop offset={1} stopColor="#CAEB8A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_177_23694"
        x1={33.4939}
        y1={8.23726}
        x2={26.5623}
        y2={8.23726}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8AEBD6" />
        <stop offset={0.4595} stopColor="#89E9D7" />
        <stop offset={0.6261} stopColor="#84E2DA" />
        <stop offset={0.745} stopColor="#7DD6DE" />
        <stop offset={0.8413} stopColor="#72C6E5" />
        <stop offset={0.9228} stopColor="#64B0EE" />
        <stop offset={0.9948} stopColor="#5295F9" />
        <stop offset={1} stopColor="#5193FA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_177_23694"
        x1={34.755}
        y1={12.3611}
        x2={26.514}
        y2={12.3611}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31D1D6" />
        <stop offset={0.3727} stopColor="#32CED8" />
        <stop offset={0.6172} stopColor="#36C6DF" />
        <stop offset={0.8241} stopColor="#3CB7EB" />
        <stop offset={1} stopColor="#45A3FA" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_177_23694"
        x1={34.7751}
        y1={17.162}
        x2={27.2018}
        y2={17.162}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8AEBD6" />
        <stop offset={0.4595} stopColor="#89E9D7" />
        <stop offset={0.6261} stopColor="#84E2DA" />
        <stop offset={0.745} stopColor="#7DD6DE" />
        <stop offset={0.8413} stopColor="#72C6E5" />
        <stop offset={0.9228} stopColor="#64B0EE" />
        <stop offset={0.9948} stopColor="#5295F9" />
        <stop offset={1} stopColor="#5193FA" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_177_23694"
        x1={62.9199}
        y1={20.66}
        x2={74.9332}
        y2={20.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_177_23694"
        x1={133.893}
        y1={29.8369}
        x2={133.495}
        y2={44.287}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7B6ED6" />
        <stop offset={0.4803} stopColor="#796DD5" />
        <stop offset={0.6534} stopColor="#7268D0" />
        <stop offset={0.7768} stopColor="#6760C9" />
        <stop offset={0.8765} stopColor="#5655BE" />
        <stop offset={0.961} stopColor="#4047B0" />
        <stop offset={1} stopColor="#333EA8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_177_23694"
        x1={152.46}
        y1={30.3487}
        x2={152.062}
        y2={44.7988}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7B6ED6" />
        <stop offset={0.4803} stopColor="#796DD5" />
        <stop offset={0.6534} stopColor="#7268D0" />
        <stop offset={0.7768} stopColor="#6760C9" />
        <stop offset={0.8765} stopColor="#5655BE" />
        <stop offset={0.961} stopColor="#4047B0" />
        <stop offset={1} stopColor="#333EA8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_177_23694"
        x1={108.763}
        y1={20.0364}
        x2={126.228}
        y2={20.0364}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_177_23694"
        x1={128.843}
        y1={23.7924}
        x2={151.792}
        y2={23.7924}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE86" />
        <stop offset={0.3288} stopColor="#F7BC83" />
        <stop offset={0.5444} stopColor="#F7B67B" />
        <stop offset={0.7275} stopColor="#F7AC6C" />
        <stop offset={0.8914} stopColor="#F79E57" />
        <stop offset={1} stopColor="#F79244" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_177_23694"
        x1={206.77}
        y1={276.231}
        x2={205.888}
        y2={231.239}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E6D9" />
        <stop offset={0.1211} stopColor="#FCF1EA" />
        <stop offset={0.27} stopColor="#FEF9F6" />
        <stop offset={0.4754} stopColor="#FFFEFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
export default NotFound;
