function getDiscount(original_price, price) {
  return original_price !== price && price !== 0 ? original_price - price : 0;
}

export const pushToDataLayer = (event, data, session) => {
  if (typeof window != undefined) {
    const user_mobile = session?.user?.custom_attributes?.find(
      (attr) => attr?.attribute_code === "mobile_number"
    )?.value;
    window.dataLayer = window.dataLayer || [];
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event,
      ecommerce: data,
      ...(session && {
        user: {
          ...(session?.user?.id && { id: session?.user?.id }),
          name: `${session?.user?.firstname} ${session?.user?.lastname}`,
          email: session?.user?.email,
          mobile_number: user_mobile?.startsWith("00966")
            ? `+${user_mobile?.slice(2)}`
            : user_mobile?.startsWith("966")
            ? `+${user_mobile}`
            : `+966${user_mobile?.value}`,
        },
      }),
    });
  }
};

// Success Page

export const event_purchase = (
  order,
  session,
  email,
  firstname,
  lastname,
  telephone
) =>
  pushToDataLayer(
    "purchase",
    {
      transaction_id: order?.increment_id,
      value: order?.grand_total,
      tax: order?.tax_amount,
      shipping: order?.shipping_amount,
      currency: "SAR",
      // coupon: "SUMMER_SALE",
      items: order?.items?.map((item, i) => {
        return {
          item_id: item?.sku,
          item_name: item?.name,
          // coupon: "SUMMER_FUN",
          discount: getDiscount(
            item?.extension_attributes?.original_price,
            item?.extension_attributes?.final_price
          ),
          index: i + 1,
          price:
            item?.extension_attributes?.final_price ||
            item?.extension_attributes?.original_price,
          quantity: item?.qty_ordered,
        };
      }),
    },
    session?.user
      ? session
      : {
          user: {
            firstname,
            lastname,
            email,
            custom_attributes: [
              { attribute_code: "mobile_number", value: telephone },
            ],
          },
        }
  );

/* Check out Page  */
export const event_checkout_started = (cartData) =>
  pushToDataLayer("begin_checkout", {
    currency: "SAR",
    value: cartData?.grand_total,
    // coupon: "SUMMER_FUN",
    items: cartData?.items?.map((item, i) => {
      return {
        item_id: item?.extension_attributes?.product_sku,
        item_name: item?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          item?.extension_attributes?.original_price,
          item?.extension_attributes?.final_price
        ),
        index: i + 1,
        ...(item?.extension_attributes?.brand_name && {
          item_brand: item?.extension_attributes?.brand_name,
        }),
        ...(item?.extension_attributes?.category_names?.at(0) && {
          item_category:
            item?.extension_attributes?.category_names?.at(0)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(1) && {
          item_category1:
            item?.extension_attributes?.category_names?.at(1)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(2) && {
          item_category2:
            item?.extension_attributes?.category_names?.at(2)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(3) && {
          item_category3:
            item?.extension_attributes?.category_names?.at(3)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(4) && {
          item_category4:
            item?.extension_attributes?.category_names?.at(4)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(5) && {
          item_category5:
            item?.extension_attributes?.category_names?.at(5)?.name,
        }),
        price:
          item?.extension_attributes?.final_price ||
          item?.extension_attributes?.original_price,
        quantity: item?.qty,
      };
    }),
  });

export const event_shipping_info = (cartData) =>
  pushToDataLayer("add_shipping_info", {
    currency: "SAR",
    value: cartData?.grand_total,
    // coupon: "SUMMER_FUN",
    items: cartData?.items?.map((item, i) => {
      return {
        item_id: item?.extension_attributes?.product_sku,
        item_name: item?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          item?.extension_attributes?.original_price,
          item?.extension_attributes?.final_price
        ),
        index: i + 1,
        ...(item?.extension_attributes?.brand_name && {
          item_brand: item?.extension_attributes?.brand_name,
        }),
        ...(item?.extension_attributes?.category_names?.at(0) && {
          item_category:
            item?.extension_attributes?.category_names?.at(0)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(1) && {
          item_category1:
            item?.extension_attributes?.category_names?.at(1)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(2) && {
          item_category2:
            item?.extension_attributes?.category_names?.at(2)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(3) && {
          item_category3:
            item?.extension_attributes?.category_names?.at(3)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(4) && {
          item_category4:
            item?.extension_attributes?.category_names?.at(4)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(5) && {
          item_category5:
            item?.extension_attributes?.category_names?.at(5)?.name,
        }),
        price:
          item?.extension_attributes?.final_price ||
          item?.extension_attributes?.original_price,
        quantity: item?.qty,
      };
    }),
  });

export const event_payment_info = (cartData, payment_type) =>
  pushToDataLayer("add_payment_info", {
    currency: "SAR",
    value: cartData?.grand_total,
    // coupon: "SUMMER_FUN",
    payment_type,
    items: cartData?.items?.map((item, i) => {
      return {
        item_id: item?.extension_attributes?.product_sku,
        item_name: item?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          item?.extension_attributes?.original_price,
          item?.extension_attributes?.final_price
        ),
        index: i + 1,
        ...(item?.extension_attributes?.brand_name && {
          item_brand: item?.extension_attributes?.brand_name,
        }),
        ...(item?.extension_attributes?.category_names?.at(0) && {
          item_category: item?.extension_attributes?.category_names?.at(0),
        }),
        ...(item?.extension_attributes?.category_names?.at(1) && {
          item_category1: item?.extension_attributes?.category_names?.at(1),
        }),
        ...(item?.extension_attributes?.category_names?.at(2) && {
          item_category2: item?.extension_attributes?.category_names?.at(2),
        }),
        ...(item?.extension_attributes?.category_names?.at(3) && {
          item_category3: item?.extension_attributes?.category_names?.at(3),
        }),
        ...(item?.extension_attributes?.category_names?.at(4) && {
          item_category4: item?.extension_attributes?.category_names?.at(4),
        }),
        ...(item?.extension_attributes?.category_names?.at(5) && {
          item_category5: item?.extension_attributes?.category_names?.at(5),
        }),
        price:
          item?.extension_attributes?.final_price ||
          item?.extension_attributes?.original_price,
        quantity: item?.qty,
      };
    }),
  });

// Cart Section
export const event_view_cart = (cartData) =>
  pushToDataLayer("view_cart", {
    currency: "SAR",
    value: cartData?.grand_total,
    items: cartData?.items?.map((item, i) => {
      return {
        item_id: item?.extension_attributes?.product_sku,
        item_name: item?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          item?.extension_attributes?.original_price,
          item?.extension_attributes?.final_price
        ),
        index: i + 1,
        ...(item?.extension_attributes?.brand_name && {
          item_brand: item?.extension_attributes?.brand_name,
        }),
        ...(item?.extension_attributes?.category_names?.at(0) && {
          item_category:
            item?.extension_attributes?.category_names?.at(0)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(1) && {
          item_category1:
            item?.extension_attributes?.category_names?.at(1)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(2) && {
          item_category2:
            item?.extension_attributes?.category_names?.at(2)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(3) && {
          item_category3:
            item?.extension_attributes?.category_names?.at(3)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(4) && {
          item_category4:
            item?.extension_attributes?.category_names?.at(4)?.name,
        }),
        ...(item?.extension_attributes?.category_names?.at(5) && {
          item_category5:
            item?.extension_attributes?.category_names?.at(5)?.name,
        }),
        price:
          item?.extension_attributes?.final_price ||
          item?.extension_attributes?.original_price,
        quantity: item?.qty,
      };
    }),
  });

// Add To wishlist, Select Item
export const add_event_name = (product, event_name) =>
  pushToDataLayer(event_name, {
    currency: "SAR",
    value:
      product?.type_id === "simple"
        ? product?.prices_with_tax?.final_price ||
          product?.prices_with_tax?.original_price
        : product?._configurables?.[0].prices_with_tax?.final_price ||
          product?._configurables?.[0].prices_with_tax?.original_price,
    items: [
      {
        item_id:
          product?.type_id === "simple"
            ? product?.sku
            : product?._configurables?.[0]?.sku,
        item_name:
          product?.type_id === "simple"
            ? product?.name?.[0]
            : product?._configurables?.[0]?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          product?.type_id === "simple"
            ? product?.prices_with_tax?.original_price
            : product?._configurables?.[0]?.prices_with_tax?.original_price,
          product?.type_id === "simple"
            ? product?.prices_with_tax?.final_price
            : product?._configurables?.[0]?.prices_with_tax?.final_price
        ),
        index: 1,
        // ...(product?.extension_attributes?.brand_name && {
        //   item_brand: product?.extension_attributes?.brand_name,
        // }),
        ...(product?.category?.at(0) && {
          item_category: product?.category?.at(0)?.name,
        }),
        ...(product?.category?.at(1) && {
          item_category2: product?.category?.at(1)?.name,
        }),
        ...(product?.category?.at(2) && {
          item_category3: product?.category?.at(2)?.name,
        }),
        ...(product?.category?.at(3) && {
          item_category4: product?.category?.at(3)?.name,
        }),
        ...(product?.category?.at(4) && {
          item_category5: product?.category?.at(4)?.name,
        }),
        price:
          product?.type_id === "simple"
            ? product?.prices_with_tax?.final_price ||
              product?.prices_with_tax?.original_price
            : product?._configurables?.[0].prices_with_tax?.final_price ||
              product?._configurables?.[0].prices_with_tax?.original_price,
        quantity: 1,
      },
    ],
  });

// View Item in PDP && Add To Cart
export const event_view_item = (product, configIndex, event_name, qty) => {
  console.log({ event_name, product });
  return pushToDataLayer(event_name || "view_item", {
    currency: "SAR",
    value:
      product?.type_id === "simple"
        ? (qty || 1) *
          (product?.prices_with_tax?.final_price ||
            product?.prices_with_tax?.original_price)
        : (qty || 1) *
          (product?._configurables?.[configIndex || 0]?.prices_with_tax
            ?.final_price ||
            product?._configurables?.[configIndex || 0]?.prices_with_tax
              ?.original_price),
    items: [
      {
        item_id:
          product?.type_id === "simple"
            ? product?.sku
            : product?._configurables?.[configIndex || 0]?.sku,
        item_name:
          product?.type_id === "simple"
            ? product?.name?.[configIndex || 0]
            : product?._configurables?.[configIndex || 0]?.name,
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          product?.type_id === "simple"
            ? product?.prices_with_tax?.original_price
            : product?._configurables?.[configIndex || 0]?.prices_with_tax
                ?.original_price,
          product?.type_id === "simple"
            ? product?.prices_with_tax?.final_price
            : product?._configurables?.[configIndex || 0]?.prices_with_tax
                ?.final_price
        ),
        index: 1,
        ...(product?.option_text_brand?.at(0) && {
          item_brand: product?.option_text_brand?.at(0),
        }),
        ...(product?.category?.at(0) && {
          item_category: product?.category?.at(0)?.name,
          item_list_name: product?.category?.at(0)?.name,
          item_list_id: String(product?.category?.at(0)?.category_id),
        }),
        ...(product?.category?.at(1) && {
          item_category2: product?.category?.at(1)?.name,
        }),
        ...(product?.category?.at(2) && {
          item_category3: product?.category?.at(2)?.name,
        }),
        ...(product?.category?.at(3) && {
          item_category4: product?.category?.at(3)?.name,
        }),
        ...(product?.category?.at(4) && {
          item_category5: product?.category?.at(4)?.name,
        }),
        price:
          product?.type_id === "simple"
            ? product?.prices_with_tax?.final_price ||
              product?.prices_with_tax?.original_price
            : product?._configurables?.[configIndex || 0]?.prices_with_tax
                ?.final_price ||
              product?._configurables?.[configIndex || 0]?.prices_with_tax
                ?.original_price,
        quantity: qty || 1,
      },
    ],
  });
};

// Remove From Cart && Update Cart From PopUp, Cart Drop Down and Cart Page
export const event_toggle_cart = (product, event_name, qty) =>
  pushToDataLayer(event_name, {
    currency: "SAR",
    value: product?.extension_attributes
      ? qty *
        (product?.extension_attributes?.final_price ||
          product?.extension_attributes?.original_price)
      : qty *
        (product?.prices_with_tax?.final_price ||
          product?.prices_with_tax?.original_price),
    items: [
      {
        item_id: product?.extension_attributes
          ? product?.extension_attributes?.product_sku
          : product?.sku,
        item_name: product?.name,
        // affiliation: "Google Merchandise Store",
        // coupon: "SUMMER_FUN",
        discount: getDiscount(
          product?.extension_attributes
            ? product?.extension_attributes?.original_price
            : product?.prices_with_tax?.original_price,
          product?.extension_attributes
            ? product?.extension_attributes?.final_price
            : product?.prices_with_tax?.final_price
        ),
        index: 1,
        // ...(product?.option_text_brand?.at(0) && {
        //   item_brand: product?.option_text_brand?.at(0),
        // }),
        ...(product?.category?.at(0) && {
          item_category: product?.category?.at(0)?.name,
          item_list_name: product?.category?.at(0)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(0) && {
          item_category:
            product?.extension_attributes?.category_names.at(0)?.name,
          item_list_name:
            product?.extension_attributes?.category_names.at(0)?.name,
        }),
        ...(product?.category?.at(1) && {
          item_category2: product?.category?.at(1)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(1) && {
          item_category2:
            product?.extension_attributes?.category_names?.at(1)?.name,
        }),
        ...(product?.category?.at(2) && {
          item_category3: product?.category?.at(2)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(2) && {
          item_category3:
            product?.extension_attributes?.category_names?.at(2)?.name,
        }),
        ...(product?.category?.at(3) && {
          item_category4: product?.category?.at(3)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(3) && {
          item_category4:
            product?.extension_attributes?.category_names?.at(3)?.name,
        }),
        ...(product?.category?.at(4) && {
          item_category5: product?.category?.at(4)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(4) && {
          item_category5:
            product?.extension_attributes?.category_names?.at(4)?.name,
        }),
        ...(product?.extension_attributes?.category_names?.at(0) && {
          item_list_id: String(
            product?.extension_attributes?.category_names?.at(0)?.id
          ),
        }),
        ...(product?.category?.at(0) && {
          item_list_id: String(product?.category?.at(0)?.category_id),
        }),
        price: product?.extension_attributes
          ? product?.extension_attributes?.final_price ||
            product?.extension_attributes?.original_price
          : product?.prices_with_tax?.final_price ||
            product?.prices_with_tax?.original_price,
        quantity: qty,
      },
    ],
  });

// Category Page
export const event_view_item_list = (currentCategory, products) =>
  pushToDataLayer("view_item_list", {
    item_list_id: String(currentCategory?.id),
    item_list_name: currentCategory?.name,
    items: products?.map((product, i) => {
      return {
        item_id: product?.sku,
        item_name: product?.name?.[0],
        discount: getDiscount(
          product?.prices_with_tax?.original_price,
          product?.prices_with_tax?.final_price
        ),
        index: i + 1,
        ...(product?._category_?.main_category?.name && {
          item_category: product?._category_?.main_category?.name,
          item_list_name: product?._category_?.main_category?.name,
        }),
        ...(product?._category_?.sub_categories?.at(0)?.name && {
          item_category2: product?._category_?.sub_categories?.at(0)?.name,
        }),
        ...(product?._category_?.sub_categories?.at(1)?.name && {
          item_category3: product?._category_?.sub_categories?.at(1)?.name,
        }),
        ...(product?._category_?.sub_categories?.at(2)?.name && {
          item_category4: product?._category_?.sub_categories?.at(2)?.name,
        }),
        ...(product?._category_?.sub_categories?.at(3)?.name && {
          item_category5: product?._category_?.sub_categories?.at(3)?.name,
        }),
        ...(product?._category_?.main_category?.category_id && {
          item_list_id: String(product?._category_?.main_category?.category_id),
        }),
        price:
          product?.prices_with_tax?.final_price ||
          product?.prices_with_tax?.original_price,
        quantity: 1,
      };
    }),
  });
