import { useRef, useState } from "react";
import ArrowIcon from "../icons/ArrowIcon";
import useHandleOutSide from "@/hooks/useHandleOutSide";

const Sort = ({ t, sortName, sortKeys, handleSorting, setSortName, isResponsive=false }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useHandleOutSide(ref, () => setOpen(false))
  return (
    <>
      <span onClick={() => setOpen(!open)} className="font-bold text-[13px]">{t(sortName)}</span>
      <ArrowIcon onClick={() => setOpen(!open)} className="size-3 mt-0.5" />
      <ul ref={ref} className={`${open ? "block" : "hidden"} absolute top-full start-0 w-full border shadow-lg rounded-md bg-white p-2 z-50 *:text-black *:cursor-pointer`}>
        {sortKeys?.map((item, key) => (
          <li
            key={key}
            onClick={() => {
              handleSorting({ keys: item?.key });
              setSortName(item?.label);
              setOpen(false)
            }}
            className={`hover:text-secondary-700 ${
              sortKeys?.length - 1 == key ? "mb-0" : "mb-2"
            } ${!isResponsive ? "text-sm" : "text-xs"}`}
          >
            {t(item?.label)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Sort;
