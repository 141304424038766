const EmailIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 15.9375H5.25C2.5125 15.9375 0.9375 14.3625 0.9375 11.625V6.375C0.9375 3.6375 2.5125 2.0625 5.25 2.0625H12.75C15.4875 2.0625 17.0625 3.6375 17.0625 6.375V8.625C17.0625 8.9325 16.8075 9.1875 16.5 9.1875C16.1925 9.1875 15.9375 8.9325 15.9375 8.625V6.375C15.9375 4.23 14.895 3.1875 12.75 3.1875H5.25C3.105 3.1875 2.0625 4.23 2.0625 6.375V11.625C2.0625 13.77 3.105 14.8125 5.25 14.8125H9C9.3075 14.8125 9.5625 15.0675 9.5625 15.375C9.5625 15.6825 9.3075 15.9375 9 15.9375Z"
      fill="currentColor"
    />
    <path
      d="M8.99812 9.65247C8.36812 9.65247 7.73063 9.45747 7.24313 9.05997L4.89562 7.18497C4.65562 6.98997 4.61063 6.63748 4.80563 6.39748C5.00063 6.15748 5.35312 6.11998 5.59312 6.30748L7.94062 8.18248C8.51062 8.63998 9.47812 8.63998 10.0481 8.18248L12.3956 6.30748C12.6356 6.11248 12.9881 6.14998 13.1831 6.39748C13.3781 6.63748 13.3406 6.99747 13.0931 7.18497L10.7456 9.05997C10.2656 9.45747 9.62812 9.65247 8.99812 9.65247Z"
      fill="currentColor"
    />
    <path
      d="M11.8647 16.3347C11.5797 16.3347 11.3097 16.2297 11.1147 16.0347C10.8822 15.8022 10.7772 15.4647 10.8297 15.1122L10.9722 14.0997C11.0097 13.8372 11.1672 13.5222 11.3547 13.3347L14.0097 10.6797C14.3697 10.3197 14.7222 10.1322 15.1047 10.0947C15.5697 10.0497 16.0347 10.2447 16.4697 10.6797C16.9047 11.1147 17.0997 11.5722 17.0547 12.0447C17.0172 12.4197 16.8222 12.7797 16.4697 13.1397L13.8147 15.7947C13.6272 15.9822 13.3122 16.1397 13.0497 16.1772L12.0372 16.3197C11.9772 16.3272 11.9247 16.3347 11.8647 16.3347ZM15.2322 11.2122C15.2247 11.2122 15.2172 11.2122 15.2097 11.2122C15.1047 11.2197 14.9622 11.3172 14.8047 11.4747L12.1497 14.1297C12.1272 14.1522 12.0897 14.2272 12.0897 14.2572L11.9547 15.1947L12.8922 15.0597C12.9222 15.0522 12.9972 15.0147 13.0197 14.9922L15.6747 12.3372C15.8322 12.1722 15.9297 12.0372 15.9372 11.9322C15.9522 11.7822 15.8022 11.6022 15.6747 11.4747C15.5547 11.3547 15.3822 11.2122 15.2322 11.2122Z"
      fill="currentColor"
    />
    <path
      d="M15.6904 13.6872C15.6379 13.6872 15.5854 13.6797 15.5404 13.6647C14.5504 13.3872 13.7629 12.5997 13.4854 11.6097C13.4029 11.3097 13.5754 11.0022 13.8754 10.9122C14.1754 10.8297 14.4829 11.0022 14.5654 11.3022C14.7379 11.9172 15.2254 12.4047 15.8404 12.5772C16.1404 12.6597 16.3129 12.9747 16.2304 13.2747C16.1629 13.5222 15.9379 13.6872 15.6904 13.6872Z"
      fill="currentColor"
    />
  </svg>
);
export default EmailIcon;
