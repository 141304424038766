import axiosRequest from "@/utils/axios-config";

const getProductBySku = async (handshake, sku) => {
  const config = {
    method: "get",
    url: `/search/product/${sku}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getProductsBySkus = async (handshake, skuBulk, selectedAtteributes) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => "sku=" + sku).join("&")
    : "";

  const config = {
    method: "get",
    url: "/search/product?" + transformSKus,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => "select=" + select)
      .join("&");
    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getAmastyProducts = async (handshake, sku) => {
  const config = {
    method: "get",
    url: `/customer/amasty_mostviewed_related_products?sku=${sku}&limit=${5}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    console.log('bulk req', req)
    return req
  } catch (error) {
    return error;
  }
};

const getProductsReviews = async (handshake, sku, selectedAtteributes) => {
  const config = {
    method: "get",
    url: "/product/reviews/" + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => "select=" + select)
      .join("&");
    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getAvailableStores = async (handshake, sku) => {
  const config = {
    method: "get",
    url: "/mstore/store-locator-by-product/" + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export {
  getProductBySku,
  getProductsBySkus,
  getAmastyProducts,
  getProductsReviews,
  getAvailableStores,
};
