export function getCategory(data, targetId) {
  for (const item of data) {
    // Check if the current item has the target value
    if (item.id === targetId) {
      return item;
    }

    // Check if the current item has children, then recursively search in children
    if (item.children_data && item.children_data.length > 0) {
      const result = getCategory(item.children_data, targetId);
      if (result) {
        return result;
      }
    }
  }
  // If the target value is not found in the current level or its children, return null
  return null;
}

export const modifyMegaMenuTreeData = (array) => {
  return array?.filter(
    (cat) =>
      cat.is_active &&
      cat.include_in_megamenu &&
      // cat.include_in_menu === "1" &&
      cat.product_count > 0
  );
};

export const modifyTopThisWeekData = (array) => {
  return array?.filter(
    (cat) => cat.is_active && cat.top_this_week && cat.product_count > 0
  );
};

export const modifyMenuTreeData = (array) => {
  return (array || [])?.filter(
    (cat) => cat.is_active && cat.include_in_menu && cat.product_count > 0
  );
};

export function getCategoryData(categorytree, target, value) {
  if (categorytree !== undefined)
    for (const item of categorytree) {
      if (item[target] == value) {
        return item;
      }
      if (item.children_data && item.children_data.length > 0) {
        const result = getCategory(item.children_data, target, value);
        if (result) {
          return result;
        }
      }
    }
  // If the target value is not found in the current level or its children, return null
  return null;
}

export const getSubCategoriesData = (category, isMobile = false) => {
  return {
    active: true,
    value: category?.children_data?.map((subCategory) => {
      return {
        active: subCategory?.is_active,
        "category-url": subCategory?.url_key,
        id: subCategory?.id,
        // 'img-ar': isMobile ? "images/officechair.png" : "images/officechair.png",
        // 'img-en':  isMobile ? "images/officechair.png" : "images/officechair.png",
        "img-ar": isMobile
          ? subCategory?.category_mobile_image1
          : subCategory?.category_desktop_image1,
        "img-en": isMobile
          ? subCategory?.category_mobile_image1
          : subCategory?.category_desktop_image1,
        position: 1,
        "title-ar": subCategory?.name,
        "title-en": subCategory?.name,
      };
    }),
  };
};

export const isShowBannerEachNumOfRows = (
  numOfProductsOnRow,
  numOfRows,
  rowNum
) => {
  return rowNum % (numOfProductsOnRow * numOfRows) === 0;
};
