import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useRef, useState } from "react";

import Modal from ".";
import Album from "../product/Album";
import Price from "../product/Price";
import CloseIcon from "../icons/CloseIcon";
import ColorSizes from "../product/ColorSizes";
import ProductRates from "../product/ProductRates";
import UserActions from "../product/UserActions";
import SpecialQtyCart from "../product/SpecialQtyCart";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import ExtraAttributes from "../product/ExtraAttributes";
import useHandleAlbumDetails from "@/hooks/product/useHandleAlbumDetails";
import { useBodyScrollLock } from "@/hooks/global/useBodyScrollLock";
import { add_event_name } from "@/utils/gtm";

const ProductQuickView = ({ show, onHide, product, productQuickViewRef }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("product");
  const ref = useRef();
  const { push } = useRouter();
  const [qty, setQty] = useState(1);
  useHandleOutSide(ref, onHide);
  const {
    chosenConfigurableProduct,
    setSelectedIdx,
    handleOnClickConfigurable,
    selectedIdx,
    configurables,
    configuration,
    configIndex,
  } = useHandleAlbumDetails(product);
  useBodyScrollLock();

  const configurPrice = chosenConfigurableProduct?.prices_with_tax || null;

  return (
    <Modal show={show} productQuickViewRef={productQuickViewRef} isCenter>
      <div
        ref={ref}
        className="min-h-fit max-h-[95vh] overflow-y-auto p-3 bg-white w-11/12 relative red-scroll"
      >
        <div className="flex items-center justify-end mb-2 sm:mb-4">
          <CloseIcon
            className="cursor-pointer max-sm:hidden sm:size-6"
            onClick={onHide}
          />
        </div>
        <div className="grid grid-cols-1 sm:gap-4 md:grid-cols-2">
          <div className="max-sm:hidden">
            <Album
              {...{
                album: chosenConfigurableProduct
                  ? chosenConfigurableProduct?._media_?.image
                  : product?._media_?.image || [],
                setSelectedIdx,
              }}
            />
            <div className="flex items-center gap-12 my-3">
              <UserActions product={product} />
            </div>
          </div>
          <div className="*:mb-4 max-sm:*:mb-2">
            <h1 className="text-sm font-bold text-justify sm:text-lg">
              {product?.name?.at(0) || ""}
            </h1>
            <ExtraAttributes
              t={t}
              sku={
                chosenConfigurableProduct
                  ? chosenConfigurableProduct?.sku
                  : Array.isArray(product?.sku)
                  ? product?.sku?.at(0)
                  : product?.sku
              }
              model={product?.model}
              fromModal
            />
            <ProductRates
              fromModal
              reviews_count={
                product?.reviews_count > 0 ? product?.reviews_count : null
              }
            />
            <Price
              fromModal
              prices={configurPrice || product?.prices_with_tax}
              product_attributes={product?.product_attributes}
            />
            <ColorSizes
              {...{
                language,
                handleOnClickConfigurable,
                selectedIdx,
                configurables,
                fromModal: true,
              }}
            />
            <SpecialQtyCart
              {...{
                configurations: product?._configurables?.length
                  ? configuration
                  : null,
                product,
                fromModal: true,
                sku: Array.isArray(product?.sku)
                  ? product?.sku?.at(0)
                  : product?.sku,
                onHide,
                qty,
                setQty,
                configurPrice,
                chosenConfigurableProduct,
                configurables,
                configIndex,
              }}
            />
            <div className="flex items-center justify-center">
              <button
                onClick={() => {
                  add_event_name(product, "select_item");
                  push(`/product/${product?.rewrite_url}`);
                  onHide();
                }}
                className="font-bold text-center text-red-600 underline max-sm:text-xs"
              >
                {language === "en"
                  ? "See Product Detail"
                  : "رؤية تفاصيل المنتج"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductQuickView;
