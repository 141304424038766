const LogoutIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1616 14.8467H10.075C7.11497 14.8467 5.6883 13.68 5.44164 11.0667C5.41497 10.7933 5.61497 10.5467 5.89497 10.52C6.1683 10.4933 6.41497 10.7 6.44164 10.9733C6.63497 13.0667 7.62164 13.8467 10.0816 13.8467H10.1683C12.8816 13.8467 13.8416 12.8867 13.8416 10.1733V5.82665C13.8416 3.11332 12.8816 2.15332 10.1683 2.15332H10.0816C7.6083 2.15332 6.62164 2.94665 6.44164 5.07999C6.4083 5.35332 6.18164 5.55999 5.89497 5.53332C5.61497 5.51332 5.41497 5.26665 5.43497 4.99332C5.66164 2.33999 7.09497 1.15332 10.075 1.15332H10.1616C13.435 1.15332 14.835 2.55332 14.835 5.82665V10.1733C14.835 13.4467 13.435 14.8467 10.1616 14.8467Z"
      fill="currentColor"
    />
    <path
      d="M9.9187 8.5H1.33203C1.0587 8.5 0.832031 8.27333 0.832031 8C0.832031 7.72667 1.0587 7.5 1.33203 7.5H9.9187C10.192 7.5 10.4187 7.72667 10.4187 8C10.4187 8.27333 10.1987 8.5 9.9187 8.5Z"
      fill="currentColor"
    />
    <path
      d="M8.43193 10.7331C8.30526 10.7331 8.17859 10.6864 8.07859 10.5864C7.88526 10.3931 7.88526 10.0731 8.07859 9.87973L9.95859 7.99973L8.07859 6.11973C7.88526 5.9264 7.88526 5.6064 8.07859 5.41307C8.27193 5.21973 8.59193 5.21973 8.78526 5.41307L11.0186 7.6464C11.2119 7.83973 11.2119 8.15973 11.0186 8.35307L8.78526 10.5864C8.68526 10.6864 8.55859 10.7331 8.43193 10.7331Z"
      fill="currentColor"
    />
  </svg>
);
export default LogoutIcon;
