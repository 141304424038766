const Twitter = (props) => (
  <svg
    width={14}
    height={12}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5667 2.98737C12.5753 3.11756 12.5753 3.24775 12.5753 3.37914C12.5753 7.38269 9.68596 12 4.40276 12V11.9976C2.84209 12 1.31383 11.5284 0 10.6393C0.226935 10.6681 0.455007 10.6825 0.683648 10.6831C1.97701 10.6843 3.23339 10.2265 4.2509 9.38356C3.02182 9.35897 1.94402 8.51362 1.5675 7.2795C1.99805 7.36709 2.44168 7.34909 2.86427 7.2273C1.52427 6.94172 0.560228 5.6998 0.560228 4.25749V4.21909C0.959496 4.45368 1.40654 4.58387 1.86382 4.59827C0.601747 3.70852 0.212716 1.93744 0.974853 0.552723C2.43315 2.4456 4.58477 3.59633 6.8945 3.71812C6.66301 2.66579 6.97924 1.56306 7.72545 0.823306C8.88231 -0.323822 10.7018 -0.265026 11.7892 0.954697C12.4325 0.820906 13.049 0.571921 13.6132 0.219144C13.3988 0.920499 12.9501 1.51626 12.3506 1.89484C12.9199 1.82404 13.4762 1.66325 14 1.41787C13.6144 2.02743 13.1287 2.5584 12.5667 2.98737Z"
      fill="currentColor"
    />
  </svg>
);
export default Twitter;
