import { object, ref, string } from "yup";

export const loginValidation = (t, isMobile) =>
  object().shape({
    username: isMobile
      ? string()
      : string().email(t("invalidEmail")).required(t("emailRequired")),
    mobilenumber: isMobile
      ? string()
          .label("Phone number")
          .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
          .matches(/^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
          .min(9)
          .max(9)
          .required(t("TelephoneRequired"))
      : string(),
    password: string().required(t("PasswordRequired")),
  });

export const registerValidation = (t) =>
  object().shape({
    firstname: string().required(t("FirstNameRequired")),
    lastname: string().required(t("LastNameRequired")),
    email: string().email(t("invalidEmail")).required(t("emailRequired")),
    password: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("password"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
    terms: string().required(t("YouMustAcceptTerms")),
    mobile: string()
      .label("Phone number")
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(9, t("ErrorMinLength"))
      .required(t("TelephoneRequired")),
  });

export const forgotValidation = (t) =>
  object().shape({
    email: string().required(t("emailIsRequired")).email(t("emailError")),
  });

export const changePasswordValidation = (t) =>
  object().shape({
    password: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("password"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
  });

export const otpValidation = (t) =>
  object().shape({
    otp: string().required(t("Otp is required")),
  });
