import ArrowIcon from "@/components/icons/ArrowIcon";
import DiscountIcon from "@/components/icons/DiscountIcon";
import { useGlobalData } from "@/context/global";
import Link from "next/link";
// import { useState } from "react";

export default function TopThisWeek({ t }) {
  const {
    appData: { topThisWeekData },
  } = useGlobalData();

  // const [menuInView, setmenuInView] = useState(null)
  return (
    <div className="flex items-center gap-5 py-1.5 cursor-pointer group/topmenu">
      <div className="flex items-center gap-3">
        <DiscountIcon className="size-6" />
        <div className="flex flex-col">
          <span className="text-xs font-normal">{t("This Week")}</span>
          <span className="text-sm font-bold">{t("Offers and Sale")}</span>
        </div>
      </div>
      <ArrowIcon />
      <ul className="space-y-1 hidden group-hover/topmenu:block absolute top-full z-[999] bg-white w-52">
        {topThisWeekData?.map((cat, i) => (
          <li key={i}>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex items-center justify-between px-4 py-2 text-gray-500 rounded-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700">
                <span className="flex items-center justify-center h-12 text-sm font-medium">
                  {" "}
                  {cat?.name}{" "}
                </span>

                <span className="transition duration-300 shrink-0 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <ul className="px-4 mt-2 space-y-1">
                {cat?.children_data.length > 0 &&
                  cat?.children_data.map((c, i) => (
                    <li key={i}>
                      <Link
                        href={`/${c?.url_key}`}
                        className="block px-4 py-2 text-sm font-medium text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        {c.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </details>
          </li>
        ))}
      </ul>
    </div>
    // <div className="flex items-center gap-5 py-1 cursor-pointer group/topmenu">
    //   <div className="flex items-center gap-3">
    //     <DiscountIcon className="size-9" />
    //     <div className="flex flex-col">
    //       <span className="text-[15px]">{t("This Week")}</span>
    //       <span className="text-[15px] font-extrabold">
    //         {t("Offers and Sale")}
    //       </span>
    //     </div>
    //   </div>
    //   <ArrowIcon />
    //   <div
    //     className="hidden group-hover/topmenu:block absolute top-[100%] bg-white text-gray-700 w-52">
    //     <ul className="relative flex flex-col">
    //       {topThisWeekData?.map((cat, i)=>(
    //         <li
    //         onMouseEnter={()=>setmenuInView(i)}
    //         onMouseLeave={()=>{
    //           setTimeout(() => {
    //             setmenuInView(null)
    //           }, 2000);
    //         }}
    //         key={i} className="px-4 py-4 hover:bg-gray-100" >
    //           <p>{cat?.name}</p>
    //           {menuInView == i && <ul>
    //             {cat?.children_data.length > 0 && cat?.children_data.map((c, i)=> (<li key={i} className="py-4 -top-[10px] hover:font-bold">
    //               {c.name}
    //               </li>))}
    //           </ul>}
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>
  );
}
