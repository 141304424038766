import { getProductsByCatId } from "@/services/category";
import { productsSearch } from "@/services/search";
import { getCookie } from "cookies-next";
import { useCallback, useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";

const useFilterProducts = ({
  setAllFilterKeys,
  setAllProducts,
  allSortingKeys,
  categoryID,
  filters,
  products,
  fromCategory = true,
  word = "",
  setPageIndex,
}) => {
  const [selectedFilterKeys, setSelectedFilterKeys] = useState([]);
  const [allFilters, setAllFilters] = useState(filters);
  const handshake = getCookie("handshake");

  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}`
      : `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${selectedFilterKeys?.join(
          "&"
        )}`,
    async (_, { arg }) => {
      const { pageNo, pageSize, categoryID, filterKeys, sortKeys } = arg;
      const { data: productsData, error: productsError } = fromCategory
        ? await getProductsByCatId(
            handshake,
            categoryID,
            sortKeys,
            filterKeys,
            pageSize,
            pageNo
          )
        : await productsSearch(
            handshake,
            word,
            pageSize,
            pageNo,
            sortKeys,
            filterKeys
          );

      let handleProducts = {
        products: productsData?.data?.products,
        aggregate: productsData?.data?.aggregate,
        pages: productsData?.data?.pages,
        pageParam: +pageNo,
        error: productsError,
      };
      return handleProducts;
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  useEffect(() => {
    setAllFilters(filters);
  }, [filters]);

  useEffect(() => {
    setSelectedFilterKeys([]);
    setAllFilterKeys("");
    return () => {
      setSelectedFilterKeys([]);
      setAllFilterKeys("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(
    async ({ code, item }) => {
      let filterKeys = "";
      if (selectedFilterKeys?.includes(`${code}=${item?.Key}`)) {
        const updatedSelectedFilterKeys = selectedFilterKeys?.filter(
          (itemF) => itemF != `${code}=${item?.Key}`
        );
        setSelectedFilterKeys(updatedSelectedFilterKeys);
        filterKeys = `${updatedSelectedFilterKeys?.join("&")}`;
      } else {
        setSelectedFilterKeys((prev) => [...prev, `${code}=${item?.Key}`]);
        filterKeys = `${selectedFilterKeys?.join("&")}&${code}=${item?.Key}`;
      }

      setAllFilterKeys(filterKeys);
      setPageIndex(1);
      let result = await res?.trigger(
        fromCategory
          ? {
              pageNo: 0,
              pageSize: 30,
              categoryID,
              filterKeys,
            }
          : {
              pageNo: 0,
              pageSize: 30,
              sortKeys: allSortingKeys,
              word,
              filterKeys,
            }
      );

      result?.products?.length &&
        result?.products != undefined &&
        setAllProducts(result?.products);
      // result?.aggregate?.length && result?.aggregate != undefined && setAllFilters(result?.aggregate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryID, res, selectedFilterKeys, setAllFilterKeys, setAllProducts]
  );

  const handleResetFilter = () => {
    setSelectedFilterKeys([]);
    setAllProducts(products);
  };

  return {
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    setAllFilters,
    allFilters,
  };
};

export default useFilterProducts;
