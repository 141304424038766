const { useReducer, useEffect, createContext } = require("react");
import store from "store";
import { appReducer } from "./reducers";
export const AppDataContext = createContext();

export const AppProvider = ({ children }) => {
  const savedSData = (Object.keys(store.get("app_data") || {})?.length ? store.get("app_data") : null) || {
    categoryData: {},
    menuTreeData: [],
    megaMenuTreeData: [],
    topThisWeekData:[],
    regionId: null,
  };
  const [appData, dispatch] = useReducer(appReducer, { ...savedSData });

  useEffect(() => {
    if (Object.keys(appData || {}).length > 0) {
      store.set("app_data", appData);
    }
  }, [appData]);

  return (
    <AppDataContext.Provider value={{ appData, dispatch }}>
      {children}
    </AppDataContext.Provider>
  );
};
