export const initialState = {
  modal: false,
  cartInfo: false,
  auth: false,
  otp: false,
  forgot: false,
  filter: false,
  sortBy: false,
  editAddresses: false,
  editAddress: false,
  addNewAddress: false,
  stcPayOtp: false,
  sidemenu: false,
  trackOrder: false,
  videoModal: false,
  videoPayload: "",
  cartPayload: "",
  relatedProducts: [],
  productInfo: {},
};

export const modalReducer = (state, action) => {
  switch (action.type) {
    case "cartInfo":
      return {
        ...state,
        modal          : !state?.modal,
        cartInfo       : !state?.cartInfo,
        cartPayload    : action?.payload,
        relatedProducts: action?.payloadRelatedProducts || [],
        productInfo    : action?.productInfo,
      };
    case "sidemenu":
      return {
        ...initialState,
        sidemenu: !state.sidemenu,
      };
    case "auth":
      return {
        ...initialState,
        modal: !state.modal,
        auth: !state.auth,
      };
    case "otp":
      return {
        ...initialState,
        modal: !state.modal,
        otp: !state.otp,
      };
    case "videoModal":
      return {
        ...initialState,
        modal: !state.modal,
        videoModal: !state.videoModal,
        videoPayload: action.payload,
      };
    case "forgot":
      return {
        ...initialState,
        modal: !state.modal,
        forgot: !state.forgot,
      };
    case "filter":
      return {
        ...initialState,
        modal: !state.modal,
        filter: !state.filter,
      };
    case "sortBy":
      return {
        ...initialState,
        modal: !state.modal,
        sortBy: !state.sortBy,
      };
    case "editAddresses":
      return {
        ...initialState,
        modal: !state.modal,
        editAddresses: !state.editAddresses,
      };
    case "addNewAddress":
      return {
        ...initialState,
        modal: !state.modal,
        addNewAddress: !state.addNewAddress,
      };
    case "editAddress":
      return {
        ...initialState,
        modal: !state.modal,
        editAddress: !state.editAddress,
      };
    case "stcPayOtp":
      return {
        ...initialState,
        modal: !state.modal,
        stcPayOtp: !state.stcPayOtp,
      };
    case "trackOrder":
      return {
        ...initialState,
        modal: !state.modal,
        trackOrder: !state.trackOrder,
      };
  }
};
