const ShareIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9998 7.5C13.9998 11.088 11.0878 14 7.49976 14C3.91176 14 1.72126 10.386 1.72126 10.386M1.72126 10.386H4.65926M1.72126 10.386V13.636M0.999756 7.5C0.999756 3.912 3.88576 1 7.49976 1C11.8353 1 13.9998 4.614 13.9998 4.614M13.9998 4.614V1.364M13.9998 4.614H11.1138"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShareIcon;
