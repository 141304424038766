import Categories from "./filters/Categories";
// import Image from "@/components/global/Image";

const Side = ({
  isModal,
  setFilterModal,
  t,
  filterList,
  selectedFilterKeys,
  handleFilter,
}) => {
  return (
    <>
      {filterList?.map((filterSection, key) => {
        const title = filterSection?.label || "";
        const options = filterSection?.filterable_values || [];
        const code = filterSection?.filter_key || "";
        return options?.length ? (
          <div
            key={key}
            className={`${isModal ? "border-b pb-3" : "p-3 border"} rounded-md`}
          >
            <h2 className="mb-2 text-lg font-semibold capitalize">
              {t(title)}
            </h2>
            <Categories
              {...{ options, selectedFilterKeys, code, handleFilter, isModal }}
            />
          </div>
        ) : null;
      })}
      {!isModal ? (
        // <Image
        //   loading="eager"
        //   src="/imgs/category/summer-special.png"
        //   className="object-cover w-full h-auto"
        //   alt="Summer Special Img!"
        // />
        <></>
      ) : (
        <div className="grid grid-cols-2 gap-2 mt-2 fixed bottom-0 start-1 w-[72%] bg-white py-1.5 mx-auto">
          <button
            onClick={() => setFilterModal(false)}
            className="p-1.5 max-sm:p-1 max-sm:text-sm rounded-md text-white bg-red-600"
          >
            {t("Filter")}
          </button>
          <button
            onClick={() => setFilterModal(false)}
            className="p-1.5 max-sm:p-1 max-sm:text-sm rounded-md text-red-600 border border-red-600"
          >
            {t("Cancel")}
          </button>
        </div>
      )}
    </>
  );
};

export default Side;
