import { useEffect, useRef } from "react";

const useClickOutside = (refs, callback) => {
    const callbackRef = useRef();

    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          refs.every((ref) => ref.current && !ref.current.contains(event.target))
        ) {
          callbackRef.current(event);
        }
      };
  
      document.addEventListener("click", handleClickOutside);
  
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [refs]);
};

export default useClickOutside;