import { deleteCartItem, updateCartItem } from "@/services/cart";
import { event_toggle_cart } from "@/utils/gtm";
import { getCookies, setCookie } from "cookies-next";
import { useTranslation } from "next-i18next";
import { useCart } from "@/context/cart";
import { useRouter } from "next/router";
import { useState } from "react";

import toast from "react-hot-toast";
import Remove from "../icons/Remove";
import AddIcon from "../icons/AddIcon";
import MinusIcon from "../icons/MinusIcon";

const ItemCartCounter = ({ product, refetchCart }) => {
  const [qty, setQty] = useState(product?.qty || 1);
  const { handshake, mage, quote } = getCookies();
  const [loadingBtn, setLoadingBtn] = useState("");
  const { dispatch: dispatchCart } = useCart();
  const { t } = useTranslation("common");
  const { locale } = useRouter();

  const handleDeleteCartItem = async () => {
    setLoadingBtn("deleting");
    const res = await deleteCartItem(handshake, mage, quote, product?.item_id);
    if (res?.status === 404) {
      toast.success(
        locale === "en" ? "Item Deleted From Cart!" : "تم مسح المنتج من العربة"
      );
      dispatchCart({
        type: "remove",
        payload: { item_id: product?.item_id || product?.data?.item_id },
      });
      setLoadingBtn("");
      return;
    }

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
      setLoadingBtn("");
      toast.success(
        locale === "en"
          ? "Items Deleted From Cart!"
          : "تم مسح المنجات من العربة"
      );
      return;
    }

    if (res?.status === 200) {
      refetchCart && (await refetchCart());
      toast.success(
        locale === "en" ? "Item Deleted From Cart!" : "تم مسح المنتج من العربة"
      );
      dispatchCart({
        type: "remove",
        payload: { item_id: product?.item_id || product?.data?.item_id },
      });
      event_toggle_cart(product, "remove_from_cart", 1);
      setLoadingBtn("");
    } else {
      toast.error(
        locale === "en"
          ? "Error Occured, please try again"
          : "حدث خطأ, برجاء المحاولة مره اخرى"
      );
      setLoadingBtn("");
    }
  };

  const handleUpdateItem = async (qty, isMinus) => {
    !isMinus ? setLoadingBtn("adding") : setLoadingBtn("subtracting");

    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      product?.item_id,
      qty
    );
    if (res?.status === 404) {
      dispatchCart({
        type: "remove",
        payload: { item_id: product?.item_id || product?.data?.item_id },
      });
    }

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
    }

    if (res?.status === 200) {
      refetchCart && (await refetchCart());
      toast.success(
        locale === "en"
          ? "Item Updated Successfully"
          : "تم تعديل كمية المنتج بنجاح"
      );
      dispatchCart({
        type: "update",
        payload: { item_id: product?.item_id || product?.data?.item_id, qty },
      });
      setLoadingBtn("");
      if (!isMinus) {
        setQty((prev) => prev + 1);
        event_toggle_cart(product, "add_to_cart", qty);
      } else {
        setQty((prev) => prev - 1);
        event_toggle_cart(product, "remove_from_cart", qty);
      }
    } else {
      toast.error(
        res?.error?.error?.message
          ? t(res?.error?.error?.message)
          : locale === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء المحاولة مره اخرى"
      );
      if (isMinus) {
        await handleDeleteCartItem();
      }
      setLoadingBtn("");
    }
  };

  return (
    <div className="flex items-center">
      <button
        onClick={() => handleUpdateItem(qty ? qty + 1 : 2)}
        className="flex items-center justify-center border rounded-md size-6 sm:size-8 disabled:cursor-not-allowed"
        disabled={loadingBtn === "adding"}
      >
        {loadingBtn === "adding" ? (
          <span className="border-t border-r border-black rounded-full animate-spin size-4" />
        ) : (
          <AddIcon className="size-2.5 sm:size-3" />
        )}
      </button>
      <span className="flex items-center justify-center w-fit px-1.5 sm:w-12 h-8 max-sm:text-sm">
        {qty}
      </span>
      <button
        onClick={
          qty === 1
            ? handleDeleteCartItem
            : () => handleUpdateItem(qty - 1, true)
        }
        className="flex items-center justify-center border rounded-md size-6 sm:size-8"
        disabled={loadingBtn === "deleting" || loadingBtn === "subtracting"}
      >
        {qty === 1 ? (
          loadingBtn === "deleting" ? (
            <span className="border-t border-r border-black rounded-full animate-spin size-4" />
          ) : (
            <Remove className="max-sm:size-4" />
          )
        ) : qty !== 1 ? (
          loadingBtn === "subtracting" ? (
            <span className="border-t border-r border-black rounded-full animate-spin size-4" />
          ) : (
            <MinusIcon className="size-2.5 sm:size-3" />
          )
        ) : (
          ""
        )}
      </button>
    </div>
  );
};

export default ItemCartCounter;
