import Modal from ".";
import Otp from "../forms/auth/Otp";
import Login from "../forms/auth/Login";
import Forgot from "../forms/auth/Forgot";
import CloseIcon from "../icons/CloseIcon";
import Register from "../forms/auth/Register";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import ChangePassword from "../forms/auth/ChangePassword";

import { useRef, useState } from "react";

const AuthModal = ({ auth, setAuth, t, locale }) => {
  const ref = useRef();
  const [verifyOtp, setVerifyOtp] = useState(false);
  useHandleOutSide(ref, () => setAuth(false))
  return (
    <Modal isCenter show={auth}>
      <div ref={ref} className="w-11/12 mx-auto sm:w-[640px] p-3 bg-white rounded-md max-h-[95vh] overflow-y-auto red-scroll">
        <div className="flex items-center justify-between pb-2 border-b">
          <h2 className="font-bold sm:text-lg">
            {t(
              auth === "login"
                ? "Login"
                : auth === "register"
                  ? "Register"
                  : auth === "forgot"
                    ? "Forgot Password"
                    : auth === "otp"
                      ? "Otp"
                      : "Change Password"
            )}
          </h2>
          <button onClick={() => setAuth(false)}>
            <CloseIcon className="size-5" />
          </button>
        </div>
        {auth === "login" || auth === "register" ? (
          <div className="grid grid-cols-2 my-2">
            <button
              onClick={() => setAuth("login")}
              className={`w-full border p-2 font-semibold ${
                auth === "login"
                  ? "border-red-600 text-white bg-red-600"
                  : "text-zinc-600"
              } max-sm:text-sm`}
            >
              {t("Login")}
            </button>
            <button
              onClick={() => setAuth("register")}
              className={`w-full border p-2 font-semibold ${
                auth === "register"
                  ? "border-red-600 text-white bg-red-600"
                  : "text-zinc-600"
              } max-sm:text-sm`}
            >
              {t("Register")}
            </button>
          </div>
        ) : null}
        {auth === "login" ? (
          <Login {...{ t, setAuth, locale }} />
        ) : auth === "register" ? (
          <Register {...{ t, setAuth, locale, verifyOtp, setVerifyOtp }} />
        ) : auth === "forgot" ? (
          <Forgot {...{ t, setAuth }} />
        ) : auth === "otp" ? (
          <Otp {...{ t, setAuth }} />
        ) : auth === "changePassword" ? (
          <ChangePassword {...{ t, setAuth, locale }} />
        ) : null}
      </div>
    </Modal>
  );
};

export default AuthModal;
