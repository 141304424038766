import axios from "axios";
const axiosRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASEURL,
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosRequest.interceptors.response.use(
  (res) => {
    return Promise.resolve({ data: res.data, error: null, status: res.status });
  },
  (error) => {
    if (error.response) {
      // Handle HTTP errors
      console.log("=====> http error", error);

      return Promise.reject({
        data: null,
        error: error.response?.data,
        status: error.response.status,
      });
    } else if (error.request) {
      // Handle network errors
      console.log("=====> network error", error);
      return Promise.reject({
        data: null,
        error: error.request?.data,
        status: error.request.status,
      });
    } else {
      // Handle other errors
      console.log("=====> error", error);
      return Promise.reject({
        data: null,
        error: error,
        status: 500,
      });
    }
  }
);

export default axiosRequest;
