export const sortKeys = [
  {
    key: "sortBy=most viewed&sortDir=desc",
    label: "Most Views",
  },
  {
    key: "sortBy=price&sortDir=asc",
    label: "Price Low To High",
  },
  {
    key: "sortBy=price&sortDir=desc",
    label: "Price High To Low",
  },
  // {
  // 	key: 'sortBy=created_at&sortDir=DESC',
  // 	label: 'latest',
  // },
];
