import Portal from "../global/Portal";
import { Cairo } from "next/font/google";

const cairoFont = Cairo({
  weight: ["200", "300", "400", "500", "600", "700", "800", "900"],
  style: ["normal"],
  display: "swap",
  subsets: ["arabic"],
  variable: "--font-cairo",
});

const Modal = ({ children, show, isCenter, productQuickViewRef = null }) => {
  return show ? (
    <Portal>
      <div
        ref={productQuickViewRef}
        className={`fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-60 z-[1000] ${
          cairoFont.variable
        } font-sans ${isCenter ? "flex items-center justify-center" : ""}`}
      >
        {children}
      </div>
    </Portal>
  ) : null;
};

export default Modal;
