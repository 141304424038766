const DiscountIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22.7498C11.37 22.7498 10.78 22.5099 10.34 22.0599L8.82001 20.5399C8.70001 20.4199 8.38 20.2899 8.22 20.2899H6.06C4.76 20.2899 3.70999 19.2398 3.70999 17.9398V15.7799C3.70999 15.6199 3.57999 15.2999 3.45999 15.1799L1.94 13.6599C1.5 13.2199 1.25 12.6298 1.25 11.9998C1.25 11.3698 1.49 10.7798 1.94 10.3398L3.45999 8.81979C3.57999 8.69979 3.70999 8.37981 3.70999 8.21981V6.0599C3.70999 4.7599 4.76 3.7098 6.06 3.7098H8.22C8.38 3.7098 8.70001 3.5798 8.82001 3.4598L10.34 1.93979C11.22 1.05979 12.78 1.05979 13.66 1.93979L15.18 3.4598C15.3 3.5798 15.62 3.7098 15.78 3.7098H17.94C19.24 3.7098 20.29 4.7599 20.29 6.0599V8.21981C20.29 8.37981 20.42 8.69979 20.54 8.81979L22.06 10.3398C22.5 10.7798 22.75 11.3698 22.75 11.9998C22.75 12.6298 22.51 13.2199 22.06 13.6599L20.54 15.1799C20.42 15.2999 20.29 15.6199 20.29 15.7799V17.9398C20.29 19.2398 19.24 20.2899 17.94 20.2899H15.78C15.62 20.2899 15.3 20.4199 15.18 20.5399L13.66 22.0599C13.22 22.5099 12.63 22.7498 12 22.7498ZM4.51999 14.1198C4.91999 14.5198 5.20999 15.2199 5.20999 15.7799V17.9398C5.20999 18.4098 5.59 18.7899 6.06 18.7899H8.22C8.78 18.7899 9.48001 19.0798 9.88 19.4798L11.4 20.9998C11.72 21.3198 12.28 21.3198 12.6 20.9998L14.12 19.4798C14.52 19.0798 15.22 18.7899 15.78 18.7899H17.94C18.41 18.7899 18.79 18.4098 18.79 17.9398V15.7799C18.79 15.2199 19.08 14.5198 19.48 14.1198L21 12.5998C21.16 12.4398 21.25 12.2298 21.25 11.9998C21.25 11.7698 21.16 11.5599 21 11.3999L19.48 9.87985C19.08 9.47985 18.79 8.77981 18.79 8.21981V6.0599C18.79 5.5899 18.41 5.2098 17.94 5.2098H15.78C15.22 5.2098 14.52 4.91986 14.12 4.51986L12.6 2.99984C12.28 2.67984 11.72 2.67984 11.4 2.99984L9.88 4.51986C9.48001 4.91986 8.78 5.2098 8.22 5.2098H6.06C5.59 5.2098 5.20999 5.5899 5.20999 6.0599V8.21981C5.20999 8.77981 4.91999 9.47985 4.51999 9.87985L3 11.3999C2.84 11.5599 2.75 11.7698 2.75 11.9998C2.75 12.2298 2.84 12.4398 3 12.5998L4.51999 14.1198Z"
      fill="currentColor"
    />
    <path
      d="M15.0022 16C14.4422 16 13.9922 15.55 13.9922 15C13.9922 14.45 14.4422 14 14.9922 14C15.5422 14 15.9922 14.45 15.9922 15C15.9922 15.55 15.5522 16 15.0022 16Z"
      fill="currentColor"
    />
    <path
      d="M9.01001 10C8.45001 10 8 9.55 8 9C8 8.45 8.45 8 9 8C9.55 8 10 8.45 10 9C10 9.55 9.56001 10 9.01001 10Z"
      fill="currentColor"
    />
    <path
      d="M9.00141 15.75C8.81141 15.75 8.62141 15.68 8.47141 15.53C8.18141 15.24 8.18141 14.7599 8.47141 14.4699L14.4714 8.46994C14.7614 8.17994 15.2414 8.17994 15.5314 8.46994C15.8214 8.75994 15.8214 9.24 15.5314 9.53L9.5314 15.53C9.3814 15.68 9.1914 15.75 9.00141 15.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DiscountIcon;
