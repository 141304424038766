import Heart from "@/components/icons/Heart";
import { useRouter } from "next/router";
import { handleWishList } from "@/utils/wishlist";
import { useWishlist } from "@/context/wishlist";
import FillHeart from "@/components/icons/FillHeart";
import { add_event_name } from "@/utils/gtm";

const AddtoWishlist = ({ fromPDP, product }) => {
  const { locale } = useRouter();
  const { wishlist, dispatch } = useWishlist();
  return !fromPDP ? (
    <button
      onClick={() => {
        product?.entity_id in wishlist
          ? null
          : add_event_name(product, "add_to_wishlist");
        handleWishList(wishlist, product, dispatch);
      }}
      className={`flex items-center justify-center bg-white border-none rounded-md size-7 sm:size-8`}
    >
      {product?.entity_id in wishlist ? (
        <FillHeart className="size-3.5 sm:size-4 text-red-600" />
      ) : (
        <Heart className="size-3.5 sm:size-4 max-sm:text-[#A5A5A5]" />
      )}
    </button>
  ) : (
    <button
      className="flex items-center gap-1.5 mt-[18px] sm:mt-2"
      onClick={() => {
        product?.entity_id in wishlist
          ? null
          : add_event_name(product, "add_to_wishlist");
        handleWishList(wishlist, product, dispatch);
      }}
    >
      {product?.entity_id in wishlist ? (
        <>
          <FillHeart className="size-5" />
          <span className="font-semibold max-sm:text-sm">
            {locale === "en" ? "Wishlists" : "المفضلة"}
          </span>
        </>
      ) : (
        <>
          <Heart className="size-5" />
          <span className="font-semibold max-sm:text-sm">
            {locale === "en" ? "Wishlists" : "المفضلة"}
          </span>
        </>
      )}
    </button>
  );
};

export default AddtoWishlist;
