import Link from "next/link";
import Twitter from "@/components/icons/Twitter";
import FaceBook from "@/components/icons/Facebook";
import Instagram from "@/components/icons/Instagram";
import WhatsAppIcon from "@/components/icons/WhatsAppIcon";

const FooterSocials = ({ t }) => {
  return (
    <section>
      <span className="block mb-3 sm:mb-5 text-sm max-sm:text-xs font-bold text-center lg:text-start">
        {t("Follow Us")}
      </span>
      <div className="flex items-center justify-center gap-7 sm:gap-5 lg:justify-start">
        <Link target="_blank" href="https://www.facebook.com/Backcomfort">
          <FaceBook className="size-5" />
        </Link>
        <Link target="_blank" href="https://www.instagram.com/backcomfort">
          <Instagram className="size-5" />
        </Link>
        <Link
          target="_blank"
          href="https://api.whatsapp.com/send?phone=966112228227"
        >
          <WhatsAppIcon className="size-5" />
        </Link>
        <Link target="_blank" href="https://twitter.com/BackComfortSA">
          <Twitter className="size-5" />
        </Link>
      </div>
    </section>
  );
};

export default FooterSocials;
