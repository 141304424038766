const { createContext, useState, useEffect } = require("react");
import store from "store";

const CompareContext = createContext({
  compareProducts: [],
  addProductToCompare: () => {},
  removeProductFromCompare: () => {},
  clearCompare: () => {},
});

export const CompareProvider = ({ children }) => {
  const [compareProducts, setCompareProducts] = useState(
    store.get("compare_products") || []
  );

  useEffect(() => {
    store.set("compare_products", compareProducts);
  }, [compareProducts]);

  const addProductToCompare = (product) => {
    const existingProduct = compareProducts.find(
      (compareProduct) => compareProduct.sku === product.sku
    );

    setCompareProducts((prev) => {
      return existingProduct
        ? prev.filter((item) => item.sku !== product.sku)
        : [...prev, product];
    });
  };

  const removeProductFromCompare = (product) => {
    setCompareProducts((prev) => {
      return prev.filter(
        (compareProduct) => compareProduct.sku !== product.sku
      );
    });
  };

  const clearCompare = () => {
    setCompareProducts([]);
  };
  return (
    <CompareContext.Provider
      value={{
        compareProducts,
        addProductToCompare,
        removeProductFromCompare,
        clearCompare,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
};

export default CompareContext;
