import Image from "@/components/global/Image";

const DownloadApps = ({ t }) => {
  return (
    <section>
      <span className="block mb-3 text-sm font-bold text-center sm:mb-5 max-sm:text-xs lg:text-start">
        {t("Download App")}
      </span>
      <div className="flex items-center justify-center gap-5 lg:justify-start">
        <Image
          src="/imgs/footer/google-play.png"
          className="object-contain w-24 h-10 sm:w-28"
          alt="Google Play"
        />
        <Image
          src="/imgs/footer/apple-store.png"
          className="object-contain w-24 h-10 sm:w-28"
          alt="Apple Store"
        />
      </div>
    </section>
  );
};

export default DownloadApps;
