const FillHeart = (props) => (
  <svg
    width={15}
    height={12}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.90452 12C7.70215 12 7.5063 11.9751 7.3431 11.9192C4.84933 11.1047 0.886719 8.21347 0.886719 3.94197C0.886719 1.7658 2.7342 0 5.006 0C6.10927 0 7.14072 0.410363 7.90452 1.14404C8.66832 0.410363 9.69977 0 10.803 0C13.0748 0 14.9223 1.77202 14.9223 3.94197C14.9223 8.21969 10.9597 11.1047 8.46594 11.9192C8.30274 11.9751 8.10689 12 7.90452 12ZM5.006 0.932643C3.27604 0.932643 1.86595 2.28187 1.86595 3.94197C1.86595 8.1886 6.15497 10.5513 7.66298 11.0425C7.78049 11.0798 8.03508 11.0798 8.15259 11.0425C9.65407 10.5513 13.9496 8.19482 13.9496 3.94197C13.9496 2.28187 12.5395 0.932643 10.8096 0.932643C9.81728 0.932643 8.8968 1.37409 8.30274 2.13886C8.11995 2.37513 7.70215 2.37513 7.51936 2.13886C6.91224 1.36788 5.99829 0.932643 5.006 0.932643Z"
      fill="currentColor"
    />
    <path
      d="M1.96685 2.50039C0.887184 3.00039 1.96685 5.50039 2.50668 7.00039C2.93853 8.20036 5.92556 10.5003 7.36515 11.5004C12.7635 11.0004 13.843 4.50037 13.843 4.00037C12.7633 -0.499634 8.98449 1.50037 7.90483 2.50039C4.66585 -0.499613 3.04651 2.00039 1.96685 2.50039Z"
      fill="currentColor"
    />
    <path
      d="M7.36515 11.5004C7.36512 11.5004 7.36519 11.5004 7.36515 11.5004ZM7.36515 11.5004C5.92556 10.5003 2.93853 8.20036 2.50668 7.00039C1.96685 5.50039 0.887184 3.00039 1.96685 2.50039C3.04651 2.00039 4.66585 -0.499613 7.90483 2.50039C8.98449 1.50037 12.7633 -0.499634 13.843 4.00037C13.843 4.50037 12.7635 11.0004 7.36515 11.5004Z"
      stroke="currentColor"
      strokeWidth={0.7}
    />
  </svg>
);
export default FillHeart;
