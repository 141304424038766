export const StaticLinks = [
  {
    name: "Privacy Policy",
    url_key: "static/privacy",
  },
  {
    name: "Terms & Conditions",
    url_key: "static/terms-conditions",
  },
  {
    name: "Delivery Terms",
    url_key: "static/delivery-terms",
  },
  {
    name: "Request for Quotation",
    url_key: "static/request-for-quotation",
  },
  {
    name: "Maintenance Request",
    url_key: "static/maintenance-request",
  },
  {
    name: "Send a complaint",
    url_key: "static/complain-request",
  },
  {
    name: "Careers",
    url_key: "static/careers-request",
  },
  {
    name: "Faq",
    url_key: "static/faq",
  },
  {
    name: "Better Life",
    url_key: "blogs",
  },
  {
    name: "Branches",
    url_key: "branches",
  },
];
