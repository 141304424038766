const ProfileCircle = (props) => (
  <svg
    width={34}
    height={35}
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1697 19.7313C17.1414 19.7313 17.0989 19.7313 17.0705 19.7313C17.028 19.7313 16.9714 19.7313 16.9289 19.7313C13.713 19.6292 11.3047 17.0479 11.3047 13.8688C11.3047 10.6313 13.8689 7.9917 17.0139 7.9917C20.1589 7.9917 22.723 10.6313 22.723 13.8688C22.7089 17.0625 20.2864 19.6292 17.2122 19.7313C17.1839 19.7313 17.1839 19.7313 17.1697 19.7313ZM16.9997 10.1646C15.0164 10.1646 13.4155 11.8271 13.4155 13.8542C13.4155 15.8521 14.9314 17.4709 16.858 17.5438C16.9005 17.5292 17.0422 17.5292 17.1839 17.5438C19.0822 17.4417 20.5697 15.8375 20.5839 13.8542C20.5839 11.8271 18.983 10.1646 16.9997 10.1646Z"
      fill="currentColor"
    />
    <path
      d="M17.0018 33.177C13.191 33.177 9.55014 31.7187 6.73097 29.0646C6.47597 28.8312 6.36264 28.4812 6.39097 28.1458C6.57514 26.4104 7.62347 24.7916 9.36597 23.5958C13.5876 20.7083 20.4301 20.7083 24.6376 23.5958C26.3801 24.8062 27.4285 26.4104 27.6126 28.1458C27.6551 28.4958 27.5276 28.8312 27.2726 29.0646C24.4535 31.7187 20.8126 33.177 17.0018 33.177ZM8.61514 27.8541C10.9668 29.8812 13.9276 30.9895 17.0018 30.9895C20.076 30.9895 23.0368 29.8812 25.3885 27.8541C25.1335 26.9646 24.4535 26.1041 23.4476 25.4041C19.9626 23.0125 14.0551 23.0125 10.5418 25.4041C9.53597 26.1041 8.87014 26.9646 8.61514 27.8541Z"
      fill="currentColor"
    />
    <path
      d="M16.9987 33.1769C8.59787 33.1769 1.76953 26.1478 1.76953 17.4998C1.76953 8.85192 8.59787 1.82275 16.9987 1.82275C25.3995 1.82275 32.2279 8.85192 32.2279 17.4998C32.2279 26.1478 25.3995 33.1769 16.9987 33.1769ZM16.9987 4.01025C9.7737 4.01025 3.89453 10.0623 3.89453 17.4998C3.89453 24.9373 9.7737 30.9894 16.9987 30.9894C24.2237 30.9894 30.1029 24.9373 30.1029 17.4998C30.1029 10.0623 24.2237 4.01025 16.9987 4.01025Z"
      fill="currentColor"
    />
  </svg>
);
export default ProfileCircle;
