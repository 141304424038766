import Heart from "@/components/icons/Heart";
import CartIcon from "@/components/icons/CartIcon";
import HomeIcon from "@/components/icons/HomeIcon";
import BurgerMenu from "@/components/icons/BurgerMenu";
import CategoryIcon from "@/components/icons/CategoryIcon";

import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useCart } from "@/context/cart";
import { useWishlist } from "@/context/wishlist";
import { useSession } from "next-auth/react";
import useClient from "@/hooks/global/useClient";

const FixedBox = ({setAuth}) => {
  const { push } = useRouter();
  const pathname = usePathname();
  const cart = useCart();
  const {wishlist} = useWishlist();
  const {data: session} = useSession();
  const { isClient } = useClient();
  return (
    <section className="fixed bottom-0 left-0 w-full px-5 py-2.5 z-[500] lg:hidden flex items-center justify-between bg-white">
      <button
        onClick={() => push("/")}
        className={`${pathname === "/" ? "text-red-600" : "text-zinc-500"}`}
      >
        <HomeIcon className="size-6 sm:size-7" />
      </button>
      <button
        onClick={() => push("/categories")}
        className={`${
          pathname === "/categories" ? "text-red-600" : "text-zinc-500"
        }`}
      >
        <CategoryIcon className="size-6 sm:size-7" />
      </button>
      <button
        className={`grid border rounded-full size-12 place-items-center ${
          pathname === "/cart"
            ? "text-red-600 border-red-600"
            : "text-zinc-500 border-zinc-600"
        }`}
        onClick={() => push("/cart")}
      >
        <div className="relative">
          <CartIcon className="size-[22px] sm:size-7" />
          {isClient && cart?.cart?.cartItems?.length > 0 && (
            <span className="absolute z-40 grid text-xs text-white bg-red-600 rounded-full -start-2 -top-2 size-4 place-items-center">
              {cart?.cart?.cartItems?.length}
            </span>
          )}
        </div>
      </button>
      <button
        className={`${
          pathname === "/user/favourites" ? "text-red-600" : "text-zinc-500"
        } relative`}
        onClick={() => push("/user/favourites")}
      >
        <Heart className="size-6 sm:size-7" />
        {isClient && Object.keys(wishlist || {})?.length > 0 && (
              <span className="absolute z-40 grid text-xs text-white bg-red-600 rounded-full -start-1.5 -top-1.5 size-4 place-items-center">
                {Object.keys(wishlist || {})?.length}
              </span>
            )}
      </button>
      <button onClick={() => session?.user ? push('/user') : setAuth('login')} className="text-zinc-500">
        <BurgerMenu className="size-6" />
      </button>
    </section>
  );
};

export default FixedBox;
