export const countDownHandler = (toDate) => {
  const difference = new Date(toDate) - new Date();
  let timeLeft = { isCountDown: false };

  if (difference > 0) {
    timeLeft = {
      isCountDown: true,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const preventLettersInInput = (event) => {
  const input = event.key;
  // Allow control keys (e.g., backspace, delete)
  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    input === "Backspace" ||
    input === "Delete"
  ) {
    return;
  }

  // Check if the input is not a number
  if (isNaN(Number(input))) {
    event.preventDefault();
  }
};

export function parseJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export function findObjectByIdV2(data, targetKey, searchValue) {
  if (data && Array.isArray(data) && data?.length) {
    for (const item of data) {
      if (item[targetKey] == searchValue) {
        // prettyConole(searchValue, item)
        return item;
      }

      if (item?.children_data && item?.children_data.length > 0) {
        const result = findObjectByIdV2(
          item?.children_data,
          targetKey,
          searchValue
        );
        if (result) {
          // prettyConole(searchValue, result)
          return result;
        }
      }
    }
  }
  // Target object not found
  return null;
}

export const summarizeOptions = (products) => {
  const summary = {};

  products?.length > 0 &&
  products?.forEach((product) => {
    Object.keys(product.option_details || {}).forEach((attribute) => {
      const option = product.option_details[attribute];
      if (!summary[attribute]) {
        summary[attribute] = new Set();
      }
      summary[attribute].add(option.value);
    });
  });

  // Convert sets to counts
  // Object.keys(summary).forEach((attribute) => {
  //   summary[attribute] = summary[attribute].size;
  // });

  return summary;
};