const SearchIcon = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 20L14.5146 14.5146M14.5146 14.5146C15.9472 13.082 16.8333 11.1028 16.8333 8.91667C16.8333 4.54441 13.2889 1 8.91667 1C4.54441 1 1 4.54441 1 8.91667C1 13.2889 4.54441 16.8333 8.91667 16.8333C11.1028 16.8333 13.082 15.9472 14.5146 14.5146Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SearchIcon;
