import SearchIcon from "@/components/icons/SearchIcon";

const Search = ({ t, locale, active, inputValue, setInputValue, ourSearchInput }) => {

  return (
    <div
      className={`${
        active ? "block" : "hidden"
      } lg:block relative lg:flex-1 p-1.5 max-sm:-mt-3 sm:p-2.5 border rounded-sm max-lg:order-last max-lg:w-full`}
    >
      <input
        name="search"
        ref={ourSearchInput}
        placeholder={t("Search for any products")}
        className="w-full outline-none max-sm:placeholder:text-sm max-sm:text-sm"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <SearchIcon
        className={`absolute z-50 -translate-y-1/2 top-1/2 ${
          locale === "en" ? "right-3" : "left-3"
        }`}
      />
    </div>
  );
};

export default Search;
