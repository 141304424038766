import React, { useEffect, useState } from "react";
import ShareModal from "./ShareModal";
import { useTranslation } from "next-i18next";

const Share = ({ title, description, label, url = null }) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({ canonical: "", url: "" });
  // const canonical = document.querySelector("link[rel=canonocal]");
  // const url = canonical ? canonical.href : document.location.href;
  const shareDetails = { url: config.url, title, description };

  useEffect(() => {
    setConfig({
      canonical: document.querySelector("link[rel=canonocal]"),
      url: config.canonical ? config.canonical.href : document.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails);
      } catch (error) {}
    } else {
      setOpen(true);
    }
  };
  return (
    <div className="relative flex items-center justify-center">
      <button className="sharer-button" onClick={handleShare}>
        <span className="sharer-button-text">{label || t("share")}</span>
      </button>
      <ShareModal
        open={open}
        setOpen={setOpen}
        {...{ url: url || config.url, title, t }}
      />
    </div>
  );
};

export default Share;
