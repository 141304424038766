export const getProductValueFromProductAttributes = (
  product_attributes,
  key,
  locale
) => {
  const attribute = product_attributes?.find(
    (attribute) => attribute?.["1"]?.label === key
  );
  if (attribute) {
    return locale === "en" ? attribute?.["1"]?.value : attribute?.["5"]?.value;
  }
  return "";
};

function intOrFixedNumber(number) {
  if (isNaN(number)) return null;
  const fixedNumber = number?.toFixed(2);
  const [IntPart, floatPart] = String(fixedNumber)?.split(".");
  return floatPart === "00" ? IntPart : fixedNumber;
}

export const validatePrice = (
  prices_with_tax,
  price = null,
  product_type = null
) => {
  let discount = null;
  const updated_final_price = () => {
    if (product_type === "configurable" || !(product_type && price))
      return prices_with_tax?.final_price;
    return price?.final_price <= prices_with_tax?.final_price
      ? price?.final_price
      : prices_with_tax?.final_price;
  };
  const prices = {
    ...prices_with_tax,
    final_price: updated_final_price(),
  };

  if (prices?.final_price < prices?.original_price) {
    discount =
      ((prices?.final_price - prices?.original_price) /
        prices?.original_price) *
      100;
  }
  return {
    originalPrice: prices?.original_price,
    specialPrice:
      prices?.final_price < prices?.original_price ? prices?.final_price : null,
    discount: intOrFixedNumber(discount),
  };
};

export const priceFormatedObject = (item) => {
  return {
    discounted_price:
      item?.extension_attributes?.special_price ||
      item?.prices_with_tax?.discounted_price ||
      0,
    original_price:
      item?.extension_attributes?.original_price ||
      item?.prices_with_tax?.original_price ||
      0,
    discounted_price_from:
      item?.extension_attributes?.special_price_from ||
      item?.prices_with_tax?.discounted_price_from,
    discounted_price_to:
      item?.extension_attributes?.special_price_to ||
      item?.prices_with_tax?.discounted_price_to ||
      "",
    final_price:
      item?.extension_attributes?.final_price ||
      item?.prices_with_tax?.final_price ||
      0,
  };
};
